export default `
  .ith_embedErrorWrapper {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    padding: 10px 25px;
    justify-content: space-between;
    font-size: 16px;
  }
`;
