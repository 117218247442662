import React from 'react';

import EmbedBookingSummary from '../../components/EmbedBookingSummary';
import EmbedAuthStepper from '../../components/EmbedAuthStepper';

function EmbedCheckoutContainer(props) {
  const {
    t,
    centre,
    provider,
    services,
    startTime,
    endTime,
    bufferTime,
    date,
    userData,
    showBookingCalendar,

    onSignInSubmit,
    onSignUpSubmitStep1,
    onSignUpSubmitStep2,
    onVerificationSubmit,
    onSendCodeSubmit,
    errorHandler,

    activeStep,
    handleNext,
    handleBack,
    handleStepChange,
  } = props;

  return (
    <>
      <EmbedBookingSummary
        t={t}
        centre={centre}
        showBookingCalendar={showBookingCalendar}
        provider={provider}
        services={services}
        startTime={startTime}
        endTime={endTime}
        date={date}
      />
      <EmbedAuthStepper
        t={t}
        activeStep={activeStep}
        handleNext={handleNext}
        handleBack={handleBack}
        handleStepChange={handleStepChange}
        onSignInSubmit={onSignInSubmit}
        onSignUpSubmitStep1={onSignUpSubmitStep1}
        onSignUpSubmitStep2={onSignUpSubmitStep2}
        onVerificationSubmit={onVerificationSubmit}
        onSendCodeSubmit={onSendCodeSubmit}
        errorHandler={errorHandler}
        centre={centre}
        provider={provider}
        services={services}
        startTime={startTime}
        endTime={endTime}
        bufferTime={bufferTime}
        date={date}
        userData={userData}
      />
    </>
  );
}

export default EmbedCheckoutContainer;
