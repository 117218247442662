import {
  Button, Col, Form, Input, Row,
} from 'antd';
import React, { useRef } from 'react';
import { Icon } from 'react-icons-kit';
import { envelopeO } from 'react-icons-kit/fa';

import appService from '../../api/api/appService';
import { EMBED_STEPS } from '../../constants/authModalTypes';
import styles from './styles';

function EmbedForgotPassword({ t, handleStepChange, errorHandler }) {
  const forgotPasswordRef = useRef('');

  const sendResetPasswordLink = async () => {
    const formData = forgotPasswordRef.current.getFieldsValue();

    if (formData && formData.email) {
      try {
        // eslint-disable-next-line import/no-named-as-default-member
        await appService.sendResetPasswordLink(formData.email);
        handleStepChange(EMBED_STEPS.SIGN_UP_SIGN_IN);
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  return (
    <ith-div class="ith_auth-wrapper-field">
      <Form
        ref={forgotPasswordRef}
        name="forgot-password-form"
        layout="vertical"
      >
        <ith-div class="forgot-password-text">
          {t('label_forgotPasswordText')}
        </ith-div>
        <Form.Item
          label={t('label_email')}
          name="email"
          rules={[
            {
              required: true,
              message: t('validator.required'),
            },
            {
              type: 'email',
              message: t('validator.email'),
            },
          ]}
        >
          <Input
            size="large"
            placeholder={t('placeholder_email')}
            prefix={<Icon icon={envelopeO} />}
          />
        </Form.Item>

        <Row
          align="bottom"
          justify="space-between"
          gutter={[0, 12]}
          style={{ marginTop: '25px' }}
        >
          <Col xs={24} sm={11} md={6}>
            <Button
              className="btn--auth btn--auth-primary"
              htmlType="submit"
              onClick={() => handleStepChange(EMBED_STEPS.SIGN_UP_SIGN_IN)}
            >
              {t('back')}
            </Button>
          </Col>
          <Col xs={24} sm={11} md={6}>
            <Button
              className="btn--auth btn--auth-primary"
              htmlType="submit"
              onClick={sendResetPasswordLink}
            >
              {t('label_send')}
            </Button>
          </Col>
        </Row>
      </Form>

      <style>{styles}</style>
    </ith-div>
  );
}

export default EmbedForgotPassword;
