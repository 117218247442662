/* eslint-disable react/jsx-filename-extension */
import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import AGBPage from './pages/AGBPage';
import AppointmentsPage from './pages/AppointmentsPage';
import BookingPage from './pages/BookingPage';
import DashboardPage from './pages/DashboardPage';
import InvoicePage from './pages/InvoicePage';
import InvoicesPage from './pages/InvoicesPage';
import LandingPage from './pages/LandingPage';
import PageNotFound from './pages/PageNotFound';
import ProfilePage from './pages/ProfilePage';
import SearchPage from './pages/SearchPage';
import SettingsPage from './pages/SettingsPage';
import BookingCustom from './pages/widgets/BookingCustom';
import BookingMinimal from './pages/widgets/BookingMinimal';
import ProfileWidget from './pages/widgets/ProfileWidget';

function Router() {
  return (
    <Routes>
      <Route path="/" element={<LandingPage />} />
      <Route path="/signupcode/:signUpCode" element={<LandingPage />} />
      <Route
        path="/passwordReset/:passwordResetHash"
        element={<LandingPage />}
      />
      <Route path="/signup/:invoiceHash" element={<LandingPage />} />
      <Route path="/search/:query" element={<SearchPage />} />
      <Route path="/profile/:centreId" element={<ProfilePage />} />
      <Route
        path="/booking/:centreId/:providerId/:serviceId"
        element={<BookingPage />}
      />
      <Route path="/dashboard/" element={<DashboardPage />} />
      <Route path="/dashboard/appointments" element={<AppointmentsPage />} />
      <Route path="/dashboard/invoices" element={<InvoicesPage />} />
      <Route path="/dashboard/invoice/:invoiceId" element={<InvoicePage />} />
      <Route path="/dashboard/settings" element={<SettingsPage />} />

      <Route
        path="/widget/custom/:centreId/:frameColor/:availableColor/:selectedColor/:titleColor/:hoursColor/:hourColor/:fontFamily/:fontSize/:maxWidth/:providerId/:providerLimited/:providerHidden/:serviceId/:serviceLimited/:serviceHidden/"
        element={<BookingCustom />}
      />
      <Route
        path="/widget/minimal/:centreId/:maxWidth/:providerId/:providerLimited/:providerHidden/:serviceId/:serviceLimited/:serviceHidden/"
        element={<BookingMinimal />}
      />
      <Route
        path="/widget/profile/:centreId/:maxWidth/"
        element={<ProfileWidget />}
      />

      <Route path="/agb" element={<AGBPage />} />
      <Route path="/datenschutz" element={<AGBPage />} />

      <Route path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default Router;
