export default `
  .ith_dateBar {
    margin: 0 auto;
    height: 170px;
    background-color: #ffffff;
    border-radius: 13.2px;
    box-shadow: 0 28px 37px -28px #e5eced;
    border: solid 1.2px #e5eced;
    padding: 2%;
    margin-bottom: 2%;
  }

  .ith_monthYearBar {
    margin: 0 auto;
    margin-bottom: 2%;
    width: 100%;
    height: 50px;
  }

  .ith_chooseMonthYearDiv {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #ith_monthYear {
    font-family: Roboto;
    font-size: 30px;
    text-align: center;
    color: #273d52;
    width: 100%;
  }

  .ith_day_picker {
    background-color: #b3cffb;
  }

  .ith_dayCirclesBar {
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
  }

  .ith_SmallArrowIcon {
    font-size: 3em;
    color: #ff4000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
  }

  .ith_SmallArrowIcon_disabled {
    opacity: 0.2;
  }

  .ith_dayDiv {
    margin: 0 auto;
  }

  .ith_dayName {
    margin: 0 auto;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    color: #42a4d1;
  }

  .ith_dayCircle {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    border-radius: 50%;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    background: transparent;
    cursor: pointer;
  }

  .ith_hoverElements:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  .ith_bookingCalendar {
    margin: 0 auto;
    height: 400px;
    border-radius: 13.2px;
    box-shadow: 0 28px 37px -28px #e5eced;
    border: solid 1.2px #e5eced;
    background-color: #ffffff;
    margin-bottom: 7%;
  }

  .ith_timeList {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: none;
    padding: 0% 4%;
  }

  .ith_timeList::-webkit-scrollbar {
    display: none;
  }

  // .verification_container {
  //   margin: 0 auto;
  //   text-align: left;
  //   padding: 25px 15px;
  //   box-sizing: border-box;
  // }
  //
  // .verification_container_header {
  //   font-size: 30px;
  //   font-weight: 600;
  //   color: #8d8d8f;
  // }
  //
  // .verification_form {
  //   padding-top: 20px;
  // }
  //
  // .verification_form_submit {
  //   width: 100%;
  //   padding: 12px 8px;
  //   box-sizing: border-box;
  //   cursor: pointer;
  //   color: #ffffff;
  //   border: none;
  //   margin-top: 10%;
  //   margin-left: 5px;
  //   background-color: #E74C3C;
  // }

  // #form_input_title {
  //   color: #212b36;
  // }
  //
  // .options_verification {
  //   margin-top: 5%;
  // }
  //
  // #option_verification {
  //   color: #2892cc;
  //   text-decoration: none;
  //   cursor: pointer;
  // }
  //
  // @media (min-width:320px) {}
  // @media (min-width:480px) {
  //   .verification_container {
  //     width: 100%;
  //     height: 100%;
  //     text-align: center;
  //   }
  // }
  // @media (min-width:600px) {
  //
  //   .verification_container_header {
  //     font-size: 40px;
  //   }
  //
  //   .verification_form_submit {
  //     padding: 16px 10px;
  //     margin-bottom: 5%;
  //     font-size: 17px;
  //     font-weight: 600;
  //     min-height: 50px;
  //     width: 50%;
  //     border: none;
  //   }
  //
  //   #form_input_title {
  //     font-size: 16px;
  //     align: left;
  //     margin-bottom: 2%;
  //   }
  //
  //   #option_verification {
  //     font-size: 17px;
  //   }
  // }
  // @media (min-width:801px) {}
  // @media (min-width:1025px) {}
  // @media (min-width:1281px) {}

  @media (max-width: 900px) {
    .ith_timeList {
      width: 100%;
    }
  }

  @media (max-width: 1350px) {
    .ith_chooseMonthYearDiv {
      margin: 0 auto;
      font-size: 18px;
    }

    #ith_monthYear {
      font-size: 18px;
    }

    .ith_dayCirclesBar {
      overflow-y: scroll;
      overflow-y: hidden;
      padding-bottom: 2%;
      align-items: normal;
      justify-content: normal;
    }
  }
`;
