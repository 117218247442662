export default `
  .ith_content {
    margin: 0 auto;
    margin-bottom: 3%;
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .ith_checkout {
    margin-top: 5%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 100%;
  }

  .ith_bookingSteps {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 5px;
  }

  .ith_step1 {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    opacity: 0.6;
  }

  .ith_step2 {
    width: 40%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
  }

  .ith_stepIcon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    padding: 10px;
    color: white;
    background-color: #309bce;
  }

  .ith_infosUserBooking {
    margin-left: auto;
    margin-right: auto;
  }

  @media (max-width: 1350px) {
  }

  @media (max-width: 1170px) {
    .ith_checkout {
      flex-direction: column;
      justify-content: space-around;
    }

    .ith_infosUserBooking {
      width: 100%;
      min-width: unset;
    }

    .ith_loginSignupForm {
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    .ith_bookingSteps {
      flex-direction: column;
      align-items: space-between;
      padding: 15px;
    }

    .ith_step1 {
      width: 100%;
    }

    .ith_step2 {
      width: 100%;
      margin-top: 5px;
    }
  }
`;
