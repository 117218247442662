export default class JwtService {
  static ACCESS_TOKEN_KEY = 'JWT_TOKEN';

  static REFRESH_TOKEN_KEY = 'JWT_REFRESH_TOKEN';

  static WS_ACCESS_TOKEN_KEY = 'WS_JWT_TOKEN';

  static WS_REFRESH_TOKEN_KEY = 'WS_JWT_REFRESH_TOKEN';

  static getAccessToken() {
    return window.localStorage.getItem(JwtService.ACCESS_TOKEN_KEY);
  }

  static setAccessToken(accessToken) {
    window.localStorage.setItem(JwtService.ACCESS_TOKEN_KEY, accessToken);
  }

  static hasAccessToken() {
    const accessToken = JwtService.getAccessToken();

    return accessToken !== null && accessToken !== '';
  }

  static removeAccessToken() {
    window.localStorage.removeItem(JwtService.ACCESS_TOKEN_KEY);
  }

  static getRefreshToken() {
    return window.localStorage.getItem(JwtService.REFRESH_TOKEN_KEY);
  }

  static setRefreshToken(refreshToken) {
    window.localStorage.setItem(JwtService.REFRESH_TOKEN_KEY, refreshToken);
  }

  static hasRefreshToken() {
    const refreshToken = JwtService.getRefreshToken();

    return refreshToken !== null && refreshToken !== '';
  }

  static removeRefreshToken() {
    window.localStorage.removeItem(JwtService.REFRESH_TOKEN_KEY);
  }

  static getWSAccessToken() {
    return window.localStorage.getItem(JwtService.WS_ACCESS_TOKEN_KEY);
  }

  static setWSAccessToken(accessToken) {
    window.localStorage.setItem(JwtService.WS_ACCESS_TOKEN_KEY, accessToken);
  }

  static hasWSAccessToken() {
    const accessToken = JwtService.getWSAccessToken();

    return accessToken !== null && accessToken !== '';
  }

  static removeWSAccessToken() {
    window.localStorage.removeItem(JwtService.WS_ACCESS_TOKEN_KEY);
  }

  static getWSRefreshToken() {
    return window.localStorage.getItem(JwtService.WS_REFRESH_TOKEN_KEY);
  }

  static setWSRefreshToken(refreshToken) {
    window.localStorage.setItem(JwtService.WS_REFRESH_TOKEN_KEY, refreshToken);
  }

  static hasWSRefreshToken() {
    const refreshToken = JwtService.getWSRefreshToken();

    return refreshToken !== null && refreshToken !== '';
  }

  static removeWSRefreshToken() {
    window.localStorage.removeItem(JwtService.WS_REFRESH_TOKEN_KEY);
  }
}
