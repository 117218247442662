export default `
  ith-div {
    display: block;
    box-sizing: border-box;
  }

  ith-button {
    appearance: auto;
    -webkit-writing-mode: horizontal-tb !important;
    text-rendering: auto;
    color: -internal-light-dark(black, white);
    letter-spacing: normal;
    word-spacing: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: center;
    align-items: flex-start;
    cursor: default;
    background-color: -internal-light-dark(rgb(239, 239, 239), rgb(59, 59, 59));
    box-sizing: border-box;
    margin: 0em;
    font: 400 13.3333px Arial;
    padding: 1px 6px;
    border-width: 2px;
    border-style: outset;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
  }

  ith-p {
    font-family: "Montserrat";
    font-weight: 400;
  }

  .ith_booking_container_wrapper,
  .embed_checkout_container_wrapper {
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
  }
  .ith_booking_container {
    width: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  .ith_content {
    margin-bottom: 5%;
    height: 100%;
    width: 100%;
  }

  .ith_checkout .ith_infosUserBooking {
    width: auto;
  }

  .ith_bookingSteps {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 5px;
  }

  .ith_bookButton {
    height: 55px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    color: #fff;
    background: #e74c3c;
    padding: 3px;
    outline: none;
    border: none;
    border-radius: 5px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ith_employeeService {
    width: 100%;
    height: 100%;
    float: center;
    margin: 3% auto;
  }

  .ith_SmallArrowIcon {
    font-size: 3em;
    color: #ff4000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 100%;
  }

  .ith_employeeDiv {
    width: 35%;
    float: left;
  }

  #ith_chooseEmployee {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.11;
    color: #808080;
    margin-bottom: 2%;
  }

  .ith_employee {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 5px;
    border-radius: 6.2px;
    box-shadow: 0 13px 17px -13px #e5eced;
    border: solid 1.2px #e5eced;
    background-color: #ffffff;
    cursor: pointer;
  }

  .ith_employee_disabled {
    cursor: default;
  }

  .ith_employeeInputContent {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .ith_employeeServiceInputTitle {
    margin-left: 28px;
    margin-bottom: 8px;
  }

  .ith_therapistAvatar {
    border-radius: 50% !important;
    height: 40px !important;
    margin-left: 3px !important;
    float: left !important;
  }

  #ith_therapist {
    display: flex;
    align-items: center;
    font-family: Roboto;
    font-weight: 500;
    color: #273d52;
    float: left;
    margin: 0;
    margin-left: 10px;
    width: 60%;
  }

  .ith_dropDownButtonTherapist,
  .ith_dropDownButtonService {
    margin-right: 5px;
    margin-left: 5px;
  }

  .ith_employeeNameAvatar {
    font-weight: 500;
  }

  .ith_dropDownService {
    display: flex;
    overflow: hidden;
    max-width: 650px;
    align-items: start;
    justify-content: space-between;
    margin: 10px 0 0 0;
    padding: 0 22px 0 19px;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    color: #273d52;
    height: 40px;
  }

  .ith_dropdownText {
    margin-right: 5px;
    font-family: Roboto;
    font-weight: 500;
    font-size: 18px;
    word-break: break-word;
  }

  .ith_dropdownPrice {
    font-family: Roboto;
  }

  .ith_serviceDiv {
    width: 35%;
    float: right;
  }

  .ith_serviceText {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  #ith_chooseService {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 300;
    line-height: 1.11;
    color: #808080;
    float: left;
    margin-bottom: 2%;
  }

  .ith_service {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 10px;
    border-radius: 6.2px;
    border: solid 1.2px #e5eced;
    background-color: #ffffff;
    float: right;
    cursor: pointer;
  }

  .ith_service_disabled {
    cursor: default;
  }

  .ith_serviceDescBox {
    float: initial;
    cursor: initial;
    margin-bottom: 3%;
  }

  .ith_serviceDesc {
    overflow: auto;
    font-weight: 500;
    color: #273d52;
    margin: 0;
  }

  #ith_therapyName {
    font-family: Roboto;
    overflow: auto;
    font-weight: 500;
    color: #273d52;
    margin: 0;
  }

  #ith_therapyPrice {
    min-width: 70px;
    font-family: Roboto;
    font-weight: 500;
    margin: 0;
    color: #273d52;
  }

  #ith_chooseDayTime {
    width: 100%;
    margin: 2% auto;
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 300;
    color: #808080;
  }

  .ith_bookingCalendar {
    margin: 0 auto;
    height: 400px;
    border-radius: 13.2px;
    box-shadow: 0 28px 37px -28px #e5eced;
    border: solid 1.2px #e5eced;
    background-color: #ffffff;
    margin-bottom: 6%;
  }

  .ith_dateBar {
    margin: 0 auto;
    height: 125px;
    background-color: #ffffff;
    border-radius: 13.2px;
    box-shadow: 0 28px 37px -28px #e5eced;
    border: solid 1.2px #e5eced;
    padding: 2%;
    margin-bottom: 2%;
  }

  .ith_monthYearBar {
    margin: 0 auto;
    margin-bottom: 2%;
    width: 100%;
    height: 50px;
  }

  .ith_chooseMonthYearDiv {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ith_miniCalendarPopover {
    margin-top: 20%;
  }

  .ith_day_picker {
    background-color: #b3cffb;
  }

  #ith_monthYear {
    font-family: Roboto;
    font-size: 30px;
    text-align: center;
    color: #273d52;
    width: 100%;
  }

  .ith_dayCirclesBar {
    margin-bottom: 2%;
    display: flex;
    justify-content: space-between;
    align-items: space-between;
  }

  .ith_dayDiv {
    margin: 0 auto;
  }

  .ith_dayName {
    margin: 0 auto;
    text-align: center;
    font-family: Montserrat;
    font-size: 16px;
    font-weight: 500;
    color: #42a4d1;
  }

  .ith_dayCircle {
    margin: 0 auto;
    border-radius: 50%;
    border: none;
    outline: none;
    width: 50px;
    height: 50px;
    background: transparent;
    cursor: pointer;
  }

  .ith_day {
    font-family: Roboto;
    font-size: 22px;
    font-weight: 400;
    color: #273d52;
    text-align: center;
    vertical-align: middle;
  }

  .ith_hoverElements:hover {
    box-shadow: 0 0 11px rgba(33, 33, 33, 0.2);
  }

  .ith_timeList {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    height: 100%;
    overflow-y: scroll;
    padding: 0% 4%;
  }

  .ith_timeList::-webkit-scrollbar {
    display: none;
  }

  .ith_amountBar {
    margin: 0 auto;
    overflow: hidden;
    border-radius: 6px;
    background-color: rgb(255, 65, 2, 0.08);
    border-radius: 2px;
  }

  .ith_amount {
    width: 75%;
    float: left;
    margin-left: 2%;
  }

  #ith_amountTitle {
    float: left;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.59;
    color: #808080;
  }

  #ith_amountNumber {
    float: right;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
    line-height: 2.59;
    color: #808080;
  }

  // .ith_bookButton {
  //   float: right;
  // }

  #ith_errorText {
    font-family: Roboto;
    font-size: 15px;
    line-height: 1.3;
    color: red;
    margin-top: 2%;
    margin-left: 15%;
    width: 100%;
  }

  .ith_noTimeSlots {
    display: inline-block;
    margin-top: 10%;
    margin-left: 35%;
    margin-right: 30%;
    font-weight: 600;
    word-break: break-word;
  }

  @media (max-width: 1350px) {
    .ith_employeeDiv {
      width: 40%;
    }

    .ith_dropDownService {
      width: 468px;
    }

    .ith_serviceDiv {
      width: 40%;
    }

    #ith_therapyName {
      font-size: 16px;
    }

    #ith_monthYear {
      font-size: 18px;
    }

    .ith_chooseMonthYearDiv {
      margin: 0 auto;
      font-size: 18px;
    }

    .ith_dayCirclesBar {
      overflow-y: scroll;
      overflow-y: hidden;
      padding-bottom: 2%;
      align-items: normal;
      justify-content: normal;
    }

    ::-webkit-scrollbar {
      height: 5px;
    }

    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }

    .ith_amount {
      width: 350px;
    }
  }

  @media (max-width: 1200px) {
    .ith_dropDownService {
      width: 376px;
    }

    #ith_chooseEmployee {
      font-size: 16px;
    }

    #ith_therapist {
      font-size: 16px;
    }

    #ith_chooseService {
      font-size: 16px;
    }

    #ith_chooseDayTime {
      font-size: 16px;
    }
  }

  @media (max-width: 992px) {
    .ith_dropDownService {
      width: 288px;
    }
  }

  @media (max-width: 950px) {
    .ith_centreInfos {
      width: 70%;
    }

    #ith_centreTitle {
      font-size: 25px;
    }

    #ith_centreDesc {
      font-size: 16px;
    }

    .ith_employeeDiv {
      width: 100%;
      margin-bottom: 5%;
    }

    .ith_employee {
      width: 100%;
    }

    .ith_serviceDiv {
      width: 100%;
    }

    .ith_dropDownService {
      width: 720px;
    }

    .ith_service {
      padding: none;
    }

    #ith_therapyPrice {
      margin-left: 5%;
    }

    .ith_amount {
      width: 250px;
    }

    #ith_amountTitle {
      font-size: 18px;
    }

    #ith_amountNumber {
      font-size: 18px;
    }
  }

  @media (max-width: 900px) {
    .ith_bookingSteps {
      flex-direction: column;
      align-items: space-between;
      padding: 15px;
    }

    .ith_step1 {
      width: 100%;
    }

    .ith_step2 {
      width: 100%;
      margin-top: 5px;
    }

    #ith_chooseDayTime {
      margin-left: 22px;
    }

    .ith_timeList {
      width: 100%;
    }

    .ith_amount {
      width: 300px;
    }
  }

  @media (max-width: 767px) {
    #ith_therapyName {
      font-size: 14px;
    }

    #ith_therapyPrice {
      display: flex;
      align-items: center;
      font-size: 14px;
    }

    #ith_therapist {
      font-size: 14px;
    }
  }

  @media (max-width: 750px) {
    .ith_dropDownService {
      width: 95%;
      padding: 0;
    }
  }

  @media (max-width: 650px) {
    .ith_service {
      float: left;
    }

    .ith_amount {
      width: 180px;
    }

    .ith_amountBar {
      height: 50px;
    }

    #ith_amountTitle {
      font-size: 16px;
      margin: 5% auto;
    }

    #ith_amountNumber {
      font-size: 15px;
      margin: 6% auto;
      margin-right: 5px;
    }

    .ith_bookButton {
      width: 75px;
    }

    #ith_bookNow {
      font-size: 13px;
    }
  }

  .ith_book_now_wrapper {
    width: 100%;
  }

  .ith_book_now {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    color: #fff;
    background: #e74c3c;
    padding: 3px;
    outline: none;
    border: none;
    border-radius: 5px;
    z-index: 2;
  }

  button:disabled,
  button[disabled] {
    background: rgba(231, 76, 60, 0.6);
  }

  @media (max-width: 550px) {
    .ith_dropDownService {
      width: 94%;
    }
  }

  @media (max-width: 340px) {
    .ith_dropDownService {
      width: 90%;
    }
  }
`;
