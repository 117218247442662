/* eslint-disable react/no-unescaped-entities */

/* eslint-disable max-len */
import { Card } from 'antd';
import * as React from 'react';

import antStyles from './antStyleObjects';
import './card.css';

const { Meta } = Card;

function CustomCard({
  size = 'small',
  title = '',
  mainDescription = '',
  secondaryDescription = '',
  image = '',
  alt = '',
}) {
  if (size === 'small') {
    return (
      <Card
        bodyStyle={{ margin: '0' }}
        style={antStyles.cardStyles}
        cover={<img className="smallCardImg" alt={alt} src={image} />}
      >
        <Meta
          title={title}
          description={mainDescription}
          className="cardMeta"
        />
      </Card>
    );
  }

  return (
    <Card
      style={antStyles.twoCardStyles}
      cover={(
        <img
          src={image}
          alt={alt}
          style={{
            borderRadius: '5px 5px 0 0',
          }}
        />
      )}
    >
      <p className="cardTitle">{title}</p>
      <br />
      <p className="cardDescription">{secondaryDescription}</p>
      <br />
      <p className="cardDescription">{mainDescription}</p>
    </Card>
  );
}

export default CustomCard;
