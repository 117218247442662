import { Button, Space } from 'antd';
import React from 'react';

import { INNER_ROUTES } from '../../constants/inner-routes-values';
import styles from './styles';

function EmbedSuccess({ t }) {
  return (
    <ith-div class="ith_auth-wrapper-field">
      <div className="embed-success-wrapper">
        <div>
          <img
            src={`${process.env.REACT_APP_CONSUMER_URL}/static/imgs/embed/calendar-check.png`}
            alt=""
          />
        </div>
        <div className="embed-success-text">{t('label_thankYou')}</div>

        <div className="embed-success-info">{t('label_bookingSuccess')}</div>

        <Space size="large" direction="vertical">
          <Button
            className="btn--auth btn--auth-primary"
            type="text"
            onClick={() => window.open(
              `${process.env.REACT_APP_CONSUMER_URL}/${INNER_ROUTES.DASHBOARD.APPOINTMENT}`,
              '_blank',
            )}
          >
            {t('label_seeYourBookings')}
          </Button>
        </Space>
      </div>
      <style>{styles}</style>
    </ith-div>
  );
}

export default EmbedSuccess;
