/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    signedIn: false,
    userId: '',
    user: {},
    isFetchingUser: false,
    isValidUser: false,
    isFailedFetchingUser: false,
  },
  reducers: {
    signIn: (state, action) => {
      state.signedIn = true;
      state.userId = action.payload.user_id;
      state.user = action.payload;
    },
    signOut: (state) => {
      state.signedIn = false;
      state.userId = '';
      state.user = {};
    },
    signUp: (state, action) => {
      state.userId = action.payload.user.user_id;
    },
  },
});

export const { signIn, signOut, signUp } = authSlice.actions;

export default authSlice.reducer;
