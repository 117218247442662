import React, { PureComponent } from 'react';

import BookingWidget from '../containers/BookingWidget/BookingWidget';
import Page from '../hoc/Page';

class BookingPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="pageContainer">
        <BookingWidget />
      </div>
    );
  }
}

export default Page(BookingPage);
