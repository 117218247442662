import React, { PureComponent } from 'react';

import SearchListing from '../containers/Search/SearchListing/SearchListing';
import Page from '../hoc/Page';

class SearchPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <SearchListing />;
  }
}

export default Page(SearchPage);
