/* eslint-disable no-unused-vars */

/* eslint-disable import/named */
import {
  Button, Col, Collapse, Modal, Row,
} from 'antd';
import React from 'react';

import StoreConnector from '../../hoc/StoreConnector';
import { cleanErrorThunk } from '../../redux/thunks/modals';
import stateUtils from '../../utils/state';
import './styles.css';

class ErrorModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      errorMessage: '',
      errorMessageDev: '',
    };
  }

  componentDidMount() {
    if (this.state.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }

    if (this.state.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }

    if (this.state.errorMessageDev !== this.props.errorMessageDev) {
      this.setState({ errorMessageDev: this.props.errorMessageDev });
    }
  }

  componentDidUpdate() {
    if (this.state.error !== this.props.error) {
      this.setState({ error: this.props.error });
    }

    if (this.state.errorMessage !== this.props.errorMessage) {
      this.setState({ errorMessage: this.props.errorMessage });
    }

    if (this.state.errorMessageDev !== this.props.errorMessageDev) {
      this.setState({ errorMessageDev: this.props.errorMessageDev });
    }

    return false;
  }

  render() {
    const { error, errorMessage, errorMessageDev } = this.state;

    return (
      <Modal
        visible={error}
        onCancel={() => this.props.dispatch(cleanErrorThunk())}
        footer={null}
        title="Fehler"
        style={{
          borderRadius: '10px',
        }}
      >
        <Row>
          <Col span={24} style={{ marginBottom: '20px' }}>
            <p className="ErrorLabel">{errorMessage}</p>
          </Col>
          {errorMessageDev && (
            <Col span={24} style={{ marginBottom: '20px' }}>
              <Collapse>
                <Collapse.Panel header="Mehr Informationen" key="1">
                  <p>{errorMessageDev}</p>
                </Collapse.Panel>
              </Collapse>
            </Col>
          )}
          <Col span={24}>
            <Row justify="center">
              <Button
                className="ErrorButton"
                onClick={() => this.props.dispatch(cleanErrorThunk())}
              >
                OK
              </Button>
            </Row>
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default StoreConnector(ErrorModals, stateUtils.fullStateMap, {});
