/* eslint-disable import/no-cycle */
import axios from 'axios';
import * as _ from 'lodash';

import env from '../../config/env';
import jwtService from '../jwtService';
import { publicCommonClient } from './index';

const apiUrls = [
  env.api.commonService,
  env.api.directoryEngine,
  env.api.bookingEngine,
];

export const requestInterceptor = async (config) => {
  const newConfig = config;

  if (!jwtService.hasAccessToken()) {
    throw new axios.Cancel('Operation canceled');
  }

  const accessToken = jwtService.getAccessToken();

  newConfig.headers.common.Authorization = `Bearer ${accessToken}`;
  return newConfig;
};

async function setNewTokenPair() {
  try {
    if (!jwtService.hasRefreshToken()) {
      return {};
    }

    const oldRefreshToken = jwtService.getRefreshToken();
    const {
      data: { signInToken, refreshToken },
    } = await publicCommonClient.post('/api/auth/refreshToken', {
      token: oldRefreshToken,
    });

    jwtService.setAccessToken(signInToken);
    jwtService.setRefreshToken(refreshToken);
    return { signInToken, refreshToken };
  } catch (e) {
    return {};
  }
}

export const responseInterceptor = (client) => async (error) => {
  const newError = error;

  const unauthorizedError = newError.response && newError.response.status === 401;
  const isRequestToApi = newError.response
    && _.some(apiUrls, (url) => _.includes(newError.response.request.responseURL, url));

  if (unauthorizedError && isRequestToApi) {
    const { signInToken } = await setNewTokenPair();

    if (signInToken) {
      newError.config.headers.Authorization = `Bearer ${signInToken}`;

      return client(newError.config);
    }

    jwtService.removeAccessToken();
    jwtService.removeRefreshToken();
  }

  throw newError;
};

export default {
  requestInterceptor,
  responseInterceptor,
};
