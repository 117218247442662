import { Form, Input } from 'antd';
import React, { useEffect, useRef } from 'react';
import { Icon } from 'react-icons-kit';
import { chevronRight } from 'react-icons-kit/fa';

import { EMBED_STEPS } from '../../constants/authModalTypes';

function EmbedVerification({
  t,
  activeStep,
  onVerificationSubmit,
  onSendCodeSubmit,
}) {
  const formRef = useRef('');

  useEffect(() => {
    if (activeStep === EMBED_STEPS.VERIFICATION) {
      onVerificationSubmit();
    }
  }, [activeStep]);

  const checkCode = (value) => {
    if (value.length === 4) {
      formRef.current.submit();
    }
  };

  return (
    <ith-div class="ith_auth-wrapper-field">
      <Form
        ref={formRef}
        name="verification-code-form"
        layout="vertical"
        onFinish={onSendCodeSubmit}
      >
        <Form.Item
          label={t('label_code')}
          name="code"
          rule={[
            {
              type: 'number',
              message: 'test',
            },
          ]}
        >
          <Input
            size="large"
            placeholder="X-X-X-X"
            type="text"
            maxLength="4"
            onChange={(e) => checkCode(e.target.value)}
            prefix={<Icon icon={chevronRight} />}
          />
        </Form.Item>
        <p style={{ marginBottom: '20px' }}>{t('pleaseEnterCode')}</p>
        <p>{t('codeNotReceived')}</p>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          className="requestAgainButton"
          onClick={() => onVerificationSubmit()}
        >
          {t('label_requestAgain')}
        </div>
      </Form>
    </ith-div>
  );
}

export default EmbedVerification;
