import { Col, Row } from 'antd';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import StoreConnector from '../../hoc/StoreConnector';
import {
  getAppointmentListThunk,
  getInvoiceListThunk,
  resetAppointmentListThunk,
  resetInvoiceListThunk,
} from '../../redux/thunks/dashboard';
import stateUtils from '../../utils/state';
import './styles.css';

class Dashboard extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      appointmentList: [],
      invoiceList: [],
    };
  }

  componentDidMount() {
    this.reflectProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.reflectProps(nextProps);
  }

  componentWillUnmount() {
    this.props.dispatch(resetAppointmentListThunk());
    this.props.dispatch(resetInvoiceListThunk());
  }

  reflectProps(props) {
    if (
      !props.isFetchingAppointmentList
      && !props.isValidAppointmentList
      && !props.isFailedFetchingAppointmentList
    ) {
      props.dispatch(getAppointmentListThunk(props.user.user_id));
    }
    if (props.isValidAppointmentList) {
      this.setState({ appointmentList: this.props.appointmentList }, () => this.setState({ appointmentList: this.props.appointmentList }));
    }
    if (
      !props.isFetchingInvoiceList
      && !props.isValidInvoiceList
      && !props.isFailedFetchingInvoiceList
    ) {
      props.dispatch(getInvoiceListThunk(props.user.user_id));
    }
    if (props.isValidInvoiceList) {
      this.setState({ invoiceList: this.props.invoiceList }, () => this.setState({ invoiceList: this.props.invoiceList }));
    }
  }

  render() {
    return (
      <div className="Dashboard">
        <Row
          align="top"
          justify="space-between"
          style={{ height: '100%', padding: '5% 10%' }}
        >
          <Col span={10} className="numberBox">
            <Link to="/dashboard/appointments">
              <Row align="top" justify="middle" style={{ height: '100%' }}>
                <Col span={24} className="numberBoxTop">
                  {this.state.appointmentList
                    ? this.state.appointmentList.length
                    : 0}
                </Col>
                <Col span={24} className="numberBoxBottom">
                  Termine
                </Col>
              </Row>
            </Link>
          </Col>
          <Col span={10} className="numberBox">
            <Link to="/dashboard/invoices">
              <Row
                align="top"
                justify="space-between"
                style={{ height: '100%' }}
              >
                <Col span={24} className="numberBoxTop">
                  {this.state.invoiceList ? this.state.invoiceList.length : 0}
                </Col>
                <Col span={24} className="numberBoxBottom">
                  Rechnungen
                </Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StoreConnector(Dashboard, stateUtils.fullStateMap, {});
