import {
  Col, Input, Row, Space,
} from 'antd';
import React from 'react';
import { Icon } from 'react-icons-kit';
import { checkCircle, timesCircle } from 'react-icons-kit/fa';

import './styles.css';

function PasswordFields({
  validators,
  passwordSignup,
  onPasswordSignupChange,
  passwordConfirmation,
  onPasswordConfirmationChange,
}) {
  return (
    <Row>
      <Col span={24} style={{ marginBottom: '20px' }}>
        <div className="authFieldBox">
          <Row>
            <Col span={24}>
              <p className="authFieldLabel">Passwort</p>
            </Col>
            <Col span={24}>
              <Input.Password
                placeholder="********"
                bordered={false}
                value={passwordSignup}
                onChange={(e) => onPasswordSignupChange(e.target.value)}
                style={{
                  width: '100%',
                  border: 'none',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  color: '#3A3335',
                  fontSize: '14px',
                  letterSpacing: '-0.1px',
                  lineHeight: '21px',
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24} style={{ marginBottom: '20px' }}>
        <div className="authFieldBox">
          <Row>
            <Col span={24}>
              <p className="authFieldLabel">Passwort bestätigen</p>
            </Col>
            <Col span={24}>
              <Input.Password
                placeholder="********"
                bordered={false}
                onChange={(e) => onPasswordConfirmationChange(e.target.value)}
                value={passwordConfirmation}
                style={{
                  width: '100%',
                  border: 'none',
                  fontFamily: 'Inter',
                  textAlign: 'left',
                  color: '#3A3335',
                  fontSize: '14px',
                  letterSpacing: '-0.1px',
                  lineHeight: '21px',
                }}
              />
            </Col>
          </Row>
        </div>
      </Col>
      <Col span={24} style={{ marginBottom: '20px' }}>
        <Space size="small" direction="vertical" style={{ width: '100%' }}>
          {validators.map((item) => (
            <Row>
              <Col
                span={3}
                className={
                  item.isValid
                    ? 'icon--password-valid'
                    : 'icon--password-invalid'
                }
              >
                <Icon
                  icon={item.isValid ? checkCircle : timesCircle}
                  size={20}
                />
              </Col>
              <Col span={21} style={{}}>
                {item.text}
              </Col>
            </Row>
          ))}
        </Space>
      </Col>
    </Row>
  );
}

export default PasswordFields;
