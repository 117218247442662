const APPOINT_STATUSES = {
  CONFIRMED: 'CONFIRMED',
  BOOKED: 'BOOKED',
  PROVIDED: 'PROVIDED',
  CANCELLED: 'CANCELLED',
  UNCONFIRMED: 'UNCONFIRMED',
};

export const DASHBOARD_APPT_STATUSES_T = {
  CONFIRMED: 'appt_status_confirmed',
  UNCONFIRMED: 'appt_status_pending',
  CANCELLED: 'appt_status_declined',
};

export default APPOINT_STATUSES;
