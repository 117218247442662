/* eslint-disable import/prefer-default-export */
import bookingInterface from '../../interfaces/api/booking';
import {
  bookAppointment,
  getOffers,
  getOffersF,
  getOffersS,
  resetOffers,
} from '../slices/booking';
import { setErrorThunk } from './modals';

export const getOffersThunk = (centreId, providerId, serviceId, dateStart, dateEnd) => async (dispatch) => {
  dispatch(getOffers());

  await bookingInterface
    .getOffers(centreId, providerId, serviceId, dateStart, dateEnd)
    .then((response) => {
      dispatch(getOffersS(response));
    })
    .catch((err) => {
      dispatch(getOffersF());

      console.log(err);
      dispatch(
        setErrorThunk(
          'Buchungsangebote konnten nicht abgerufen werden. Versuchen Sie es später erneut, oder wenden Sie sich direkt an die Praxis.',
          err,
        ),
      );
    });
};

export const bookAppointmentThunk = (centreId, providerId, serviceId, userId, date, startTime, endTime) => async (dispatch) => {
  await bookingInterface
    .bookAppointment(
      centreId,
      providerId,
      serviceId,
      userId,
      date,
      startTime,
      endTime,
    )
    .then(() => {
      dispatch(bookAppointment());
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Der Termin konnte nicht gebucht werden. Versuchen Sie es später erneut, oder wenden Sie sich direkt an die Praxis.',
          err,
        ),
      );
    });
};

export const resetOffersThunk = () => async (dispatch) => {
  dispatch(resetOffers());
};
