import React from 'react';
import { connect } from 'react-redux';

const decorator = (
  ComposedComponent,
  stateToPropsMapper,
  cascadedProps = {},
) => {
  class Wrapper extends React.Component {
    render() {
      return <ComposedComponent {...this.props} {...cascadedProps} />;
    }
  }

  return connect(stateToPropsMapper)(Wrapper);
};

export default decorator;
