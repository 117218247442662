export default `
  .ith_profile_header_wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
    position: relative;
    background-color: #ffffff;
    padding: 10px;
  }

  .ith_profile_header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;
    box-sizing: border-box;
    position: relative;
  }

  .ith_profile_heading {
    padding: 5px 0;
    font-family: Montserrat;
    font-size: 24px;
    font-weight: 500;
    color: #42a4d1;
  }

  .ith_profile_subheading {
    padding: 5px 0;
    text-align: center;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    color: #42a4d1;
  }

  .ith_profile_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    flex: 1;
    box-sizing: border-box;
    position: relative;
  }

  .ith_profile_info_location {
    display: flex;
    box-sizing: border-box;
    padding: 5px 0;
    position: relative;
    line-height: 32.2px;
  }

  .ith_icon_wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding: 5px 0;
    margin-right: 5px;
    position: relative;
    font-size: 14px;
    color: #e74c3c;
  }

  .ith_location_label {
    padding: 5px 0;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    color: #42a4d1;
  }

  .ith_profile_info_rating {
    display: none;
    box-sizing: border-box;
    padding: 5px 0;
    position: relative;
  }

  @media (min-width: 600px) {
    .ith_profile_header_wrapper {
      flex-direction: row;
    }

    .ith_profile_header {
      align-items: flex-start;
      justify-content: flex-start;
    }

    .ith_profile_info {
      justify-content: space-between;
      align-items: flex-end;
    }

    .ith_icon_wrapper {
      font-size: 16px;
    }

    .ith_location_label {
      font-size: 16px;
      text-align: right;
    }

    .ith_profile_heading {
      font-size: 28px;
    }

    .ith_profile_subheading {
      text-align: left;
      font-size: 16px;
    }
  }
  @media (min-width: 801px) {
    .ith_icon_wrapper {
      font-size: 18px;
    }

    .ith_location_label {
      font-size: 18px;
    }

    .ith_profile_heading {
      font-size: 32px;
    }

    .ith_profile_subheading {
      font-size: 18px;
    }
  }
  @media (min-width: 1081px) {
    .ith_icon_wrapper {
      font-size: 20px;
    }

    .ith_location_label {
      font-size: 20px;
    }

    .ith_profile_heading {
      font-size: 36px;
    }

    .ith_profile_subheading {
      font-size: 20px;
    }
  }
`;
