/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    suggestions: [
      { value: 'Shiatsu', name: 'Shiatsu' },
      { value: 'Akupunktur', name: 'Akupunktur' },
      { value: 'Massage', name: 'Massage' },
    ],
    isFetchingResults: false,
    isValidResults: false,
    isFailedFetchingResults: false,
    results: [],
    isFetchingResult: false,
    isValidResult: false,
    isFailedFetchingResult: false,
    result: {},
  },
  reducers: {
    getSuggestions: (state, action) => {
      state.suggestions = action.payload;
    },
    getResults: (state) => {
      state.isFetchingResults = true;
      state.isValidResults = false;
      state.isFailedFetchingResults = false;
      state.results = [];
    },
    getResultsS: (state, action) => {
      state.isFetchingResults = false;
      state.isValidResults = true;
      state.isFailedFetchingResults = false;
      state.results = action.payload;
    },
    getResultsF: (state) => {
      state.isFetchingResults = false;
      state.isValidResults = false;
      state.isFailedFetchingResults = true;
      state.results = [];
    },
    getResult: (state) => {
      state.isFetchingResult = true;
      state.isValidResult = false;
      state.isFailedFetchingResult = false;
      state.result = [];
    },
    getResultS: (state, action) => {
      state.isFetchingResult = false;
      state.isValidResult = true;
      state.isFailedFetchingResult = false;
      state.result = action.payload;
    },
    getResultF: (state) => {
      state.isFetchingResult = false;
      state.isValidResult = false;
      state.isFailedFetchingResult = true;
      state.result = [];
    },
    invalidateResults: (state) => {
      state.isFetchingResults = false;
      state.isValidResults = false;
      state.isFailedFetchingResults = false;
      state.results = [];
    },
    invalidateResult: (state) => {
      state.isFetchingResult = false;
      state.isValidResult = false;
      state.isFailedFetchingResult = false;
      state.result = [];
    },
  },
});

export const {
  getSuggestions,
  getResults,
  getResultsS,
  getResultsF,
  getResult,
  getResultS,
  getResultF,
  invalidateResults,
  invalidateResult,
} = searchSlice.actions;

export default searchSlice.reducer;
