import React, { PureComponent } from 'react';

import Page from '../hoc/Page';

class SettingsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <>Hoppla, die gewünschte Seite konnte nicht gefunden werden.</>;
  }
}

export default Page(SettingsPage);
