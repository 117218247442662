/* eslint-disable no-console */

/* eslint-disable class-methods-use-this */

/* eslint-disable no-unused-vars */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { SearchOutlined } from '@ant-design/icons';
import {
  Col, Image, List, Row, Space,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { Navigate } from 'react-router-dom';

import GMapContainer from '../../components/GMapContainer/GMapContainer';
import Gallery from '../../components/Gallery/Gallery';
import ListCard from '../../components/listingCard/ListCard';
import StoreConnector from '../../hoc/StoreConnector';
import withParams from '../../hoc/withParams';
import GalleryPlaceholderImage1 from '../../placeholders/placeholderGallery1.jpg';
import GalleryPlaceholderImage2 from '../../placeholders/placeholderGallery2.jpg';
import GalleryPlaceholderImage from '../../placeholders/placeholderGallery.jpg';
import {
  getResultThunk,
  invalidateResultThunk,
} from '../../redux/thunks/search';
import stateUtils from '../../utils/state';
import './styles.css';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      result: {},
    };
  }

  componentDidMount() {
    this.reflectProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.reflectProps(nextProps);
  }

  componentWillUnmount() {
    this.props.dispatch(invalidateResultThunk());
  }

  reflectProps(props) {
    if (
      !props.isFetchingResult
      && !props.isValidResult
      && !props.isFailedFetchingResult
    ) {
      props.dispatch(getResultThunk(props.params.centreId));
    }
    if (props.isValidResult) {
      this.setState({ result: this.props.result }, () => this.setState({ result: this.props.result }));
    }
  }

  render() {
    const { result } = this.state;

    if (!result.compiledTags) {
      return <>...</>;
    }

    const { compiledTags } = result;
    const formatTags = (tags) => {
      const formated = [];
      tags.forEach((tag) => {
        if (!formated.includes(tag.split(',')[0])) {
          formated.push(tag.split(',')[0]);
        }
      });
      return formated;
    };
    const formatedTags = formatTags(compiledTags);
    return (
      <div className="profile">
        <Row style={{ paddingBottom: '20px' }}>
          <Col span={24}>
            <Gallery
              urls={
                result.compiledUrls.length > 0
                  ? result.compiledUrls
                  : [
                    GalleryPlaceholderImage,
                    GalleryPlaceholderImage1,
                    GalleryPlaceholderImage2,
                  ]
              }
            />
          </Col>
        </Row>
        <Row justify="space-between" style={{ paddingBottom: '50px' }}>
          <Col span={24} lg={16} className="mainProfileContent">
            <Row>
              <Space>
                {formatedTags.length > 0 && (
                  <p className="tags">{formatedTags[0]}</p>
                )}
                {formatedTags.length > 1 && (
                  <>
                    <p className="tags">·</p>
                    <p className="tags">{formatedTags[1]}</p>
                  </>
                )}
                {formatedTags.length > 2 && (
                  <>
                    <p className="tags">·</p>
                    <p className="tags">{formatedTags[2]}</p>
                  </>
                )}
              </Space>
            </Row>
            <Row style={{ paddingBottom: '30px' }}>
              <div className="centreName">{result.centre.name}</div>
            </Row>
            <Row style={{ paddingBottom: '30px' }}>
              <pre className="description">{result.centre.description}</pre>
            </Row>
            <Row style={{ paddingBottom: '20px' }}>
              <div className="profileSectionTitle">Team</div>
            </Row>
            <Row style={{ paddingBottom: '20px' }}>
              <List
                dataSource={result.compiledProviders}
                pagination={{
                  position: 'bottom',
                  pageSize: 4,
                }}
                renderItem={(item) => (
                  <div className="pt-3">
                    <ListCard
                      navigatePath={`/booking/${item.centreId}/${item.providerId}/0`}
                      providerName={item.providerName}
                      centreName={item.centreName}
                      tags={item.tags}
                      nextAppointmentDate={new Date(item.nextAppointmentDate)}
                      distance={item.distance}
                      providerImage={item.providerImage}
                      bookable={item.bookable}
                    />
                  </div>
                )}
                style={{
                  width: '100%',
                }}
              />
            </Row>
          </Col>
          <Col span={24} lg={8}>
            <div className="contentBox">
              <Row>
                <Col span={15}>
                  <div className="boxTitle">{result.centre.name}</div>
                  <div className="boxContent">
                    {`${result.centre.Address.street} ${result.centre.Address.building}`}
                  </div>
                  <div className="boxContent">
                    {`${result.centre.Address.zipcode} ${result.centre.Address.city}`}
                  </div>
                </Col>
                <Col span={9}>
                  <Image
                    preview={false}
                    height="100px"
                    width="100px"
                    src={result.centre.Logo ? result.centre.Logo.url : ''}
                    style={{
                      maxHeight: '100px',
                      objectFit: 'cover',
                      borderRadius: '10px 0px 0px 10px',
                    }}
                  />
                </Col>
              </Row>
            </div>
            <div className="contentBox">
              <div className="boxTitle">Methoden</div>
              {_.map(result.compiledServices, (service) => (
                <div className="boxContent">{service}</div>
              ))}
            </div>
            <div className="contentBox">
              <div className="boxTitle">Symptome</div>
              {_.map(result.compiledSymptoms, (symptom) => (
                <div className="boxContent">{symptom}</div>
              ))}
            </div>
            <div className="contentBox">
              <div className="boxTitle">Sprachen</div>
              {_.map(result.compiledLanguages, (language) => (
                <div className="boxContent">{language}</div>
              ))}
            </div>
          </Col>
        </Row>
        <Row style={{ paddingBottom: '5px' }}>
          <div className="profileSectionTitle">Adresse</div>
        </Row>
        <Row style={{ paddingBottom: '20px' }}>
          <div className="profileSectionContent">
            {`${result.centre.Address.street} ${result.centre.Address.building}, ${result.centre.Address.zipcode} ${result.centre.Address.city}`}
          </div>
        </Row>
        <Row
          style={{ height: '400px', overflow: 'hidden', marginBottom: '20px' }}
        >
          <GMapContainer
            size="small"
            lat={result.centre.Address.lng}
            lng={result.centre.Address.lat}
          />
        </Row>
      </div>
    );
  }
}

export default StoreConnector(withParams(Profile), stateUtils.fullStateMap, {});
