import { captureException, withScope } from '@sentry/browser';

import env from '../../../config/env';
import { parseServerError } from './api';

export default class handleError {
  // eslint-disable-next-line consistent-return
  static logError(error) {
    if (env.server.stage !== 'development') {
      return withScope((scope) => {
        scope.setExtra('parsed_error', parseServerError(error));
        captureException(error);
      });
    }
    console.error({ error });
  }
}
