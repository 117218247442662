import _ from 'lodash';
import i18n from './i18n';

const t = i18n.t.bind(i18n);

const api = {
  // baseURL: Config.api.endpoint,
  crossDomain: true,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
};

export const parseServerError = (err, dontTranslate) => {
  const rawMessage = _.get(err, 'response.data.error.message')
    || _.get(err, 'message.message')
    || _.get(err, 'message')
    || _.get(err, 'error.message');
  const resMessage = typeof rawMessage === 'string' ? rawMessage : JSON.stringify(rawMessage);

  return dontTranslate ? resMessage : t(resMessage);
};

export const overwriteToken = (token) => {
  localStorage.setItem('access_token', token);
};

export default api;
