export default `
  .ith_timeList .ith_timeSlot, .ith_timeList .ith_emptySlot {
    border-left: 1px solid #2A99CC;
    height: auto;
  }
  .ith_timeList .ith_timeSlot:first-child {
    border: none;
  }
  .ith_timeList .ith_emptySlot:first-child {
    border: none;
  }
  
  .ith_timeList .ith_timeSlot .ith_timeBox {
    background: #E0F4FD;
    border-radius: 10px;
    color: #055793;
  }
  
  .ith_timeBox.ith_available.ith_highlighted {
    background: rgba(223, 71, 98, 100);
    color: #fff;
  }
`;
