export const DATE_FORMAT = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';
export const NOT_SELECTED_TIME = 'N/A';
export const DAY_NAMES = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
];

export default {
  DATE_FORMAT,
  TIME_FORMAT,
  NOT_SELECTED_TIME,
  DAY_NAMES,
};
