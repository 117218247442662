import { Image } from 'antd';
// import { Icon } from 'react-icons-kit';
// import { globe } from 'react-icons-kit/fa/globe';
import React, { PureComponent } from 'react';

import './styles.css';

class PageFooter extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="footer">
        <div className="adress">
          <div className="logo">
            <Image width={200} preview={false} src="/sanamia-logo-white.svg" />
          </div>
          <div className="grid lg:grid-cols-3 lg:grid-rows-1 md:grid-cols-3 md:grid-rows-1 justify-between content-center gap-0">
            <div className="ueber-uns">
              Softrey GmbH
              <br />
              Aathalstrasse 80
              <br />
              8610 Uster
              <br />
              Schweiz
              <br />
              legal@softrey.ch
            </div>
            <div />
            <div className="registrieren-login-a">
              <br />
              <a href="registrieren">Registrieren</a>
              <br />
              <a href="login">Login</a>
              <br />
              <a href="anbieter">Als Anbieter anmelden</a>
              <br />
              <a href="https://softrey.ch/dsg/Softrey-DSE.pdf">
                Datenschutz
              </a>
              <br />
              <a href="https://softrey.ch/dsg/Softrey-AGB.pdf">
                AGB
              </a>
            </div>
          </div>
        </div>
        <div className="datenschutz flex flex-wrap-reverse md:flex-wrap md:justify-items-start">
          <div className="copyright w-full md:w-1/3">
            &copy; 2024 by Softrey, All rights reserved
          </div>
          <div className="sprache">
            {/* <div className='w-full md:w-1/3 md:flex md:justify-items-start'>
            <span className="material-symbols-sharp">
              <div>
                <Icon icon={globe} />
              </div>
            </span>
            <div className="px-1.5">
              Deutsch
            </div>
            </div> */}
          </div>
        </div>
      </div>
    );
  }
}

export default PageFooter;
