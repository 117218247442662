/* eslint-disable no-irregular-whitespace */

/* eslint-disable react/no-unescaped-entities */
import React, { PureComponent } from 'react';

import '../css/infoPagesStyles.css';
import Page from '../hoc/Page';

class LandingPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="footerPageContainer">
          <div className="footerPageInnerContainer">
            <h1 className="footerPageTitle">
              Datenschutz- und Cookie-Bestimmungen
            </h1>
            <p>
              Stand: März 2019
              <br />
              Bitte beachten Sie, dass diese Datenschutz- und
              Cookie-Bestimmungen ausschliesslich im Deutschen Wortlaut gelten,
              da wir für die Übersetzung in andere Sprachen automatisierte
              Übersetzung-Tools verwenden.
              <br />
              <br />
              <h4 className="title">Einleitung</h4>
              <br />
              Diese Datenschutzerklärung und Cookie-Richtlinie (
              <strong>"Datenschutzerklärung"</strong>
              ) beschreiben die Daten,
              die auf www.therapeutenbuchen.ch, www.therapeutenbuchen.de und
              www.therapeutenbuchen.com (die
              <strong>"Webseiten"</strong>
              ) sowie der mobilen Anwendungssoftware
              benutzt werden. Die mobilen Apps können über iTunes und Google
              Play (die
              <strong>"Apps"</strong>
              ) heruntergeladen werden. Ferner beschreibt
              diese Datenschutzerklärung die Daten, die durch Einbindung
              (iFraming) unserer Webseite resp. Teilen davon auf
              Kunden-Webseiten genutzt und auf deren Webserver gehostet werden (
              <strong>"Partnerseiten"</strong>
              ) sowie die Social-Media-Seiten unserer Partner eingebettet sind,
              über die Kunden direkt bei Partnern Buchungen tätigen können.
              <br />
              Der Verantwortliche für Ihre personenbezogenen Daten ist die
              <strong>Softrey GmbH, Zürich</strong>
               (Sitz: Freiestrasse 62, 8032 Zürich, Switzerland) (
              <strong>"Softrey", "wir", "unser" oder "uns"</strong>
              ).
              <br />
              Wir sind stets bestrebt, die Privatsphäre unserer Nutzer und
              Kunden zu schützen. Diese Datenschutzerklärung soll Sie darüber
              informieren, wie wir Informationen bzw. Daten erheben, speichern
              und verarbeiten, die Sie identifizieren könnten, wie z.B. Ihren
              Namen, E-Mail-Adresse, Anschrift, andere Kontaktdaten oder
              Online-Identifikatoren und sonstige Informationen, die Sie uns bei
              der Nutzung der Plattform zur Verfügung stellen (
              <strong>"Personenbezogene Informationen"</strong>
              ) und auch welche Cookies wir verwenden. Bitte nehmen Sie sich
              einen Moment Zeit, um diese Datenschutzerklärung sorgfältig zu
              lesen.
              <br />
              <br />
              <h4 className="title">Welche Informationen erheben wir?</h4>
              <br />
              Wenn Sie unsere Plattform besuchen und/oder nutzen oder uns
              kontaktieren, können wir die folgenden Informationen erheben:
              <br />
              <li className="bulletPoints">
                Personenbezogene Informationen, die Sie uns mitteilen, wenn Sie
                ein Konto anlegen, sich für den Empfang von E-Mails, Newslettern
                und Benachrichtigungen anmelden und welche Sie uns bei der
                Nutzung unserer Dienste zur Verfügung stellen, einschliesslich
                solcher Informationen, die Sie auf unserer Buchungsplattform
                eingeben und die in Ihren Kommentaren, Bewertungen oder
                Umfrageantworten enthalten sind. Im Rahmen einer Buchung oder
                der Übermittlung einer Bewertung können Sie uns freiwillig
                sensitive personenbezogene Daten zur Verfügung stellen, wenn
                diese für den von Ihnen angeforderte oder bewertete
                Dienstleistung von Bedeutung sind (z.B. Geburtstag oder
                hinsichtlich Ihrer Gesundheit oder ethnischen Herkunft)
              </li>
              <li className="bulletPoints">
                Personenbezogene Daten, die Sie uns im Rahmen einer Bewerbung
                für eine Stelle bei Softrey zur Verfügung stellen, entweder
                direkt, auf den Webseiten oder indirekt, einschliesslich, jedoch
                nicht beschränkt auf, Daten über eine
                Personalvermittlungsagentur, eine Initiativbewerbung oder eine
                Rekrutierungsplattform von Dritten. In Bezug auf Ihre Bewerbung
                können Sie uns freiwillig sensible, personenbezogene Daten zur
                Verfügung stellen, die für den Einstellungsprozess oder
                Anschlussprozesse, wenn ein Arbeitsverhältnis bereits zustande
                gekommen ist, relevant sind. Sie dürfen jedoch keine sensiblen,
                persönlichen Daten an uns weitergeben, die nicht erforderlich
                sind, für die Beurteilung hinsichtlich der Bewerbung oder
                Einstellung. Darunter fallen z.B. politische Meinungen,
                religiöse Überzeugungen oder spezifische Informationen zu Ihrem
                Gesundheitszustand.
              </li>
              <li className="bulletPoints">
                Wenn Sie unsere Online-Zahlungsmöglichkeit nutzen, können wir
                Ihre Kredit- und EC-Bankkartendaten (selbst oder über unseren
                Zahlungsdienstleister) speichern. Die Speicherung erfolgt stets
                auf sicher verschlüsselter Grundlage
              </li>
              <li className="bulletPoints">
                Wenn Sie mit uns kommunizieren (z.B. über die Plattform, E-Mail,
                Telefon, SMS oder Social Media), werden wir den Umstand
                aufzeichnen, dass Sie uns kontaktiert haben und den Inhalt Ihrer
                Kommunikation zusammen mit Ihren Kontaktdaten und unseren
                Antworten speichern
              </li>
              <li className="bulletPoints">
                Informationen über Ihre Besuche und Nutzung der Plattform, wie
                z.B. Informationen über das von Ihnen verwendete Gerät und Ihren
                Browser, Ihre IP-Adresse und Ihren Standort, Ihre
                Werbekunden-Kennung („identifier for advertisers“; IDFA), das
                Datum und die Uhrzeit sowie die Dauer Ihres Besuchs, die
                Verweisquelle und den URL-Verlauf Ihres Besuchs der Webseite und
                Ihre Interaktionen auf der Plattform einschliesslich der
                Information an welchen Partnern und Behandlungen Sie
                interessiert sind. Bitte beachten Sie, dass wir diese
                Informationen mit Ihrem Benutzer-Konto verknüpfen können.
                Weitere Informationen zu den Zwecken, für die wir diese
                Informationen sammeln und verwenden, finden Sie im Abschnitt
                Cookies dieser Datenschutzerklärung
              </li>
              <br />
              Es ist wichtig, dass alle Personenbezogenen Informationen, die Sie
              uns bei der Registrierung als Benutzer oder anderweitig bei der
              Nutzung der Plattform zur Verfügung stellen, korrekt und aktuell
              sind. Dies bedeutet z.B. die Sicherstellung Ihrerseits, dass wir
              jederzeit über Ihre korrekten Kontaktdaten (einschliesslich E-Mail
              Adresse und Geburtsdatum) verfügen.
              <br />
              Beabsichtigen Sie, Personenbezogene Informationen einer anderen
              Person (z.B. bei einer Buchung in deren Namen) uns zur Verfügung
              zu stellen, sollten Sie dies nur tun, wenn diese Person
              eingewilligt hat und nachdem diese Person Informationen darüber
              erhalten hat, wie wir deren Daten verwenden werden,
              einschliesslich der in dieser Datenschutzerklärung niedergelegten
              Zwecke.
              <br />
              <br />
              <h4 className="title">
                Schutz Ihrer Personenbezogenen Informationen
              </h4>
              <br />
              Die Übertragung von Informationen über das Internet ist nicht
              vollkommen sicher. Obwohl wir unser Bestes tun werden, um Ihre
              personenbezogenen Daten zu schützen, können wir die Sicherheit
              Ihrer an unsere Plattform übermittelten Daten nicht garantieren.
              Jede Übertragung erfolgt auf eigene Gefahr. Sobald wir Ihre
              Personenbezogenen Informationen erhalten haben, werden wir in
              Bezug auf diese Daten strenge Sicherheitsmassnahmen und
              –prozeduren einsetzen.
              <br />
              <br />
              <h5 className="smallTitle">
                Wie verwenden wir Ihre Personenbezogenen Informationen?
              </h5>
              Grundsätzlich verwenden wir Personenbezogene Informationen, um
              Ihnen die von Ihnen gewünschten Dienstleistungen und Produkte zur
              Verfügung zu stellen, Zahlungen abzuwickeln, Kundenservice zu
              erbringen, Inhalte und Werbung anzuzeigen, die unserer Auffassung
              nach für Sie von Interesse sein könnten, um Ihnen Marketing- und
              Werbe-E-Mails zu versenden und Sie über wichtige Änderungen an
              unserer Plattform zu informieren.
              <br />
              <br />
              <strong>
                Ferner verwenden wir Ihre Personenbezogenen Informationen auch
                zu den folgenden Zwecken:
              </strong>
              <br />
              <li className="bulletPoints">
                <strong>
                  Zur Erfüllung eines Vertrages bzw. zur Durchführung von
                  Massnahmen im Zusammenhang mit einem Vertrag.
                </strong>
                {' '}
                Dies ist von Bedeutung, wenn Sie eine Dienstleistung von Softrey
                in Anspruch nehmen. Dies umfasst:
                {' '}
              </li>
              <li className="bulletPointsNestLi">
                Die Zusendung von Informationen über Ihre angeforderten
                Dienstleistungen (z.B. Terminerinnerungen) per E-Mail, SMS und
                Push-Benachrichtigung
              </li>
              <li className="bulletPointsNestLi">
                Erleichterung von Buchungen und Zahlungen
              </li>
              <li className="bulletPointsNestLi">
                Beantwortung Ihrer Fragen und Anliegen
              </li>
              <li className="bulletPointsNestLi">Verwaltung Ihres Kontos</li>
              <br />
              <li className="bulletPoints">
                Soweit es für Softrey erforderlich ist,
                {' '}
                <strong>
                  um unsere Geschäfte zu führen und um unsere berechtigten
                  Interessen zu wahren
                </strong>
                , insbesondere:
              </li>
              <li className="bulletPointsNestLi">
                Um die Sicherheit der Plattform und unserer Systeme zu
                gewährleisten und Betrug, Sicherheitsvorfälle und Straftaten zu
                verhindern
              </li>
              <li className="bulletPointsNestLi">
                Zur Überprüfung der Einhaltung unserer AGB und zur Begründung,
                Ausübung oder Verteidigung von Rechtsansprüchen
              </li>
              <li className="bulletPointsNestLi">
                Um Sie über neue Softrey-Dienstleistungen und Sonderangebote zu
                benachrichtigen, die Sie unserer Auffassung nach interessieren
                werden, z.B. per E-Mail und durch die Anzeige von Werbung auf
                Webseiten Dritter wie Facebook (soweit keine Einwilligung
                erforderlich ist)
              </li>
              <li className="bulletPointsNestLi">
                Um auf der Grundlage der von Ihnen bereitgestellten
                Informationen und Ihrer Nutzung unserer Plattform über Produkte
                und Dienstleistungen Marketingbenachrichtigungen und Werbung für
                Sie anzupassen und zu personalisieren (weitere Informationen
                finden Sie im Abschnitt Cookies dieser Datenschutzerklärung)
              </li>
              <li className="bulletPointsNestLi">
                Um uns zu helfen, unsere Plattform, Produkte und
                Dienstleistungen zu überwachen, zu verbessern und zu schützen
                und unsere Plattform, Produkte, Dienstleistungen (und die
                unserer Partner) für Sie zu personalisieren, basierend auf den
                von Ihnen zur Verfügung gestellten Informationen und Ihrer
                Nutzung unserer Plattform, Produkte und Dienstleistungen und
                anderer Webseiten (weitere Informationen finden Sie im Abschnitt
                „Cookies“ dieser Datenschutzerklärung)
              </li>
              <li className="bulletPointsNestLi">
                Um Fragen oder Beschwerden von Ihnen oder anderen Personen
                bezüglich unserer Plattform oder unserer Produkte und
                Dienstleistungen zu untersuchen und zu beantworten
              </li>
              <li className="bulletPointsNestLi">
                Um interne Recherchen und Analysen durchzuführen, damit wir
                sehen können, wie unsere Plattform, unsere Produkte und
                Dienstleistungen genutzt werden und wie sich unser Geschäft
                entwickelt
              </li>
              <li className="bulletPointsNestLi">
                Um Marktforschung und Verbraucherbefragungen durchzuführen
              </li>
              <li className="bulletPointsNestLi">
                Um Bewertungen der von unseren Partnern angebotenen Produkte
                oder Dienstleistungen zu sammeln und zu veröffentlichen und
                diese für Werbekampagnen zu nutzen
              </li>
              <li className="bulletPointsNestLi">
                Zur Bearbeitung von Bewerbungen für eine Stelle bei Softrey, ob
                direkt oder indirekt eingereicht, einschliesslich der Prüfung
                Ihrer Eignung für die Rolle, des Lebenslaufs und der beruflichen
                Qualifikation, zur Planung von Interviews und Beurteilungen, zur
                Kontaktaufnahme mit angegebenen Referenzen und, falls
                erfolgreich, Ihnen eine Stelle anzubieten und ein
                Arbeitsverhältnis mit Ihnen aufzubauen. Informationen, die Sie
                uns in diesem Zusammenhang zur Verfügung stellen, werden für
                einen Zeitraum von 12 Monaten aufbewahrt und können für den
                Fall, dass Sie sich zukünftig für eine Tätigkeit bei Softrey
                bewerben, herangezogen werden
              </li>
              <br />
              <li className="bulletPoints">
                <strong>
                  Soweit Sie in die Verarbeitung Ihrer Daten eingewilligt haben:
                </strong>
              </li>
              <li className="bulletPointsNestLi">
                Um Sie über neue Softrey-Dienste und Sonderangebote zu
                informieren, die Sie unserer Auffassung nach interessieren
                werden, z.B. per E-Mail und durch die Anzeige von Werbung auf
                Webseiten Dritter, etwa Facebook (soweit eine Einwilligung
                erforderlich ist)
              </li>
              <li className="bulletPointsNestLi">
                Um Ihnen Informationen über Wettbewerbe und Umfragen sowie
                Details zu Werbeangeboten unserer Partner und zu anderen
                ausgewählten Waren oder Dienstleistungen Dritter, z.B.
                bestimmter Gesundheits-, Beauty-, Freizeit- und
                Lifestyle-Marken, per E-Mail und als Push-Benachrichtigung über
                die App zu senden
              </li>
              <li className="bulletPointsNestLi">
                Um Ihre persönlichen Daten an unsere Partner weiterzugeben,
                damit diese Ihre persönlichen Daten bei einer erstmaligen
                Buchung durch Sie automatisch zur Verfügung erhalten.
              </li>
              <li className="bulletPointsNestLi">
                Um Cookies zu setzen und ähnliche Technologien in
                Übereinstimmung mit dem Abschnitt Cookies dieser
                Datenschutzerklärung und den Informationen, die Ihnen bei der
                Verwendung dieser Technologien zur Verfügung gestellt werden, zu
                verwenden.
              </li>
              <li className="bulletPointsNestLi">
                Zur Nutzung der Kundenkommunikation für Schulungen,
                Aufzeichnungen und Qualitätskontrollen. Wenn Sie unseren
                Kundendienst anrufen und Sie sich mit der Anmeldung
                einverstanden erklären, wird Ihr Anruf möglicherweise
                aufgezeichnet. Sie werden informiert, wenn eine solche
                Aufzeichnung erstellt wird, und Sie werden gebeten, sich zu
                registrieren, um Ihre Zustimmung und die Mittel dafür zu zeigen.
              </li>
              <li className="bulletPointsNestLi">
                In anderen Fällen, in denen wir Sie um Ihre Einwilligung bitten,
                werden wir die Daten für die in diesem Rahmen erläuterten Zwecke
                verarbeiten.
              </li>
              <br />
              <li className="bulletPoints">
                <strong>Für gesetzlich vorgeschriebene Zwecke:</strong>
              </li>
              <li className="bulletPointsNestLi">
                Um auf Ersuchen der Regierung oder der Strafverfolgungsbehörden,
                die eine Untersuchung durchführen, zu antworten.
              </li>
              <li className="bulletPointsNestLi">
                Um gesetzlichen, regulatorischen und Compliance-Anforderungen
                gerecht zu werden
              </li>
              <br />
              <br />
              <h4 className="title">
                Widerruf der Einwilligung und anderweitiger Widerspruch gegen
                Direktmarketing
              </h4>
              <br />
              Wann auch immer wir uns auf Ihre Einwilligung berufen, können Sie
              diese immer jederzeit widerrufen, obgleich uns andere
              Rechtsgrundlagen für die Verarbeitung Ihrer Daten zu anderen
              Zwecken, wie den oben genannten, zur Verfügung stehen können. In
              bestimmten Fällen dürfen wir Ihnen gegenüber ohne Ihre
              Einwilligung Direktmarketing auf Grundlage der Wahrung unserer
              berechtigten Interessen betreiben. Sie haben ein uneingeschränktes
              Recht auf jederzeitigen Widerspruch gegen Direktmarketing. Sie
              können dieses Recht ausüben, indem Sie den Anweisungen in der
              Kommunikation folgen, wenn es sich um eine elektronische Nachricht
              handelt, oder indem Sie uns über die unten aufgeführten
              Kontaktinformationen benachrichtigen.
              <br />
              <li className="bulletPoints">
                Wenn Sie den Erhalt von Mitteilungen, die Softrey Ihnen
                zusendet, abmelden möchten, können Sie dies tun, indem Sie auf
                die Schaltfläche "Abmelden" am Ende der E-Mail klicken oder
                indem Sie uns unter kunde@therapeutenbuchen.ch kontaktieren. Im
                Falle von E-Mail-Marketing kann die Entfernung Ihrer
                E-Mail-Adresse aus unserem System bis zu 48 Geschäftsstunden in
                Anspruch nehmen
              </li>
              <li className="bulletPoints">
                Wenn Sie den Empfang von Mitteilungen, die von Partnern und
                anderen Dritten gesendet werden, abbestellen möchten, wenden Sie
                sich bitte direkt an den Partner oder an den Dritten. Wenn Sie
                diesbezüglich hinsichtlich unserer Partner unsere Hilfe
                benötigen, tun wir gerne, was wir können
              </li>
              <li className="bulletPoints">
                Wenn Sie keine Push-Benachrichtigungen mehr über die App
                erhalten möchten, können Sie die entsprechende Berechtigung für
                die App in den Betriebssystemeinstellungen Ihres
                Telefons/Computers widerrufen, resp. stoppen.
              </li>
              <br />
              <br />
              <h4 className="title">
                Weitergabe Personenbezogener Informationen an Dritte
              </h4>
              <br />
              Wir nehmen die Sicherheit Ihrer Personenbezogenen Informationen
              und die Art und Weise der diesbezüglichen Verarbeitungen sehr
              ernst. Unter keinen Umständen werden wir Ihre Personenbezogenen
              Informationen verkaufen.
              <br />
              <br />
              Jedoch können wir Ihre Personenbezogenen Informationen an mit uns
              verbundene Unternehmen wie Konzerngesellschaft oder Tochterfirmen
              weitergeben. Zudem in folgenden Fällen:
              <br />
              <br />
              Wenn Sie eine Bestellung aufgeben oder sich anderweitig über eine
              Webseite oder eine App, die im Namen eines Dritten betrieben wird,
              mit Softrey in Verbindung setzen, werden Ihre Kontaktdaten und
              Bestellungsdetails an den entsprechenden Dritten weitergegeben.
              Wenn Sie einem Dritten entsprechende Einwilligungen erteilen, kann
              dieser Ihnen auch Marketingmitteilungen zusenden.
              <br />
              <br />
              <li className="bulletPoints">
                An Drittanbieter, die in unserem Namen Aufgaben im Zusammenhang
                mit der Plattform oder sonst im Zusammenhang mit der Führung
                unseres Geschäfts stehen, wie das Anwerben von Kandidaten zur
                Durchführung von Dienstleistungen über Softrey, die
                Kaufabwicklung und die Provisionen (z.B. Bearbeitung von
                Kreditkartenzahlungen, Webseite-Hosting, Durchführung von
                Umfragen und Marktforschung, Bereitstellung von Social
                Media-Analysen, Bereitstellung von Marketing-E-Mail-Diensten,
                Datenanalyse-Tools und Verwaltung der
                Kundendienst-Kommunikationen).
              </li>
              <li className="bulletPoints">
                An unsere Partner, von denen Sie Dienstleistungen entweder über
                unsere Webseite oder Apps buchen, soweit wir als gewerblicher
                Buchungsvermittler handeln, oder direkt an unsere Partner über
                unsere Partnerplattform soweit wir als blosser
                Technologieanbieter auftreten. Ihre Informationen werden an
                Partner übermittelt, damit
              </li>
              <li className="bulletPointsNestLi">
                die Buchungen erleichtert werden und, soweit erforderlich, eine
                Kontaktaufnahme vor dem Termin erfolgt;
              </li>
              <li className="bulletPointsNestLi">
                E-Mails mit Neuigkeiten und Sonderangeboten an Sie gesendet
                werden können, wenn Sie in den Erhalt von Marketing-E-Mails
                durch Partner, von denen Sie bestellt haben, eingewilligt haben;
                und
              </li>
              <li className="bulletPointsNestLi">
                diese die von Ihnen an ihre Kunden angebotenen Dienstleistungen
                und Geschäftsabläufe verbessern können, indem diesen
                statistische Daten über Kunden sowohl in aggregierter Form als
                auch individueller Basis zur Verfügung gestellt werden.
              </li>
              <li className="bulletPoints">
                An Drittmarken, mit denen wir bei Produkten, Dienstleistungen,
                Wettbewerben und Kampagnen zusammenarbeiten. An Drittanbieter
                von Admin-Software, die von unseren Partnern verwendet wird, um
                die Echtzeitverfügbarkeit dieser Softwarelösungen und Buchungen
                sicherzustellen.
              </li>
              <li className="bulletPoints">
                An Medienagenturen und Werbepartner, um gezielte
                Marketingkampagnen („targeted marketing“) durchzuführen (weitere
                Informationen zu Online-Werbepraktiken finden Sie im Abschnitt
                Cookies dieser Datenschutzerklärung).
              </li>
              <li className="bulletPoints">
                An andere Dritte, um unseren Geschäftsbetrieb zu erleichtern und
                unsere Produkte, Inhalte, Dienstleistungen oder Werbemassnahmen
                zu verbessern, z.B. durch das Versenden von E-Mails oder das
                Veröffentlichen von Bewertungen unserer Produkte und
                Dienstleistungen bzw. der unserer Partner.
              </li>
              <li className="bulletPoints">
                Wenn wir ein Unternehmen oder Unternehmensteile kaufen,
                verkaufen oder übertragen werden. Sollte dies der Fall sein,
                müssen wir Ihre Personenbezogenen Informationen gegebenenfalls
                an den Verkäufer oder Käufer offenlegen.
              </li>
              <li className="bulletPoints">
                Wenn wir verpflichtet sind, Ihre personenbezogenen Daten
                offenzulegen oder weiterzugeben, um gesetzlichen Verpflichtungen
                nachzukommen oder um unsere Allgemeinen Geschäftsbedingungen und
                andere Vereinbarungen durchzusetzen oder anzuwenden oder die
                Rechte, das Eigentum und die Sicherheit unserer Kunden oder
                anderer zu schützen. Dazu gehört auch der Austausch von
                Informationen mit anderen Unternehmen und Organisationen zum
                Schutz vor Betrug und zur Reduzierung des Kreditrisikos.
              </li>
              <li className="bulletPoints">
                An Behörden und andere Dritte, einschliesslich, aber nicht
                beschränkt auf, aufgrund gerichtlicher Anordnungen, soweit dies
                gesetzlich erforderlich oder zulässig ist. Wir können
                Benutzerinformationen auch offenlegen, wenn wir Grund zu der
                Annahme haben, dass jemand die Rechte oder das Eigentum von uns,
                von anderen Benutzern unserer Plattform oder von anderen
                Personen, die durch solche Aktivitäten geschädigt werden
                könnten, verletzt oder beeinträchtigt.
              </li>
              <br />
              <br />
              Bitte beachten Sie: Durch die Veröffentlichung von Persönlichen
              Informationen auf öffentlich zugänglichen Bereichen unserer
              Plattform (z.B. in den Bewertungs-Bereichen) können diese
              Informationen von Dritten erhoben werden, auf die wir keinen
              Einfluss haben. Wir sind nicht verantwortlich für die Verwendung
              solcher Informationen durch solche Dritten.
              <br />
              <br />
              Bitte wenden Sie daher alle gebührende Sorgfalt und Rücksicht an,
              bevor Sie Persönliche Informationen in öffentlich zugänglichen
              Bereichen offen legen. Sie sollten vermeiden, in öffentlich
              zugänglichen Bereichen Personenbezogene Informationen
              offenzulegen, die zur Identifizierung Ihrer Person verwendet
              werden können (wie z.B. Ihr Name, Ihr Alter, Ihre Anschrift oder
              der Name Ihres Arbeitgebers).
              <br />
              <br />
              <h5 className="smallTitle">
                Ihre Rechte hinsichtlich Ihrer Personenbezogenen Informationen
              </h5>
              In Bezug auf Ihre von uns gespeicherten Personenbezogenen
              Informationen können Sie uns gegenüber folgende Rechte geltend
              machen:
              <br />
              <li className="bulletPoints">
                Das Recht auf eine Kopie Ihrer Personenbezogenen Informationen
              </li>
              <li className="bulletPoints">
                Das Recht auf Berichtigung, Löschung oder Einschränkung der
                Verarbeitung Ihrer Personenbezogenen Informationen
              </li>
              <li className="bulletPoints">
                Das Recht, Ihre Personenbezogene Daten, die Sie uns im Rahmen
                eines Vertrages oder aufgrund Ihrer Einwilligung zur Verfügung
                gestellt haben in strukturierter und maschinenlesbarer Form zu
                erhalten sowie das Recht, dass wir diese Daten auf Ihre
                Aufforderung hin an eine andere Organisation übermitteln.
              </li>
              <li className="bulletPoints">
                Das Recht auf Widerspruch gegen die Verarbeitung Ihrer
                personenbezogenen Daten in bestimmten Fällen (insbesondere, wenn
                die Verarbeitung der Daten nicht zur Erfüllung einer
                vertraglichen oder gesetzlichen Verpflichtung erforderlich ist
                oder soweit wir die Daten für Direktmarketing verwenden).
              </li>
              In erster Linie ist es Ihnen möglich, die Informationen, die Sie
              uns zur Verfügung gestellt haben, einzusehen oder zu ändern, indem
              Sie sich in Ihr Online-Konto einloggen.
              <br />
              <br />
              Ihre Rechte in Bezug auf Ihre Personenbezogenen Informationen sind
              in einigen Fällen eingeschränkt. Dies kann der Fall sein, wenn zum
              Beispiel die Erfüllung Ihrer Anfrage mit einer Preisgabe von
              personenbezogenen Daten einer andere Person einhergehen würde oder
              wenn wir eine gesetzliche Verpflichtung oder einen zwingenden
              schutzwürdigen Grund haben, um die Daten, um deren Löschung Sie
              uns gebeten haben, weiterhin zu verarbeiten.
              <br />
              <br />
              Wenn Sie den Eindruck haben, dass Ihre Personenbezogenen
              Informationen missbräuchlich verwendet worden sind, können Sie
              ferner das Recht haben, eine Beschwerde einzureichen. Wir bitten
              Sie, in diesen Fällen zunächst mit uns Kontakt aufzunehmen; soweit
              Ihnen jedoch das Recht zur Beschwerde zukommt, sind Sie auch
              berechtigt, sich direkt an die zuständige Aufsichtsbehörde zu
              wenden.
              <br />
              <br />
              <strong>
                Wenn Sie eines der oben genannten Rechte ausüben möchten,
                kontaktieren Sie uns bitte unter
                {' '}
                <a href="mailto:kunde@therapeutenbuchen.ch">
                  kunde@therapeutenbuchen.ch
                </a>
                . Bitte beachten Sie jedoch, dass wir ohne vorherige Prüfung
                ihrer Identität keine Finanzdaten zur Verfügung stellen.
              </strong>
              <br />
              <br />
              <h4 className="title">
                Was ist mit anderen Webseiten, die mit unserer Plattform
                verlinkt sind?
              </h4>
              <br />
              Wir sind weder für die Inhalte von Webseiten, die mit oder von
              unserer Plattform verlinkt sind, noch für die darin enthaltenen
              Informationen oder Inhalte verantwortlich. Häufig werden Links zu
              anderen Webseiten lediglich als Hinweis auf Informationen zu
              Themen angeboten, die für die Nutzer unserer Plattform interessant
              sein können.
              <br />
              Bitte denken Sie daran, dass unsere Datenschutzerklärung nicht für
              andere Webseiten gilt, die sie über einen Link auf unserer
              Plattform erreichen. Das Besuchen von und die Interaktion mit
              anderen Webseiten, einschliesslich solcher Webseiten, die auf
              unserer Plattform verlinkt sind, unterliegt den dort gültigen
              Regeln und Richtlinien. Lesen Sie diese Regelung und Richtlinien
              stets, bevor Sie fortfahren.
              <br />
              <br />
              <h4 className="title">
                Social Media und nutzergenerierte Inhalte
              </h4>
              <br />
              Einige unserer Plattformen ermöglichen es Nutzern, ihre eigenen
              Inhalte einzustellen. Bitte denken Sie daran, dass alle Inhalte,
              die an eine unserer Plattformen übermittelt werden, von der
              Öffentlichkeit eingesehen werden können. Sie sollten bestimmte
              Personenbezogene Informationen wie z.B. Finanzinformationen oder
              Adressdaten über diese Plattformen nur mit Bedacht zur Verfügung
              stellen. Wir sind nicht verantwortlich für Handlungen anderer
              Personen, wenn Sie Personenbezogene Informationen auf einer
              unserer Social Media Plattformen, z.B. Facebook oder Instagram,
              einstellen. Bitte beachten Sie auch die jeweiligen Datenschutz-
              und Cookie-Richtlinien der von Ihnen verwendeten Social Media
              Plattformen.
              <br />
              <br />
              <h4 className="title">Änderungen unserer Datenschutzerklärung</h4>
              <br />
              Wenn wir unsere Datenschutzerklärungen und -verfahren ändern,
              werden wir diese Änderungen auf unserer Plattform veröffentlichen,
              um Sie darüber zu informieren, welche Informationen wir erheben,
              wie wir sie benutzen und unter welchen Umständen wir sie
              übermitteln.
              <br />
              <br />
              <h4 className="title">
                Wo wir Ihre personenbezogenen Daten speichern
              </h4>
              <br />
              Die Daten, die wir von Ihnen erheben, können an einen
              Bestimmungsort ausserhalb des Europäischen Wirtschaftsraums (EWR)
              übermittelt und dort gespeichert werden. Dies beinhaltet die
              Übermittlung zum Zwecke der Verarbeitung dieser Daten durch
              ausgewählte Dritte, um die Geschäftstätigkeit von Softrey zu
              erleichtern. In Ländern ausserhalb des EWR gibt es möglicherweise
              keine Gesetze, die das gleiche Schutzniveau für Ihre
              personenbezogenen Daten gewährleisten wie die Gesetze innerhalb
              des EWR. Wenn dies der Fall ist, werden wir angemessene
              Sicherheitsvorkehrungen treffen, um sicherzustellen, dass diese
              Übertragungen den geltenden Datenschutzgesetzen entsprechen.
              <br />
              <br />
              <h4 className="title">PayPal-Transaktionen</h4>
              <br />
              Bitte beachten Sie, dass alle PayPal-Transaktionen den PayPal
              EWR-Datenschutzgrundsätzen unterliegen, die Sie hier abrufen
              können: www.paypal.com/ch/webapps/mpp/ua/privacy-full. Bitte
              stellen Sie sicher, dass Sie mit den Bestimmungen der PayPal
              EWR-Datenschutzgrundsätzen einverstanden sind, wenn Sie PayPal
              verwenden möchten, um Transaktionen über die Plattform
              abzuschliessen.
              <br />
              <br />
              <h4 className="title">Aufbewahrung</h4>
              <br />
              Wir bewahren Ihre Personenbezogenen Informationen auf, solange es
              zur Erfüllung der in dieser Datenschutzerklärung dargelegten
              Zwecke erforderlich ist, wir Ihnen dies anderweitig kommuniziert
              haben oder solange dies nach dem anwendbaren Recht erlaubt ist.
              Zum Beispiel:
              <li className="bulletPoints">
                Wenn Sie sich über direkt oder indirekt auf eine Position bei
                Softrey bewerben, werden die von Ihnen zur Verfügung gestellten
                Daten für einen Zeitraum von maximal 12 Monaten gespeichert.
              </li>
              <li className="bulletPoints">
                Wenn Sie sich telefonisch mit uns in Verbindung setzen und die
                Aufzeichnung nicht ablehnen, bewahren wir die
                Anrufaufzeichnungen für bis zu 90 Tage ab dem Datum des Anrufs
                auf.
              </li>
              <br />
              <br />
              <h4 className="title">Unsere Cookie-Richlinien</h4>
              <br />
              Wie auch andere kommerzielle Webseiten und Apps verwendet unsere
              Plattform Standard-Softwareanwendungen wie Cookies und ähnliche
              Tools, etwa Webserver-Protokolle, Web-Beacons, Token, Pixel-Tags,
              lokale Speicherung („local storage“), Gerätekennungen und Tracking
              IDs (in dieser Datenschutzerklärung zusammen bezeichnet als
              {' '}
              <strong>"Cookies"</strong>
              ) für die unten aufgeführten Zwecke.
              Beachten Sie, dass Dritte Daten von auf der Plattform platzierten
              Cookies erheben und verarbeiten. Die Datenschutzmassnahmen dieser
              Dritten sind von dieser Datenschutzerklärung nicht erfasst. Wir
              empfehlen Ihnen, die Datenschutzerklärungen dieser Dritten zu
              lesen. Falls Sie es vorziehen, dass Ihre Daten nicht von diesen
              Dritten erhoben werden, sollten Sie, sofern vorhanden, deren
              Opt-out-Optionen nutzen. Bitte überprüfen Sie den Abschnitt
              {' '}
              <strong>
                "Was kann ich tun, um meine Cookie-Einstellungen zu ändern?"
              </strong>
              {' '}
              für weitere Informationen.
              <br />
              <br />
              <h5 className="smallTitle">Warum verwenden wir Cookies?</h5>
              <li className="bulletPoints">
                <strong>Zwingende Notwendigkeit/Transaktions-Zwecke</strong>
                <br />
                Die Verwendung von Cookies für diesen Zweck ist für die
                ordnungsgemässe Funktion unserer Plattform unerlässlich. Cookies
                können erforderlich, damit Sie sich auf unserer Plattform
                bewegen und deren Funktionen nutzen können, ferner zur
                Systemadministration, zur Verhinderung betrügerischer
                Aktivitäten, zur Beibehaltung ihres Login-Status beim
                Seitenwechsel oder zur Speicherung der Inhalte Ihres Warenkorbs.
                Diese Cookies können nicht ausgeschaltet werden, da unsere
                Plattform ohne sie nicht ordnungsgemäss funktionieren kann.
                <br />
                Beispiele:
                <br />
                <strong>ITKT / ATKT</strong>
                 - Sie bleiben eingeloggt, während Sie sich auf der Plattform
                bewegen.
                <br />
                <strong>venue-basket-123456</strong>
                 – Ihre Produkte bleiben im Warenkorb gespeichert, während Sie
                sich auf der Plattform bewegen.
              </li>
              <li className="bulletPoints">
                <strong>Funktionelle Zwecke</strong>
                <br />
                Die Verwendung von Cookies zu diesen Zwecken ermöglicht es uns,
                Ihr Benutzererlebnis zu verbessern und zu erleichtern. Die zu
                diesen Zwecken gesetzten Cookies können z.B. das Land, von dem
                aus Sie unsere Plattform besuchen, oder Ihre Einstellungen wie
                Sprache oder Suchparameter (z-B. Datum, Gebiet oder Behandlung)
                speichern und helfen, Probleme mit unserer Plattform zu
                identifizieren (z.B. Abstürze). Diese Erkenntnisse ermöglichen
                es, Ihnen eine auf Ihre individuellen Bedürfnisse abgestimmte,
                verbesserte Erfahrung zu bieten. Die Informationen, die diese
                Cookies erheben, können Sie gegebenenfalls persönlich
                identifizieren, z.B. im Fall Ihrer Anmeldedaten. Die Cookies
                können Ihre Browsing-Aktivitäten auf anderen Webseiten oder
                Anwendungen ausserhalb der Plattform nicht verfolgen. Hierfür
                verwenden wir andere Tools, wie z.B. Snowplow.
                <br />
                Beispiele:
                <br />
                <strong>preferred_language</strong>
                 - Erinnert sich an Ihre Sprachauswahl auf unseren
                mehrsprachigen Webseiten.
                <br />
                <strong>homepage-search </strong>
                - merkt sich die Einstellungen der Homepage-Suche für Ihren
                nächsten Besuch
                <br />
                <strong>recent-locaticons </strong>
                - Erinnert sich an zuletzt
                gesuchte Orte
              </li>
              <li className="bulletPoints">
                <strong>Performance-Zwecke</strong>
                <br />
                Durch den Einsatz von Cookies für diese Zwecke können wir besser
                nachvollziehen, wie viele Nutzer unsere Plattform besuchen, wie
                Nutzer auf unsere Plattform gelangen und welche Teile unserer
                Plattform am beliebtesten sind. Dies hilft uns, unsere Produkte
                und Dienstleistungen und die Plattform, etwa in den Bereichen
                Navigation und Interface-Erfahrung, zu verbessern. Um dies zu
                erreichen, erheben wir Informationen darüber, wie unsere
                Plattform genutzt wird. Dazu verwenden wir auch Lösungen von
                Drittanbietern, einschliesslich, aber nicht beschränkt auf,
                Google Analytics, Snowplow, Optimizely, Hotjar, AppsFlyer,
                Fabric Analytics, Firebase und Crashlytics. Wir verwenden
                Cookies auch, um die Effektivität unserer Online-Marketing- und
                Werbeaktivitäten zu messen. Zu diesem Zweck verwenden wir einige
                Drittanbieter, einschliesslich, aber nicht beschränkt auf,
                Salesforce Marketing Cloud, Snowplow, Impact Radius, Awin,
                DoubleClick Floodlight, Bing sowie Tools bestimmter Social Media
                Plattformen (einschliesslich Facebook und Snapchat).
                <br />
                Beispiele:
                <br />
                <strong>Google Analytics</strong>
                 ist ein Webanalysedienst der Google Inc. Wir verwenden Google
                Analytics, um zu beobachten, wie Nutzer unsere Plattform
                verwenden, um Berichte zu erstellen und die Plattform zu
                verbessern. Google erhebt Informationen in anonymisierter Form,
                einschliesslich der Anzahl der Besucher der Plattform, von
                welchen Webseiten die Besucher auf die Plattform gekommen sind,
                und der Seiten, die sie auf der Plattform besucht haben. Diese
                Informationen werden dann von Google verwendet, um Berichte für
                uns zu erstellen und die Webseite zu verbessern. Weitere
                Informationen zu den von Google erhobenen Daten und deren
                Verarbeitung finden Sie in den Datenschutzbestimmungen von
                Google unter https://policies.google.com/privacy?gl=ch&hl=de.
                <br />
                <strong>Hotjar</strong>
                 ist eine Software, die wir verwenden, um die
                Benutzerfreundlichkeit unserer Webseite zu verbessern
                (www.hotjar.com, 3 Lyons Range, 20 Bisazza Street, Sliema SLM
                1640, Malta, Europa). Hotjar hilft uns nachzuvollziehen, wie
                Benutzer die Webseite verwenden (Mausbewegungen, Klicks,
                Scrollen etc.). Zu diesem Zweck verwendet Hotjar
                Analyse-Cookies, um nicht personenbezogene Informationen zu
                sammeln, einschliesslich Standard-Internetprotokollinformationen
                und Einzelheiten über das Verhalten der Besucher beim Besuch
                unserer Webseite. Durch die Nutzung unserer Webseite erkennen
                Sie unsere Datenschutzerklärung sowie die von Hotjar an. Weitere
                Informationen zu den von Hotjar erfassten Daten und deren
                Verarbeitung finden Sie in der Datenschutzerklärung von Hotjar
                (www.hotjar.com/legal/policies/privacy).
              </li>
              <li className="bulletPoints">
                <strong>
                  Zielgerichtete Werbung („Targeting“) oder Werbezwecke
                </strong>
                <br />
                Die Verwendung von Cookies zu diesem Zweck ermöglicht es uns,
                Anzeigen auf und ausserhalb der Plattform anzuzeigen und
                Informationen über Ihre Surfgewohnheiten und die Nutzung der
                Plattform zu sammeln, um Anzeigen relevanter und persönlicher
                für Sie und Ihre Interessen zu gestalten. Wir verwenden
                möglicherweise Remarketing-Technologien, um es Dritten zu
                ermöglichen, Ihnen relevante und personalisierte Anzeigen über
                deren Netzwerke zu schalten. Solche Technologien werden auch
                verwendet, um festzustellen, dass Sie eine bestimmte Anzeige
                gesehen haben, um die Anzahl der Anzeigen zu begrenzen und um
                die Effektivität einer Werbekampagne zu messen. Zu diesem Zweck
                verwenden wir einige Technologien von Drittanbietern,
                einschliesslich, aber nicht beschränkt auf, Salesforce Marketing
                Cloud, Sovendus, Affiliate People, Snowplow, DoubleClick
                Floodlight, Google Analytics Audiences, Facebook Analytics, Bing
                und AppsFlyer. Technologien werden auch von Social Media Sites
                für Werbe- und Targetingzwecke eingesetzt (einschliesslich
                Facebook, Pinterest und Snapchat).
                <br />
                Diese Technologien merken sich die Webseiten, die Sie besuchen,
                und diese Informationen werden mit anderen Parteien wie z.B.
                Werbekunden geteilt. Wenn wir Dritte für Werbe- und
                Targetingzwecke einsetzen, können wir Folgendes offenlegen:
              </li>
              <li className="bulletPoints">
                Persönlich identifizierbare Informationen, wie E-Mail-Adresse,
                Bestellnummer, Veranstaltungsort.
              </li>
              <li className="bulletPoints">
                Allgemeine, aggregierte oder anonymisierte Daten über Ihre
                Besuche und die Nutzung unserer Plattform; oder
              </li>
              <li className="bulletPoints">
                Informationen in pseudonymisierter Form, wie z.B. eine
                Browser-Cookie-ID / -Code oder kryptographischer Hash Ihrer
                E-Mail-Adresse, um uns zu helfen, unsere Anzeigen für Sie auf
                anderen Diensten anzupassen und anzuzeigen. Diese ID oder dieser
                Code wird mit Ihrem entsprechenden eindeutigen Code verglichen,
                der von unseren Werbepartnern generiert wird, um die Anzeigen
                auf Sie zuzuschneiden. Die Drittfirmen, die wir für Targeting-
                und Werbezwecke einsetzen, haben ihre eigenen
                Datenschutzerklärungen, die Sie ausführlich lesen sollten.
                <br />
                Beispiele:
              </li>
              <br />
              <strong>Facebook-Targeting.</strong>
              {' '}
              Diese Cookies werden von
              Facebook gesetzt. Facebook verwendet Cookies, um das soziale Login
              zu verwalten und Daten über die Interessen seiner Nutzer zu
              sammeln. Facebook verwendet diese Daten, um gezielte Werbung
              während Ihres Surf-Erlebnisses/während Sie online sind, zu
              liefern.
              <br />
              <strong>Doubleclick Werbung.</strong>
              {' '}
              Doubleclick ist Googles
              Echtzeit-Werbebörse. Diese Cookies stellen sicher, dass Sie die
              Angebote von Softrey auf sorgfältig ausgewählten Webseiten Dritter
              sehen können, nachdem Sie die Plattform verlassen haben.
              <br />
              <strong>Google Targeting.</strong>
              {' '}
              Diese Cookies werden von Google
              gesetzt. Diese Cookies ermöglichen es Google, die Interessen der
              Besucher zu einem Profil zuzuordnen und sicherzustellen, dass
              relevante Softrey-Werbung Sie auf Webseiten Dritter beim Suchen
              erreicht. Diese Cookies können auch verwendet werden, um
              Benutzereinstellungen zu speichern und Google-Benutzerkonten zu
              überprüfen.
              <br />
              <strong>Gutscheinangebote der Sovendus GmbH.</strong>
              {' '}
              Für die
              Auswahl eines für Sie aktuell interessanten Gutscheinangebots
              werden von uns Ihre IP-Adresse an die Sovendus GmbH, Moltkestrasse
              11, 76133 Karlsruhe (Sovendus) übermittelt (Art. 6 Abs.1 f DSGVO).
              Sovendus verarbeitet Ihre IP-Adresse in verschlüsselter Form. Die
              IP-Adresse wird von Sovendus ausschliesslich zu Zwecken der
              Datensicherheit verwendet und im Regelfall nach sieben Tagen
              anonymisiert (Art. 6 Abs.1 f DSGVO). Ausserdem übermitteln wir zu
              Abrechnungszwecken Bestellnummer, Bestellwert mit Währung,
              Session-ID, Couponcode und Zeitstempel an Sovendus (Art. 6 Abs.1 f
              DSGVO). Sovendus wird diese Informationen in pseudonymisierter
              Form verarbeiten. Weitere Informationen zur Verarbeitung Ihrer
              Daten durch Sovendus entnehmen Sie bitte den Online-
              Datenschutzhinweisen unter www.sovendus.de/ch/datenschutz/.
              <br />
              <br />
              <h4 className="title">
                Wie verwenden wir Informationen, die wir von
                Tracking-Technologien sammeln?
              </h4>
              <br />
              Siehe "Wie verwenden wir Ihre persönlichen Daten?" oben.
              <br />
              <br />
              <h4 className="title">
                Was kann ich tun, um meine Cookie-Einstellungen zu ändern?
              </h4>
              <br />
              Im Folgenden finden Sie eine Reihe von Möglichkeiten, wie Sie Ihre
              Cookie-Einstellungen online verwalten können. Bitte beachten Sie
              auch die Datenschutz- und Cookie-Richtlinien des jeweiligen
              Drittanbieters für weitere Informationen.
              <li className="bulletPoints">
                Die meisten
                <strong>Browser und Geräte</strong>
                 erlauben es Ihnen, Tracking-Technologien zu entfernen und/oder
                neue Tracking-Technologien zu blockieren. Um die
                Tracking-Technologien auszuschalten, schauen Sie im Menü "Hilfe"
                Ihres Browsers nach oder besuchen Sie Ihre Geräteeinstellungen.
                Bitte beachten Sie jedoch, dass (a) dies nicht für alle
                Tracking-Technologien wirksam sein kann und (b) das Ausschalten
                von Tracking-Technologien Ihre Nutzung der Sites einschränken
                und/oder deren Betrieb verzögern oder beeinträchtigen kann.
              </li>
              <li className="bulletPoints">
                AdChoices ist ein Selbstkontrollprogramm, das
                Online-Werbeplattformen dazu anregt, auf allen Anzeigen oder
                Webseiten, auf denen Daten gesammelt und für verhaltensbasierte
                Werbung verwendet werden, ein Werbeoptionssymbol einzubinden.
                Mehr dazu erfahren Sie hier: http://youradchoices.com/control.
              </li>
              <li className="bulletPoints">
                Verwenden Sie die
                <strong>Einstellungen Ihres Mobilgeräts</strong>
                , um Ihre
                Werbepräferenzen zu konfigurieren.
              </li>
              <li className="bulletPoints">
                Besuchen Sie
                <strong>Your Online Choices</strong>
                 hier, um sich von verschiedenen interessenbezogenen
                Werbemassnahmen abzumelden www.youronlinechoices.com/ch-de/.
              </li>
              <li className="bulletPoints">
                <strong>Hotjar</strong>
                : Sie können sich jederzeit von der
                Erfassung Ihrer Daten durch Hotjar
                abmelden: www.hotjar.com/legal/compliance/opt-out.
              </li>
              <li className="bulletPoints">
                <strong>Google Analytics</strong>
                : Wenn Sie keine Daten von
                Google Analytics melden lassen möchten, können Sie das Google
                Analytics Opt-Out Browser Add-On wie folgt
                installieren: https://tools.google.com/dlpage/gaoptout?hl=de&gl=ch. Sie
                können die Verwendung von Cookies durch Google deaktivieren,
                indem Sie die Seite zur Deaktivierung von Google-Werbung hier
                besuchen: www.google.com/policies/technologies/ads/.
              </li>
              <li className="bulletPoints">
                <strong>Facebook-Anzeigen:</strong>
                {' '}
                Sie können steuern, wie
                Facebook Daten verwendet, um Ihnen Anzeigen zu zeigen, indem Sie
                Anzeigen in Ihren Anzeigeneinstellungen deaktivieren, die auf
                Interessen und/oder Ihrer Beziehung zu bestimmten
                Werbetreibenden basieren. Für weitere Informationen besuchen Sie
                bitte die verschiedenen Facebook-Seiten, auf denen Sie mehr über
                Facebook-Anzeigen und Cookies erfahren und Ihre Einstellungen
                aktualisieren können:
              </li>
              <li className="bulletPointsNestLi">
                <a href="https://www.facebook.com/policies/cookies/">
                  https://www.facebook.com/policies/cookies/
                </a>
              </li>
              <li className="bulletPointsNestLi">
                <a href="https://www.facebook.com/about/ads">
                  https://www.facebook.com/about/ads
                </a>
              </li>
              <li className="bulletPointsNestLi">
                <a href="https://www.facebook.com/ads/preferences/edit/">
                  https://www.facebook.com/ads/preferences/edit/
                </a>
              </li>
              <li className="bulletPoints">
                Bitte beachten Sie, dass Sie, wenn Sie sich gegen den Erhalt
                unserer E-Mail-Marketing-Mitteilungen über die oben unter dem
                Abschnitt "Widerruf der Einwilligung und anderweitiger
                Widerspruch gegen Direktmarketing" beschriebenen Methoden
                entschieden haben, unsere nicht zielgerichteten Anzeigen
                weiterhin sehen können, während Sie online sind, wenn Ihre
                Interesseneinstellungen auf Facebook auf ein (von Facebook
                vordefiniertes) Zielgruppensegment ausgerichtet sind, mit dem
                unser Unternehmen ebenfalls verbunden ist. Wir kontrollieren
                nicht, ob Ihnen diese Anzeigen angezeigt werden.
              </li>
              <li className="bulletPoints">
                Im Falle von Dritten, die nicht in diesem Abschnitt aufgeführt
                sind, konsultieren Sie bitte deren Datenschutz- und
                Cookie-Richtlinien, um mehr über die Anzeigen zu erfahren, die
                Ihnen angezeigt werden, während Sie online sind, und wie Sie
                sich abmelden können.
              </li>
            </p>
          </div>
        </div>
      </div>
    );
  }
}

export default Page(LandingPage);
