import {
  cleanError,
  close,
  openLogin,
  openPasswordRenew,
  openPasswordRenewSuccess,
  openPasswordReset,
  openSignup1,
  openSignup2,
  openSignup3,
  openSignup4,
  saveContactInState,
  setError,
} from '../slices/modals';

export const closeThunk = () => async (dispatch) => {
  dispatch(close());
};

export const openLoginThunk = () => async (dispatch) => {
  dispatch(openLogin());
};

export const openSignup1Thunk = () => async (dispatch) => {
  dispatch(openSignup1());
};

export const openSignup2Thunk = () => async (dispatch) => {
  dispatch(openSignup2());
};

export const openSignup3Thunk = () => async (dispatch) => {
  dispatch(openSignup3());
};

export const openSignup4Thunk = () => async (dispatch) => {
  dispatch(openSignup4());
};

export const openPasswordResetThunk = () => async (dispatch) => {
  dispatch(openPasswordReset());
};

export const openPasswordRenewThunk = () => async (dispatch) => {
  dispatch(openPasswordRenew());
};

export const openPasswordRenewSuccessThunk = () => async (dispatch) => {
  dispatch(openPasswordRenewSuccess());
};

export const saveContactInStateThunk = (contact) => async (dispatch) => {
  dispatch(saveContactInState({ contact }));
};

export const cleanErrorThunk = () => async (dispatch) => {
  dispatch(cleanError());
};

export const setErrorThunk = (errorMessage, errorMessageDev) => async (dispatch) => {
  dispatch(setError({ errorMessage, errorMessageDev }));
};
