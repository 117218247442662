/* eslint-disable no-console */

/* eslint-disable class-methods-use-this */

/* eslint-disable no-unused-vars */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { SearchOutlined } from '@ant-design/icons';
import {
  Button, Col, Input, Row, Select, Typography,
} from 'antd';
import React, { PureComponent } from 'react';
import { Navigate } from 'react-router-dom';
import _ from 'lodash';

import StoreConnector from '../../hoc/StoreConnector';
import { getSuggestionsThunk } from '../../redux/thunks/search';
import stateUtils from '../../utils/state';
import './styles.css';

const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;

class SearchHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      searchSuggestions: this.props.suggestions,
      navigateSearch: false,
    };

    this.debouncedSearch = _.debounce(this.onServiceSearch, 750);
  }

  componentDidMount() {
    if (this.props.searchSuggestions !== this.state.searchSuggestions) {
      this.setState({ searchSuggestions: this.props.suggestions }, () => this.setState({ searchSuggestions: this.props.suggestions }));
    }
  }

  onSearch() {
    this.setState({ navigateSearch: true });
  }

  onServiceChange(value) {
    this.setState({ searchValue: value });
  }

  onServiceSearch(value = '') {
    let newSuggestions = [];
    newSuggestions = newSuggestions.concat(this.props.suggestions);
    newSuggestions.unshift({ value, name: value });
    this.setState(
      { searchValue: value, searchSuggestions: newSuggestions },
      () => this.setState({
        searchValue: value,
        searchSuggestions: newSuggestions,
      }),
    );

    if (value.length >= 3) {
      this.props.dispatch(getSuggestionsThunk(value));
    }
  }

  render() {
    const { searchSuggestions, searchValue, navigateSearch } = this.state;

    return (
      <div className="searchHeader">
        {navigateSearch && <Navigate to={`/search/${searchValue}`} replace />}
        <div className="backgroundImage flex justify-center items-center">
          <div className="searchContent">
            <div className="headerText">
              Finde und Buche jetzt deinen Therapeuten
            </div>
            <div className="searchbar">
              <Row justify="center" align="middle" style={{ width: '100%' }}>
                <Col span={24} md={21} className="searchInputs">
                  <Row style={{ width: '100%' }}>
                    <Col span={24} md={12}>
                      <Row>
                        <div className="searchLabel">
                          Methode oder Name des Anbieters
                        </div>
                      </Row>
                      <Row>
                        <Select
                          showSearch
                          placeholder="wählen Sie einen Anbieter"
                          options={searchSuggestions}
                          onChange={(value) => this.onServiceChange(value)}
                          onSearch={(value) => this.debouncedSearch(value)}
                          bordered={false}
                          style={{
                            width: '100%',
                            border: 'none',
                            fontFamily: 'Inter',
                            textAlign: 'left',
                            color: '#3A3335',
                            fontSize: '14px',
                            letterSpacing: '-0.1px',
                            lineHeight: '21px',
                          }}
                          dropdownStyle={{
                            fontFamily: 'Inter',
                            textAlign: 'left',
                            color: '#3A3335',
                            fontSize: '14px',
                            letterSpacing: '-0.1px',
                            lineHeight: '21px',
                          }}
                        />
                      </Row>
                    </Col>
                    <Col span={0} md={1}>
                      <div className="divider" />
                    </Col>
                    <Col span={24} md={11}>
                      <Row>
                        <div className="searchLabel">Ort</div>
                      </Row>
                      <Row>
                        <Input
                          placeholder="geben Sie einen Ort ein"
                          allowClear
                          bordered={false}
                          style={{
                            width: '100%',
                            border: 'none',
                            fontFamily: 'Inter',
                            textAlign: 'left',
                            color: '#3A3335',
                            fontSize: '14px',
                            letterSpacing: '-0.1px',
                            lineHeight: '21px',
                          }}
                        />
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} md={3}>
                  <div className="searchButtonMd">
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => this.onSearch()}
                      icon={<SearchOutlined />}
                      style={{
                        border: 'none',
                        background: '#EB5E55',
                      }}
                    />
                  </div>
                  <div className="searchButtonMobile">
                    <Button
                      type="primary"
                      shape="round"
                      onClick={() => this.onSearch()}
                      icon={<SearchOutlined />}
                      style={{
                        border: 'none',
                        background: '#EB5E55',
                        width: '100%',
                      }}
                    >
                      Suche
                    </Button>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StoreConnector(SearchHeader, stateUtils.fullStateMap, {});
