import authInterface from '../../interfaces/api/auth';
import JwtService from '../../interfaces/jwtService';
import { signIn, signOut, signUp } from '../slices/auth';
import {
  closeThunk,
  openPasswordRenewSuccessThunk,
  openSignup3Thunk,
  openSignup4Thunk,
  saveContactInStateThunk,
  setErrorThunk,
} from './modals';

// export const invalidateCentre = () => (act(CLINIC_ACTION_TYPES.INVALIDATE_CENTRE));

export const getUserFromTokenThunk = () => async (dispatch) => {
  await authInterface
    .getUserFromToken()
    .then((response) => {
      dispatch(signIn(response));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Benutzerinformationen konnten nicht abgeholt werden. Bitte melden Sie sich erneut an.',
          err,
        ),
      );
    });
};

export const signInThunk = (email, password) => async (dispatch) => {
  await authInterface
    .signIn({ email, password })
    .then((response) => {
      const { signInToken, refreshToken } = response;

      JwtService.setAccessToken(signInToken);
      JwtService.setRefreshToken(refreshToken);

      dispatch(getUserFromTokenThunk());
      dispatch(closeThunk());
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Benutzerdaten sind inkorrekt. Bitte überprüfen Sie ihre E-Mail oder Ihr Passwort.',
          err,
        ),
      );
    });
};

export const signOutThunk = () => async (dispatch) => {
  JwtService.removeAccessToken();
  JwtService.removeRefreshToken();

  dispatch(signOut());
};

export const sendActivationCodeThunk = (data) => async (dispatch) => {
  await authInterface
    .sendActivationCode(data)
    .then(() => {
      dispatch(openSignup3Thunk());
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Der Bestätigungscode konnte nicht an eine Mobilnummer gesendet werden. Bitte verwenden Sie den Bestätigungscode der E-Mail oder überprüfen Sie ihre Mobilnummer.',
          err,
        ),
      );
    });
};

export const signUpThunk = (data, isModal = true) => async (dispatch) => {
  await authInterface
    .signUp(data)
    .then((response) => {
      dispatch(signUp(response));
      dispatch(sendActivationCodeThunk({ userId: response.user.user_id }));
      if (isModal) {
        dispatch(openSignup3Thunk());
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Registrierung fehlgeschlagen. Bitte überprüfen Sie Ihre Angaben und versuchen Sie es erneut.',
          err,
        ),
      );
    });
};

export const activateWithCodeThunk = (data) => async (dispatch) => {
  await authInterface
    .activateWithCode(data)
    .then((response) => {
      const { signInToken, refreshToken } = response;

      JwtService.setAccessToken(signInToken);
      JwtService.setRefreshToken(refreshToken);

      dispatch(getUserFromTokenThunk());
      dispatch(openSignup4Thunk());
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Regisrtierungsabschluss fehlgeschalgen. Bitte überprüfen Sie den angegebenen Code, oder versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};

export const sendResetThunk = (data) => async (dispatch) => {
  await authInterface
    .sendReset(data)
    .then(() => {
      dispatch(closeThunk());
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Passwort wiederherstellung fehlgeschlagen. Bitte überprüfen Sie Ihre E-Mail Adresse.',
          err,
        ),
      );
    });
};

export const resetPasswordThunk = (data) => async (dispatch) => {
  JwtService.setAccessToken(data.hashToken);

  await authInterface
    .resetPassword({ password: data.password })
    .then(() => {
      dispatch(openPasswordRenewSuccessThunk());
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk(
          'Passwort konnte nicht zurückgesetzt werden. Bitte versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};

export const decryptInvoiceHashThunk = (invoiceHash) => async (dispatch) => {
  await authInterface
    .getHashedInvoice(invoiceHash)
    .then((response) => {
      dispatch(saveContactInStateThunk(response));
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        setErrorThunk('Rechnung konnte nicht identifiziert werden.', err),
      );
    });
};
