import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Typography,
} from 'antd';
import 'libphonenumber-js-utils';
import React, { useRef, useState } from 'react';
import { Icon } from 'react-icons-kit';
import {
  check, envelopeO, key, pencil, warning,
} from 'react-icons-kit/fa';
import IntlTelInput from 'react-intl-tel-input';
import 'react-intl-tel-input/dist/main.css';

import _ from 'lodash';
import { EMBED_STEPS } from '../../constants/authModalTypes';
import styles from './styles';

function EmbedSignInSignUp({
  t,
  onSignInSubmit,
  onSignUpSubmitStep1,
  handleStepChange,
}) {
  const formRefSignIn = useRef('');
  const formRefSignUp = useRef('');

  const [phone, setPhone] = useState('');
  const [countryCode, setCountryCode] = useState('');
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isPhoneInputTouched, setIsPhoneInputTouched] = useState(false);

  const onPhoneNumberChange = (isValid, phoneInputValue, country) => {
    let number;
    if (window.intlTelInputUtils && window.intlTelInputUtils.formatNumber) {
      const format = window.intlTelInputUtils.numberFormat.INTERNATIONAL;
      number = window.intlTelInputUtils.formatNumber(
        phoneInputValue,
        country.iso2,
        format,
      );
    } else {
      number = phoneInputValue || '';
    }

    setPhone(number.replace(/[ +]/g, ''));
    setCountryCode(country.iso2.toUpperCase());
    setIsValidPhone(isValid);
  };

  const debouncedOnPhoneNumberChange = _.debounce(onPhoneNumberChange, 600);

  const handleSignUpSubmitStep1 = (formData) => {
    if (isValidPhone) {
      const data = {
        ...formData,
        phone,
        countryPhone: countryCode,
      };

      onSignUpSubmitStep1(data);
    } else {
      setIsPhoneInputTouched(true);
    }
  };

  return (
    <ith-div class="ith_auth-wrapper-field">
      <Form
        ref={formRefSignIn}
        name="signin-form"
        layout="vertical"
        onFinish={onSignInSubmit}
      >
        <div className="login-label">{t('label_login')}</div>
        <Row justify="space-between">
          <Col xs={24} md={11}>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: t('validator.required'),
                },
                {
                  type: 'email',
                  message: t('validator.required'),
                },
              ]}
            >
              <Input
                size="large"
                placeholder={t('placeholder_email')}
                type="email"
                prefix={<Icon icon={envelopeO} />}
              />
            </Form.Item>
          </Col>
          <Col xs={24} md={11}>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t('validator.required'),
                },
              ]}
            >
              <Input.Password
                size="large"
                placeholder={t('placeholder_password')}
                prefix={<Icon icon={key} />}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row align="middle" justify="start">
          <Col xs={{ span: '24', order: '2' }} md={{ span: '18', order: '1' }}>
            <Divider
              style={{ paddingBottom: '22px' }}
              className="dashed-line"
              dashed
            />
          </Col>
          <Col xs={{ order: '1', span: '24' }} md={{ order: '2', span: '6' }}>
            <Button className="btn--auth btn--auth-primary ith_bookButton" htmlType="submit">
              {t('label_signIn')}
            </Button>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div
              className="forgot-password-button"
              onClick={() => handleStepChange(EMBED_STEPS.FORGOT_PASSWORD)}
            >
              {t('label_forgotPassword')}
            </div>
          </Col>
        </Row>
      </Form>
      <div className="ith_auth-wrapper-field">
        <Typography.Text className="title">
          {t('label_createAccount')}
        </Typography.Text>
        <Form
          ref={formRefSignUp}
          name="signup-form"
          layout="vertical"
          onFinish={(formData) => handleSignUpSubmitStep1(formData)}
        >
          <Row justify="space-between">
            <Col xs={24} md={11}>
              <Form.Item
                label={t('label_firstName')}
                name="firstName"
                rules={[
                  {
                    required: true,
                    message: t('validator.required'),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t('placeholder_firstName')}
                  prefix={<Icon icon={pencil} />}
                />
              </Form.Item>
            </Col>
            <Col xs={24} md={11}>
              <Form.Item
                label={t('label_lastName')}
                name="lastName"
                rules={[
                  {
                    required: true,
                    message: t('validator.required'),
                  },
                ]}
              >
                <Input
                  size="large"
                  placeholder={t('placeholder_lastName')}
                  prefix={<Icon icon={pencil} />}
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
            label={t('label_email')}
            name="email"
            rules={[
              {
                required: true,
                message: t('validator.required'),
              },
              {
                type: 'email',
                message: t('validator.email'),
              },
            ]}
          >
            <Input
              size="large"
              placeholder={t('placeholder_email')}
              prefix={<Icon icon={envelopeO} />}
            />
          </Form.Item>
          <Row justify="start" align="middle" gutter={[0, 12]}>
            <Col xs={24} md={18}>
              <ith-div class="auth-field-phone-wrapper">
                {/* <Space size={'small'} direction={'horizontal'}> */}
                <Row align="middle" gutter={15}>
                  <Col flex="auto">
                    <Form.Item label={t('label_secPhone')} name="phone">
                      <IntlTelInput
                        fieldId="signup-form_phone"
                        containerClassName="ant-input-affix-wrapper intl-tel-input"
                        nationalMode={false}
                        separateDialCode
                        preferredCountries={['ch', 'de', 'li', 'it', 'fr']}
                        inputClassName="ant-input input--phone"
                        autoHideDialCode={false}
                        onPhoneNumberChange={debouncedOnPhoneNumberChange}
                        onPhoneNumberBlur={() => setIsPhoneInputTouched(true)}
                        format
                        formatOnInit={false}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex="40px">
                    {isPhoneInputTouched ? (
                      <ith-div
                        class="phone-input-valid-icon"
                        style={
                          isValidPhone
                            ? { color: '#4ece3d' }
                            : { color: '#d0021b' }
                        }
                      >
                        <Icon
                          icon={isValidPhone ? check : warning}
                          size="30px"
                        />
                      </ith-div>
                    ) : null}
                  </Col>
                  {/* </Space> */}
                </Row>
              </ith-div>
            </Col>
            <Col xs={24} md={6}>
              <Button className="btn--auth btn--auth-primary ith_bookButton" htmlType="submit">
                {t('label_next')}
              </Button>
            </Col>
          </Row>
        </Form>
        <style>{styles}</style>
      </div>
    </ith-div>
  );
}

export default EmbedSignInSignUp;
