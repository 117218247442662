/* eslint-disable jsx-a11y/anchor-is-valid */

/* eslint-disable jsx-a11y/label-has-for */
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import PropTypes from 'prop-types';
import React from 'react';

import styles from './styles';

function ModalError({
  t, status, toggleState, errorMessage,
}) {
  return (
    <div>
      <Dialog
        open={status}
        onClose={() => toggleState('error')}
        aria-labelledby="form-dialog-title"
      >
        <DialogContent>
          <ith-div class="ith_verification_container">
            <ith-h2 class="ith_form_input_title">{errorMessage}</ith-h2>
            <ith-button
              className="ith_verification_form_submit"
              value={t('label_okay')}
              onClick={() => toggleState('error')}
            >
              {t('label_okay')}
            </ith-button>
          </ith-div>
        </DialogContent>
      </Dialog>
      <style>{styles}</style>
    </div>
  );
}

ModalError.propTypes = {
  t: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
  toggleState: PropTypes.func.isRequired,
};

export default ModalError;
