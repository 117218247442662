import { overwriteToken } from '../../utils/api';
import { commonClient, publicCommonClient, publicDirectoryClient } from '../../../../interfaces/clients';

export const signUp = async (signUpData) => {
  const { data: tokenPair } = await publicCommonClient.post(
    '/api/auth/signup',
    signUpData,
  );

  return tokenPair;
};

export const specialSignUp = async (signUpData) => {
  const { data: tokenPair } = await publicCommonClient.post(
    '/api/auth/specialSignup',
    signUpData,
  );

  return tokenPair;
};

export const signIn = async (signInData) => {
  const { data: tokenPair } = await publicCommonClient.post(
    '/api/auth/signin',
    signInData,
  );

  return tokenPair;
};

export const sendActivationCode = async (userId) => {
  const { data } = await publicCommonClient.post('/api/auth/sendActivationCode', {
    userId,
  });

  return data.success;
};

export const activateWithCode = async (data) => {
  const { data: tokenPair } = await publicCommonClient.post(
    '/api/auth/activateWithCode',
    data,
  );

  return tokenPair;
};

export const sendResetPasswordLink = async (email) => publicCommonClient.post('/api/auth/sendReset', {
  email,
  isProvider: false,
});

export const resetPassword = async (password, token) => {
  overwriteToken(token);

  return commonClient
    .post('/api/auth/resetPassword', { password })
    .then((response) => {
      localStorage.clear();
      return response.data;
    });
};

export const fetchMe = async () => {
  const { data: response } = await commonClient.get('/api/auth/getUserFromToken');

  return response.user;
};

export const checkSignUpKey = async (key) => {
  const { data: user } = await publicCommonClient.get(
    `/api/auth/specialSignup?key=${key}`,
  );

  return user;
};

export const listing = async (
  providerType,
  serviceType,
  query,
  location,
  filterParams,
) => publicDirectoryClient.get(
  `/listing/${providerType}/${serviceType}/${query}`,
  {
    params: {
      location,
      filterParams,
    },
  },
);

export const getGooglePlaceDetailed = async (placeId) => {
  const { data: centre } = await publicDirectoryClient.get(
    `/listing/place/${placeId}`,
  );

  return centre;
};

export const changePhone = async (userId, data) => {
  const { data: user } = await publicCommonClient.patch(
    `/api/auth/users/${userId}/changePhone`,
    data,
  );

  return user;
};

export const getContactFromHash = async (hash) => {
  const { data: contact } = await publicCommonClient.get(
    `/api/contacts/hash/${hash}`,
  );
  return contact;
};

export default {
  signUp,
  specialSignUp,
  signIn,
  sendActivationCode,
  activateWithCode,
  sendResetPasswordLink,
  resetPassword,
  fetchMe,
  checkSignUpKey,
  listing,
  getGooglePlaceDetailed,
  changePhone,
  getContactFromHash,
};
