/* eslint-disable no-underscore-dangle */

// eslint-disable-next-line no-redeclare
/* global window, module */
// eslint-disable-next-line import/no-import-module-exports
import Immutable from 'immutable';
// eslint-disable-next-line import/no-import-module-exports
import { applyMiddleware, compose, createStore } from 'redux';
// eslint-disable-next-line import/no-import-module-exports
import { createLogger } from 'redux-logger';
// eslint-disable-next-line import/no-import-module-exports
import thunk from 'redux-thunk';

// eslint-disable-next-line import/no-import-module-exports
import env from '../../../config/env';

function createMiddlewares() {
  // eslint-disable-line no-unused-vars
  const middlewares = [thunk];

  if (env.server.stage === 'development' && typeof window !== 'undefined') {
    middlewares.push(
      createLogger({
        level: 'info',
        collapsed: true,
        stateTransformer: (state) => {
          const newState = {};

          // eslint-disable-next-line no-restricted-syntax
          for (const i of Object.keys(state)) {
            // eslint-disable-line no-restricted-syntax
            if (Immutable.Iterable.isIterable(state[i])) {
              newState[i] = state[i].toJS();
            } else {
              newState[i] = state[i];
            }
          }

          return newState;
        },
      }),
    );
  }

  return middlewares;
}

function immutableChildren(obj) {
  const state = {};
  Object.keys(obj).map((key) => {
    state[key] = Immutable.fromJS(obj[key]);
    return undefined;
  });
  return state;
}

const __NEXT_REDUX_STORE__ = '__NEXT_REDUX_STORE__';

const initStore = (reducer, initialState, isServer) => {
  const middlewares = createMiddlewares({ isServer });
  const state = immutableChildren(initialState);

  if (isServer && typeof window === 'undefined') {
    return createStore(
      reducer,
      state,
      compose(applyMiddleware(...middlewares)),
    );
  }

  if (!window[__NEXT_REDUX_STORE__]) {
    if (window.__REDUX_DEVTOOLS_EXTENSION__) {
      window[__NEXT_REDUX_STORE__] = createStore(
        reducer,
        state,
        compose(
          applyMiddleware(...middlewares),
          window.__REDUX_DEVTOOLS_EXTENSION__
            && window.__REDUX_DEVTOOLS_EXTENSION__(),
        ),
      );
    } else {
      window[__NEXT_REDUX_STORE__] = createStore(
        reducer,
        state,
        applyMiddleware(...middlewares),
      );
    }
  }

  if (module.hot) {
    module.hot.accept('./reducers', () => window[__NEXT_REDUX_STORE__].replaceReducer(
      // eslint-disable-next-line global-require
      require('./reducers').default,
    )); // eslint-disable-line global-require
  }

  return window[__NEXT_REDUX_STORE__];
};

export default initStore;
