/* eslint-disable no-console */

/* eslint-disable class-methods-use-this */

/* eslint-disable no-unused-vars */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Input } from 'antd';
import React, { PureComponent } from 'react';

import PageFooter from '../PageFooter/PageFooter';
import PageHeader from '../PageHeader/PageHeader';
import './styles.css';

const { Search } = Input;

class PageLayout extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { children } = this.props;

    return (
      <>
        {!this.props.headerOff && <PageHeader />}
        {children}
        {!this.props.footerOff && <PageFooter />}
      </>
    );
  }
}

export default PageLayout;
