const antStyles = {
  cardStyles: {
    backgroundColor: '#FFFFFF',
    borderRadius: '6px',
    boxShadow: '0 3px 4px 0 rgba(153, 155, 168, 0.15)',
    width: '263px',
    height: '420px',
    maxWidth: '100%',
  },
  twoCardStyles: {
    backgroundColor: '#FFFFFF',
    borderRadius: '6px',
    boxShadow: '0 3px 4px 0 rgba(153, 155, 168, 0.15)',
    width: '515px',
    height: '550px',
    maxWidth: '100%',
  },
};

export default antStyles;
