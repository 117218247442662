import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import {
  EmptyStarIcon,
} from '../../constants/icons';
import SVGIcon from '../SVGIcon';
import styles from './styles';

class StarRating extends PureComponent {
  // eslint-disable-next-line class-methods-use-this
  getStars() {
    return _.fill(Array(5), EmptyStarIcon);
  }

  render() {
    const {
      averageRating, starColor,
    } = this.props;

    return (
      <ith-div id="star_rating" class="star_rating">
        <ith-div class="rating_wrapper">
          <ith-div class="star_group" style={{ color: starColor }}>
            {this.getStars(averageRating).map((starIcon) => (
              <SVGIcon icon={starIcon} />
            ))}
          </ith-div>
        </ith-div>

        {/* <div className="total_reviews" style={{ color: this.props.fontColor }}>
              ({this.state.reviewList.count})
            </div> */}

        {/* {this.state.reviewList.count === 0 &&
          <div className="no_reviews" style={{ color: this.props.fontColor }}>
            {this.props.t('no_reviews_found')}
          </div>
        } */}
        <style>{styles}</style>
      </ith-div>
    );
  }
}

StarRating.defaultProps = {
  starColor: '#f48a82',
};

StarRating.propTypes = {
  averageRating: PropTypes.number.isRequired,
  starColor: PropTypes.string,
};

export default StarRating;
