import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import SVGIcon from '../../components/SVGIcon';
import StarRating from '../../components/StarRating';
import { MapMarkerIcon } from '../../constants/icons';
import styles from './styles';

class ProfileHeader extends PureComponent {
  // eslint-disable-next-line class-methods-use-this
  centreAddress(centre) {
    const {
      street = '',
      building = '',
      zipcode = '',
      city = '',
    } = centre.Address;
    const comma = street || building ? ',' : '';

    // TODO link to google map
    return `${street} ${building}${comma} ${zipcode} ${city}`;
  }

  render() {
    const { centre } = this.props;

    return (
      <ith-div class="ith_profile_header_wrapper">
        <ith-div class="ith_profile_header">
          <ith-div class="ith_profile_heading">{centre.name}</ith-div>
          {centre.subtitle && (
            <ith-div class="ith_profile_subheading">{centre.subtitle}</ith-div>
          )}
        </ith-div>
        <ith-div class="ith_profile_info">
          <ith-div class="ith_profile_info_location">
            <ith-div class="ith_icon_wrapper">
              <SVGIcon icon={MapMarkerIcon} />
            </ith-div>
            <ith-div class="ith_location_label">
              {this.centreAddress(centre)}
            </ith-div>
          </ith-div>
          <ith-div class="ith_profile_info_rating">
            <StarRating
              t={this.props.t}
              averageRating={this.props.centre.averageRating}
              serviceProvider={
                  /* eslint-disable-next-line no-underscore-dangle */
                this.props.centre._id
              }
              fontColor="#8b572a"
            />
          </ith-div>
        </ith-div>
        <style>{styles}</style>
      </ith-div>
    );
  }
}

const ProviderProfileProp = PropTypes.shape({
  name: PropTypes.shape({
    first: PropTypes.string.isRequired,
    last: PropTypes.string,
  }).isRequired,
  picture: PropTypes.string.isRequired,
  slug: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  qualifications: PropTypes.array.isRequired,
  specialisation: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ).isRequired,
  averageRating: PropTypes.number,
  centres: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      address: PropTypes.arrayOf(
        PropTypes.shape({
          street: PropTypes.string,
          building: PropTypes.string,
          zipcode: PropTypes.string.isRequired,
          city: PropTypes.string.isRequired,
          state: PropTypes.string.isRequired,
          country: PropTypes.string.isRequired,
          additionalAddress: PropTypes.string.isRequired,
          locality: PropTypes.string.isRequired,
          landmark: PropTypes.string.isRequired,
          // eslint-disable-next-line react/forbid-prop-types
          location: PropTypes.array.isRequired,
        }),
      ).isRequired,
      openingHours: PropTypes.arrayOf(
        PropTypes.shape({
          openTime: PropTypes.number.isRequired,
          closeTime: PropTypes.number.isRequired,
          day: PropTypes.number.isRequired,
        }),
      ).isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ).isRequired,
  serviceProvider: PropTypes.string.isRequired,
});

ProfileHeader.defaultProps = {
  centre: ProviderProfileProp,
};

ProfileHeader.propTypes = {
  t: PropTypes.func.isRequired,
  centre: ProviderProfileProp,
};

export default ProfileHeader;
