export default `
  .star_rating {
    display: flex;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .rating_wrapper {
    display: none;
    height: 100%;
    width: 100%;
    box-sizing: border-box;
  }

  .star_group {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    font-size: 14px;
    color: #f48a82;
  }

  .total_reviews {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    margin-left: 10px;
    font-family: Roboto;
    font-size: 12px;
    font-weight: 300;
    color: #999999;
  }

  .no_reviews {
    display: flex;
    height: 100%;
    box-sizing: border-box;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 300;
    color: #999999;
  }

  @media (min-width: 480px) {
    .total_reviews {
      font-size: 13px;
    }

    .no_reviews {
      font-size: 13px;
    }

    .star_group {
      font-size: 16px;
    }
  }
  @media (min-width: 600px) {
    .total_reviews {
      font-size: 14px;
    }

    .no_reviews {
      font-size: 14px;
    }

    .star_group {
      font-size: 17px;
    }
  }
  @media (min-width: 801px) {
    .total_reviews {
      font-size: 15px;
    }

    .no_reviews {
      font-size: 15px;
    }

    .star_group {
      font-size: 18px;
    }
  }
  @media (min-width: 1025px) {
    .total_reviews {
      font-size: 16px;
    }

    .no_reviews {
      font-size: 16px;
    }

    .star_group {
      font-size: 20px;
    }
  }
  @media (min-width: 1281px) {
    .total_reviews {
      font-size: 17px;
    }

    .no_reviews {
      font-size: 17px;
    }

    .star_group {
      font-size: 22px;
    }
  }
`;
