export default `
  .ith_auth-wrapper-field .btn--auth {
    border-radius: 25px !important;
    width: 100%;
    height: 40px;
    opacity: 1;
    color: #fff !important;
    font-weight: 500;
  }

  .ith_auth-wrapper-field .auth-field-phone-wrapper .ant-space {
    width: 100%;
  }

  .ith_auth-wrapper-field
    .auth-field-phone-wrapper
    .ant-space-item:first-child {
    width: 100%;
  }

  .ith_auth-wrapper-field .auth-field-phone-wrapper .ant-space-item:last-child {
    width: 30%;
    margin-top: 5px;
    margin-left: 20px;
  }

  .ith_auth-wrapper-field .ant-form-item-label > label {
    font-weight: 400;
  }

  .ith_auth-wrapper-field .auth-field-phone-wrapper .intl-tel-input {
    height: 40px;
  }

  .ith_auth-wrapper-field
    .auth-field-phone-wrapper
    .intl-tel-input
    .selected-flag {
    background: transparent;
  }

  .ith_auth-wrapper-field
    .auth-field-phone-wrapper
    .intl-tel-input
    .country-list {
    bottom: 40px;
  }

  .ith_auth-wrapper-field .auth-field-phone-wrapper .phone-input-valid-icon {
    display: flex;
    margin-top: 10px;
    margin-right: 25px;
  }

  .ith_auth-wrapper-field .icon--password-invalid {
    color: #f4a261;
  }

  .ith_auth-wrapper-field .icon--password-valid {
    color: #00ce30;
  }

  .dashed-line {
    border-color: #000;
  }

  .embed-success-wrapper .embed-success-text {
    font-size: 24px;
    font-weight: 500;
  }

  .embed-success-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
  }

  .embed-success-wrapper .embed-success-info {
    font-size: 18px;
    font-weight: 500;
    color: #81949c;
    margin-top: 35px;
    margin-bottom: 35px;
  }

  .forgot-password-button {
    cursor: pointer;
    text-align: end;
    color: #2a99cc;
  }

  .login-label {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
  }

  .title {
    display: flex;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 10px;
  }

  .forgot-password-text {
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .requestAgainButton {
    text-decoration: underline;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    .forgot-password-button {
      text-align: center;
      margin-top: 10px;
    }
  }
`;
