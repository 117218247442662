import { bookingClient, commonClient } from '../clients';

const dashboardInterface = {
  getAppointmentList: async (userId) => {
    const { data: appointmentList } = await bookingClient.get(
      `/appointments/consumer/${userId}`,
    );
    console.log('appointmentList', appointmentList);
    return appointmentList;
  },
  getInvoiceList: async (userId) => {
    const { data: invoiceList } = await commonClient.get(
      `/api/invoices/consumer/${userId}`,
    );
    console.log('invoiceList', invoiceList);
    return invoiceList;
  },
  getInvoice: async (invoiceId) => {
    const { data: invoice } = await commonClient.get(
      `/api/invoices/${invoiceId}`,
    );

    return invoice;
  },
  getInvoiceAsZIP: async (invoiceIds) => {
    const { data: invoiceZIP } = await commonClient.post(
      '/api/invoices/getInvoicesAsZIP',
      invoiceIds,
    );

    return invoiceZIP;
  },
  markInvoiceAsViewed: async (invoiceId) => {
    const { data: invoice } = await commonClient.post('/api/viewed-invoices', {
      invoiceId,
    });

    return invoice;
  },
  getUserData: async (userId) => {
    const { data: user } = await commonClient.get(`/api/user/${userId}`);

    return user;
  },
  updateUserData: async (userId, data) => {
    const { data: user } = await commonClient.post(`/api/user/${userId}`, data);

    return user;
  },
};

export default dashboardInterface;
