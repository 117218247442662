import React from 'react';
import SwipeableViews from 'react-swipeable-views';

import { EMBED_STEPS } from '../../constants/authModalTypes';
import EmbedConfirmation from '../EmbedSteps/EmbedConfirmation';
import EmbedForgotPassword from '../EmbedSteps/EmbedForgotPassword';
import EmbedPassword from '../EmbedSteps/EmbedPassword';
import EmbedSignInSignUp from '../EmbedSteps/EmbedSignInSignUp';
import EmbedSuccess from '../EmbedSteps/EmbedSuccess';
import EmbedVerification from '../EmbedSteps/EmbedVerification';
import styles from './styles';

// eslint-disable-next-line consistent-return
const getWrapperStyles = (activeStep) => {
  // eslint-disable-next-line default-case
  switch (activeStep) {
    case EMBED_STEPS.SIGN_UP_SIGN_IN:
      return {
        paddingTop: '4px',
      };

    case EMBED_STEPS.CONFIRMATION:
      return {
        background: 'transparent',
        border: 'none',
      };
  }
};

function EmbedAuthStepper(props) {
  const {
    t,

    onSignInSubmit,
    onSignUpSubmitStep1,
    onSignUpSubmitStep2,
    onVerificationSubmit,
    onSendCodeSubmit,
    errorHandler,

    activeStep,
    handleNext,
    handleBack,
    handleStepChange,
    centre,
    provider,
    services,
    startTime,
    endTime,
    bufferTime,
    date,
    userData,
  } = props;

  return (
    <div
      className="ith_embed-auth-stepper"
      id="ith_embed-auth-stepper"
      style={getWrapperStyles(activeStep)}
    >
      <SwipeableViews
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents={false}
      >
        {activeStep === 0 ? (
          <EmbedSignInSignUp
            t={t}
            handleStepChange={handleStepChange}
            handleBack={handleBack}
            handleNext={handleNext}
            onSignInSubmit={onSignInSubmit}
            onSignUpSubmitStep1={onSignUpSubmitStep1}
          />
        ) : (
          ''
        )}
        {activeStep === 1 ? (
          <EmbedPassword
            t={t}
            handleBack={handleBack}
            handleNext={handleNext}
            handleStepChange={handleStepChange}
            onSignUpSubmitStep2={onSignUpSubmitStep2}
          />
        ) : (
          ''
        )}
        {activeStep === 2 ? (
          <EmbedForgotPassword
            t={t}
            handleStepChange={handleStepChange}
            errorHandler={errorHandler}
          />
        ) : (
          ''
        )}
        {activeStep === 3 ? (
          <EmbedVerification
            t={t}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
            onVerificationSubmit={onVerificationSubmit}
            onSendCodeSubmit={onSendCodeSubmit}
          />
        ) : (
          ''
        )}
        {activeStep === 4 ? (
          <EmbedConfirmation
            t={t}
            centre={centre}
            provider={provider}
            services={services}
            startTime={startTime}
            endTime={endTime}
            bufferTime={bufferTime}
            date={date}
            userData={userData}
            handleStepChange={handleStepChange}
            errorHandler={errorHandler}
          />
        ) : (
          ''
        )}
        {activeStep === 5 ? <EmbedSuccess t={t} /> : ''}
      </SwipeableViews>

      <style>{styles}</style>
    </div>
  );
}

export default EmbedAuthStepper;
