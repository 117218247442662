import {
  publicBookingClient,
  publicCommonClient,
  publicDirectoryClient,
} from '../../../interfaces/clients';

// todo check or complet
// replace everywhere old apis
// export const getClinic = async (centreId, params) => {
//   const { data: clinic } = await publicDirectoryClient.get(`/centres/${centreId}`, { params });

//   return clinic;
// };

const getCentre = async (centreId) => publicDirectoryClient.get(`/centre/${centreId}/getCentre`);

const getCentreServices = async (centreId) => publicDirectoryClient.get(
  `/service/centre/${centreId}/getCentreServices`,
);

export const getBookingClinic = async (centreId) => {
  const { data: clinic } = await publicBookingClient.get(
    `/centres/${centreId}`,
  );

  return clinic;
};

export const getAllClinicVariants = async (centreId) => {
  const { data: variants } = await publicDirectoryClient.get(
    `/services/centre/${centreId}/variant`,
  );

  return variants;
};

export const getAllClinicServices = async (centreId) => {
  const { data: services } = await publicDirectoryClient.get(
    `/centres/${centreId}/services`,
  );

  return services;
};

export const getClinicProviders = async (providerIds) => {
  const { data: response } = await publicCommonClient.post('/private/users', {
    userIds: providerIds,
  });

  return response.result;
};

export default {
  // getClinic,
  getCentre,
  getBookingClinic,
  getAllClinicVariants,
  // getAllClinicServices,
  getCentreServices,
  getClinicProviders,
};
