/* eslint-disable import/no-cycle */
import axios from 'axios';

import env from '../../config/env';
import { requestInterceptor, responseInterceptor } from './interceptors';

export const publicCommonClient = axios.create({
  baseURL: env.api.commonService,
});
export const publicDirectoryClient = axios.create({
  baseURL: env.api.directoryEngine,
});
export const publicBookingClient = axios.create({
  baseURL: env.api.bookingEngine,
});

export const commonClient = axios.create({ baseURL: env.api.commonService });
export const directoryClient = axios.create({
  baseURL: env.api.directoryEngine,
});
export const bookingClient = axios.create({ baseURL: env.api.bookingEngine });

commonClient.interceptors.request.use(requestInterceptor);
commonClient.interceptors.response.use(
  undefined,
  responseInterceptor(commonClient),
);
directoryClient.interceptors.request.use(requestInterceptor);
directoryClient.interceptors.response.use(
  undefined,
  responseInterceptor(directoryClient),
);
bookingClient.interceptors.request.use(requestInterceptor);
bookingClient.interceptors.response.use(
  undefined,
  responseInterceptor(bookingClient),
);

export default {
  publicCommonClient,
  publicDirectoryClient,
  publicBookingClient,
  commonClient,
  directoryClient,
  bookingClient,
};
