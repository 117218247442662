import { Col, Row, Typography } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import React from 'react';
import { Icon } from 'react-icons-kit';
import { pencil } from 'react-icons-kit/fa';

import {
  DATE_FORMAT,
  TIME_FORMAT,
} from '../../constants/dateConstants';
import styles from './styles';
import env from '../../../../config/env';

const getProviderAddress = (address) => {
  if (address) {
    const street = get(address, 'street', '');
    const building = get(address, 'building', '');
    const zipCode = get(address, 'zipcode', '');
    const city = get(address, 'city', '');
    const comma = street || building ? ',' : '';

    return `${street} ${building}${comma} ${zipCode} ${city}`;
  }
  return '';
};

function EmbedBookingSummary({
  t,
  centre,
  showBookingCalendar,
  provider,
  services,
  startTime,
  endTime,
  date,
}) {
  return (
    <>
      <div className="ith_embed-booking-summary">
        <div className="ith_embed-booking-summary-header">
          <div>{t('L.BookingSummary')}</div>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
          <div
            className="ith_embed-booking-summary-header-button"
            onClick={showBookingCalendar}
          >
            <Icon icon={pencil} />
          </div>
        </div>
        <div className="ith_embed-booking-summary-main">
          <Row gutter={[0, 8]}>
            <Col span={22} offset={1}>
              <img
                src={`${env.api.consumerApps}/imgs/embed/provider.png`}
                alt=""
              />
              <Typography.Text
                style={{ fontSize: '16px', fontWeight: 500 }}
              >
                {`${provider.User.firstName} ${provider.User.lastName}`}
              </Typography.Text>
            </Col>
            <Col span={22} offset={1}>
              <img
                src={`${env.api.consumerApps}/imgs/embed/location.png`}
                alt=""
              />
              <Typography.Text
                style={{ fontSize: '16px', fontWeight: 500 }}
              >
                {`${centre.name} ${getProviderAddress(provider.address)}`}
              </Typography.Text>
            </Col>
            <Col span={22} offset={1}>
              <img
                src={`${env.api.consumerApps}/imgs/embed/service.png`}
                alt=""
              />
              <Typography.Text
                style={{ fontSize: '16px', fontWeight: 500 }}
              >
                {`${get(services, '[0].name', '')}, ${get(services, '[0].total', '')}`}
              </Typography.Text>
            </Col>
            <Col span={22} offset={1}>
              <img
                src={`${env.api.consumerApps}/imgs/embed/datetime.png`}
                alt=""
              />
              <Typography.Text style={{ fontSize: '16px', fontWeight: 500 }}>
                {moment(
                  `${date} ${startTime}`,
                  `${DATE_FORMAT} ${TIME_FORMAT}`,
                ).format('DD.MM.YYYY - HH:mm')}
                {' '}
                -
                {' '}
                {endTime}
              </Typography.Text>
            </Col>
          </Row>
        </div>
      </div>

      <style>{styles}</style>
    </>
  );
}

export default EmbedBookingSummary;
