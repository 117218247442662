import React from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

import colors from '../../constants/colors';
import styles from './styles';

function EmbedLoader({ isLoading }) {
  return (
    isLoading && (
      <div className="loader-wrapper">
        <div>
          <Loader
            type="TailSpin"
            color={colors.indicatorColor}
            width={80}
            length={80}
          />
        </div>
        <style>{styles}</style>
      </div>
    )
  );
}

export default EmbedLoader;
