import { configureStore } from '@reduxjs/toolkit';

import auth from '../slices/auth';
import booking from '../slices/booking';
import dashboard from '../slices/dashboard';
import modals from '../slices/modals';
import search from '../slices/search';

export default configureStore({
  reducer: {
    auth,
    search,
    modals,
    booking,
    dashboard,
  },
});
