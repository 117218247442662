import React, { Component } from 'react';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import store from '../redux/store/store';
import i18n from '../utils/i18n';

const decorator = (ComposedComponent) => {
  class PageComponent extends Component {
    render() {
      return (
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <ComposedComponent />
          </I18nextProvider>
        </Provider>
      );
    }
  }

  return PageComponent;
};

export default decorator;
