/* eslint-disable class-methods-use-this */

/* eslint-disable no-unused-expressions */
// import { Link } from 'react-router-dom';
import {
  Col, List, Row, Table, Tag,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { PureComponent } from 'react';

import StoreConnector from '../../hoc/StoreConnector';
import {
  getAppointmentListThunk,
  resetAppointmentListThunk,
} from '../../redux/thunks/dashboard';
import stateUtils from '../../utils/state';
import './styles.css';

class AppointmentList extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      appointmentList: [],
    };
  }

  componentDidMount() {
    this.reflectProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.reflectProps(nextProps);
  }

  componentWillUnmount() {
    this.props.dispatch(resetAppointmentListThunk());
  }

  getState(appointment) {
    let color;
    let tagKind = appointment.status;

    if (appointment.status === 'CONFIRMED') {
      color = 'green';
      tagKind = 'Bestätigt';
      // would be nice if it had its own status
      if (this.isDateBefore(appointment)) {
        color = 'blue';
        tagKind = 'Vorüber';
      }
    }
    if (appointment.status === 'CANCELLED') {
      color = 'red';
      tagKind = 'Storniert';
    }
    if (appointment.status === 'UNCONFIRMED') {
      color = 'yellow';
      tagKind = 'Unbestätigt';
    }
    return (
      <Tag color={color} key={tagKind}>
        {tagKind}
      </Tag>
    );
  }

  getDate(appointment) {
    const appointmentRef = [];
    appointmentRef.push(moment(appointment.date).format('DD.MM.YYYY'));
    // if (window.innerWidth <= 576) {
    //   appointmentRef.push(`${appointment.startTime} - ${appointment.endTime}`);
    // }
    // if (window.innerWidth <= 992) {
    //   appointmentRef.push(`${appointment.CentreProvider.User.firstName} ${appointment.CentreProvider.User.lastName}`);
    // }
    // if (window.innerWidth <= 992) {
    //   appointment.serviceName && appointmentRef.push(appointment.serviceName.split(',')[0]);
    // }
    // if (window.innerWidth <= 1200) {
    //   appointment.price && appointmentRef.push(`CHF ${appointment.price}`);
    // }
    // if (window.innerWidth <= 576) {
    //   appointmentRef.push(this.getState(appointment));
    // }
    return (
      <List
        split={false}
        size="small"
        dataSource={appointmentRef}
        renderItem={(item) => <List.Item>{item}</List.Item>}
      />
    );
  }

  isDateBefore(appointment) {
    const today = moment();
    const day = _.split(appointment.date, '-');
    const time = _.split(appointment.startTime, ':');
    const appointmentDate = moment().set({
      year: day[0],
      month: day[1] - 1,
      date: day[2],
      hour: time[0],
      minute: time[1],
      second: 0,
    });

    return moment(appointmentDate).isBefore(today);
  }

  reflectProps(props) {
    if (
      !props.isFetchingAppointmentList
      && !props.isValidAppointmentList
      && !props.isFailedFetchingAppointmentList
    ) {
      props.dispatch(getAppointmentListThunk(props.user.user_id));
    }
    if (props.isValidAppointmentList) {
      this.setState({ appointmentList: this.props.appointmentList }, () => this.setState({ appointmentList: this.props.appointmentList }));
    }
  }

  render() {
    const columns = [
      {
        title: 'Datum',
        key: 'date',
        defaultSortOrder: 'ascend',
        render: (appointment) => this.getDate(appointment),
        sorter: (a, b) => moment(b.date).diff(a.date),
      },
      {
        title: 'Zeit',
        responsive: ['sm'],
        render: (appointment) => `${appointment.startTime} - ${appointment.endTime}`,
      },
      {
        title: 'Therapeut',
        responsive: ['lg'],
        render: (appointment) => `${appointment.CentreProvider.User.firstName} ${appointment.CentreProvider.User.lastName}`,
        key: 'provider',
      },
      {
        title: 'Typ',
        responsive: ['lg'],
        dataIndex: 'serviceName',
        key: 'serviceName',
        render: (appointment) => (appointment ? appointment.split(',')[0] : ''),
      },
      {
        title: 'Preis',
        responsive: ['xl'],
        dataIndex: 'price',
        key: 'price',
        render: (price) => (price ? `${parseInt(price, 10)} CHF` : ''),
      },
      {
        title: 'Status',
        responsive: ['sm'],
        key: 'status',
        filters: [
          {
            text: 'Bestätigt',
            value: 'CONFIRMED',
          },
          {
            text: 'Storniert',
            value: 'CANCELLED',
          },
          {
            text: 'Unbestätigt',
            value: 'UNCONFIRMED',
          },
        ],
        onFilter: (value, record) => {
          if (value === 'CONFIRMED') {
            return record.status === 'CONFIRMED';
          }
          if (value === 'CANCELLED') {
            return record.status === 'CANCELLED';
          }
          if (value === 'UNCONFIRMED') {
            return record.status === 'UNCONFIRMED';
          }
          return record.status === 'CONFIRMED';
        },
        sortDirections: ['descend'],
        render: (appointment) => this.getState(appointment),
      },
    ];

    return (
      <div className="appointmentList">
        <Row align="top" justify="space-between" style={{ height: '100%' }}>
          <Col span={24}>
            <Table
              columns={columns}
              showSorterTooltip={false}
              dataSource={this.state.appointmentList}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default StoreConnector(AppointmentList, stateUtils.fullStateMap, {});
