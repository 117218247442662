import * as moment from 'moment';

import { DATE_FORMAT } from '../constants/dateConstants';

const MS_PER_DAY = 24 * 60 * 60 * 1000;
const MAX_MONTH_NUMBER = 12;
const MIN_MONTH_NUMBER = 1;
const MAX_DAY_NUMBER = 6;
const BASE_UNIT = 5;
export const MS_PER_MINUTE = 60 * 1000;

const TIME_FORMAT_VIEW_12 = 'hh:mm A';
const TIME_FORMAT_VIEW_24 = 'HH:mm';
const HOUR_CYCLE_12 = 'h12';
const HOUR_CYCLE_23 = 'h23';

class DateTime {
  static getBeginOfWeek(date) {
    return new Date(date.getTime() - date.getDay() * MS_PER_DAY);
  }

  static getNextMonthBegin(date) {
    const nextMonthBegin = DateTime.getBeginOfMonth(new Date(date));

    if (nextMonthBegin.getMonth() === MAX_MONTH_NUMBER) {
      nextMonthBegin.setMonth(0);
      nextMonthBegin.setFullYear(nextMonthBegin.getFullYear() + 1);
    } else {
      nextMonthBegin.setMonth(nextMonthBegin.getMonth() + 1);
    }

    return nextMonthBegin;
  }

  static getPrevMonthBegin(date) {
    const prevMonthBegin = DateTime.getBeginOfMonth(new Date(date));

    if (prevMonthBegin.getMonth() === MIN_MONTH_NUMBER) {
      prevMonthBegin.setMonth(MAX_MONTH_NUMBER);
      prevMonthBegin.setFullYear(prevMonthBegin.getFullYear() - 1);
    } else {
      prevMonthBegin.setMonth(prevMonthBegin.getMonth() - 1);
    }

    return prevMonthBegin;
  }

  static getMonthViewTail(date) {
    const nextMonthBegin = DateTime.getNextMonthBegin(date);

    if (nextMonthBegin.getDate() === 0) {
      return new Date(nextMonthBegin.getTime() - MS_PER_DAY);
    }
    return new Date(
      nextMonthBegin.getTime()
          + MS_PER_DAY * (MAX_DAY_NUMBER - nextMonthBegin.getDay()),
    );
  }

  static addDay(date) {
    return new Date(date.getTime() + MS_PER_DAY);
  }

  static getBeginOfDate(date) {
    const tempDate = new Date(date);

    tempDate.setHours(0);
    tempDate.setMinutes(0);
    tempDate.setSeconds(0);
    tempDate.setMilliseconds(0);

    return tempDate;
  }

  static getBeginOfMonth(date) {
    const tempDate = DateTime.getBeginOfDate(date);

    tempDate.setDate(1);

    return tempDate;
  }

  static diffYear(dateold, datenew) {
    const ynew = datenew.getFullYear();
    const mnew = datenew.getMonth();
    const dnew = datenew.getDate();
    const yold = dateold.getFullYear();
    const mold = dateold.getMonth();
    const dold = dateold.getDate();
    let diff = ynew - yold;

    if (mold > mnew) {
      // eslint-disable-next-line no-plusplus
      diff--;
    } else if (mold === mnew) {
      // eslint-disable-next-line no-plusplus
      if (dold > dnew) diff--;
    }
    // eslint-disable-next-line no-restricted-globals
    return isNaN(diff) ? '' : `${diff}`;
  }

  static localDateAsISO(date) {
    return `${moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
  }

  static startOfDate(date) {
    return new Date(moment(date).startOf('date'));
  }

  static endOfDate(date) {
    return new Date(moment(date).endOf('date'));
  }

  static localDateToISOStr(date) {
    return `${moment(date).format('YYYY-MM-DDTHH:mm:ss.SSS')}Z`;
  }

  static localDateFromISOStr(strKey) {
    return new Date(
      strKey.slice(0, 4),
      +strKey.slice(5, 7) - 1,
      strKey.slice(8, 10),
      strKey.slice(11, 13),
      strKey.slice(14, 16),
    );
  }

  static roundUpBaseUnit(date) {
    const time = date.getTime();
    const resDate = time % (BASE_UNIT * MS_PER_MINUTE)
      ? new Date(
        (Math.floor(time / (BASE_UNIT * MS_PER_MINUTE)) + 1)
              * BASE_UNIT
              * MS_PER_MINUTE,
      )
      : date;

    return resDate;
  }

  static getTimeFormatByLocale() {
    const locale = navigator.language;
    const { hourCycle } = Intl.DateTimeFormat(locale, {
      hour: 'numeric',
    }).resolvedOptions();
    let timeFormat;

    switch (hourCycle) {
      case HOUR_CYCLE_12:
        timeFormat = TIME_FORMAT_VIEW_12;
        break;
      case HOUR_CYCLE_23:
        timeFormat = TIME_FORMAT_VIEW_24;
        break;
      default:
        timeFormat = TIME_FORMAT_VIEW_12;
    }
    return timeFormat;
  }

  static getDaysListByStartEndMoment(startDate, endDate) {
    const days = [];
    let day = startDate;

    while (day.isSameOrBefore(endDate)) {
      days.push(day.format(DATE_FORMAT));
      day = moment(day).add(1, 'day');
    }

    return days;
  }
}

export default DateTime;
