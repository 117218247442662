/* eslint-disable react/no-unescaped-entities */

/* eslint-disable max-len */
import {
  CameraOutlined,
  LeftCircleOutlined,
  RightCircleOutlined,
} from '@ant-design/icons';
import {
  Button, Carousel, Col, Image, Modal, Row,
} from 'antd';
import _ from 'lodash';
import React, { PureComponent } from 'react';

import './style.css';

class Gallery extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      modalCarousel: false,
    };
  }

  openModalCarousel() {
    this.setState({ modalCarousel: true });
  }

  closeModalCarousel() {
    this.setState({ modalCarousel: false });
  }

  render() {
    const { urls } = this.props;
    const { modalCarousel } = this.state;

    return (
      <div className="Gallery">
        <div className="mdOnlyGallery">
          <Row justify="center">
            <Col span={14}>
              <Image
                preview={false}
                height="488px"
                width="100%"
                src={urls[0]}
                style={{
                  maxHeight: '488px',
                  objectFit: 'cover',
                  borderRadius: '10px 0px 0px 10px',
                }}
              />
            </Col>
            <Col span={10}>
              <Row justify="space-between">
                <Col span={24}>
                  <Image
                    preview={false}
                    height="241px"
                    width="100%"
                    src={urls[1]}
                    style={{
                      padding: '0px 0px 20px 20px',
                      maxHeight: '241px',
                      objectFit: 'cover',
                      borderRadius: '0px 10px 0px 0px',
                    }}
                  />
                </Col>
                <Col span={24}>
                  <Image
                    preview={false}
                    height="241px"
                    width="100%"
                    src={urls[2]}
                    style={{
                      padding: '0px 0px 0px 20px',
                      maxHeight: '241px',
                      objectFit: 'cover',
                      borderRadius: '0px 0px 10px 0px',
                    }}
                  />
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="floatingButtonBottom">
            <Button
              onClick={() => this.openModalCarousel()}
              icon={<CameraOutlined />}
              style={{
                backgroundColor: '#FFFFFF',
                borderRadius: '10px',
                width: '172px',
                height: '46px',
                border: 'none',
                color: '#5F5B5B',
                fontFamily: 'Inter',
                fontSize: '14px',
                letterSpacing: '-0.1px',
                lineHeight: '21px',
                textAlign: 'left',
                fontWeight: '700',
              }}
            >
              Alle anzeigen
            </Button>
          </div>
          <Modal
            footer={null}
            header={null}
            title=""
            visible={modalCarousel}
            onOk={() => this.closeModalCarousel()}
            onCancel={() => this.closeModalCarousel()}
            width="80%"
            bodyStyle={{
              width: '100%',
              padding: '0px',
            }}
          >
            <Carousel
              arrows
              nextArrow={(
                <Button
                  icon={<RightCircleOutlined style={{ fontSize: 100 }} />}
                />
              )}
              prevArrow={(
                <Button
                  icon={<LeftCircleOutlined style={{ fontSize: 100 }} />}
                />
              )}
            >
              {_.map(urls, (url) => (
                <div>
                  <Image
                    preview={false}
                    height="100%"
                    width="100%"
                    src={url}
                    style={{
                      objectFit: 'cover',
                      maxHeight: '60vh',
                    }}
                  />
                </div>
              ))}
            </Carousel>
          </Modal>
        </div>
        <div className="mobileOnlyGallery">
          <Carousel autoplay>
            {_.map(urls, (url) => (
              <div>
                <Image
                  preview={false}
                  height="100%"
                  width="100%"
                  src={url}
                  style={{
                    objectFit: 'cover',
                    maxHeight: '220px',
                  }}
                />
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    );
  }
}

export default Gallery;
