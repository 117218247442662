/* eslint-disable no-console */

/* eslint-disable class-methods-use-this */

/* eslint-disable no-unused-vars */

/* eslint-disable jsx-a11y/anchor-is-valid */
import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons';
import {
  Button, Col, Image, Input, List, Row, Select, Space,
} from 'antd';
import _ from 'lodash';
import moment from 'moment';
import 'moment/locale/de';
import React, { PureComponent } from 'react';
import { Navigate } from 'react-router-dom';

import {
  Login,
  Signup1,
  Signup2,
  Signup3,
} from '../../components/AuthSteps/AuthSteps';
import Gallery from '../../components/Gallery/Gallery';
import ListCard from '../../components/listingCard/ListCard';
import StoreConnector from '../../hoc/StoreConnector';
import withParams from '../../hoc/withParams';
import {
  activateWithCodeThunk,
  signInThunk,
  signUpThunk,
} from '../../redux/thunks/auth';
import {
  bookAppointmentThunk,
  getOffersThunk,
  resetOffersThunk,
} from '../../redux/thunks/booking';
import { closeThunk } from '../../redux/thunks/modals';
import { getResultThunk } from '../../redux/thunks/search';
import stateUtils from '../../utils/state';
import './styles.css';

class BookingWidget extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      result: {},
      providerId:
        props.params.providerId === '0' ? undefined : props.params.providerId,
      serviceId:
        props.params.serviceId === '0' ? undefined : props.params.serviceId,
      selectedDate: undefined,
      offers: [],
      navigateBookingFinished: false,
      navigateBookingConfirm: false,
      email: '',
      phone: '',
      firstName: '',
      lastName: '',
      street: '',
      building: '',
      zipcode: '',
      city: '',
      step: 'login/signin',
      // TODO needs to be moved
      validators: [
        { text: 'mindestens 8 Zeichen', isValid: false, regex: /^.{8,}$/ },
        { text: 'mindestens eine Zahl', isValid: false, regex: /.*\d/ },
        {
          text: 'mindestens ein kleinbuchstabe',
          isValid: false,
          regex: /.*[a-z]/,
        },
        {
          text: 'mindestens ein Grossbuchstabe',
          isValid: false,
          regex: /.*[A-Z]/,
        },
        {
          text: 'mindistens ein Spezial Zeichen',
          isValid: false,
          regex: /(?=.*?[_#?!@$%^&*])/,
        },
        { text: 'Passwort bestätigung stimmt überein', isValid: false },
      ],
    };
  }

  componentDidMount() {
    this.reflectProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.reflectProps(nextProps);
  }

  componentDidUpdate() {
    if (
      this.state.providerId
      && this.state.serviceId
      && !this.props.isValidOffers
      && !this.props.isFetchingOffers
      && !this.props.isFailedFetchingOffers
    ) {
      const currentDate = new Date();
      this.props.dispatch(
        getOffersThunk(
          this.props.params.centreId,
          this.state.providerId,
          this.state.serviceId,
          moment(currentDate).format('YYYY-MM-DD'),
          moment(currentDate).add(2, 'M').format('YYYY-MM-DD'),
        ),
      );
    }
  }

  onProviderChange(value) {
    this.setState({ providerId: value, serviceId: undefined, offers: [] }, () => this.props.dispatch(resetOffersThunk()));
  }

  onServiceChange(value) {
    this.setState({ serviceId: value, offers: [] }, () => this.props.dispatch(resetOffersThunk()));
  }

  setSignupFields(fields) {
    this.setState((prevState) => ({ ...prevState, ...fields }));
  }

  updateValidators(value, password, confirmation) {
    const validatorsToUpdate = _.cloneDeep(this.state.validators);
    const updatedValidators = _.map(validatorsToUpdate, (validator) => {
      const validatorCopy = validator;
      if (validatorCopy.regex && !confirmation) {
        validatorCopy.isValid = value.match(validator.regex);
      } else if (!validatorCopy.regex && confirmation) {
        validatorCopy.isValid = password === value;
      }
      return validatorCopy;
    });

    this.setState({ validators: updatedValidators });
  }

  calendarBack(count) {
    const { selectedDate } = this.state;

    this.setState({ selectedDate: moment(selectedDate).subtract(count, 'd') });
  }

  calendarForward(count) {
    const { selectedDate } = this.state;

    this.setState({ selectedDate: moment(selectedDate).add(count, 'd') });
  }

  selectOffer(offer) {
    const allOffers = Array.from(
      document.getElementsByClassName('calendarText'),
    );
    _.forEach(allOffers, (o) => {
      document.getElementById(o.id).style.backgroundColor = '#f4f3f3';
    });
    document.getElementById(
      `${offer.date}-${offer.startTime}`,
    ).style.backgroundColor = '#eb5e552b';
    this.setState({ selectedOffer: offer });
    /* if (this.props.signedIn) {
      this.setState({ selectedOffer: offer });
    } else {
      this.props.dispatch(openLoginThunk());
    } */
  }

  finishBooking() {
    this.setState({ navigateBookingFinished: true });
  }

  switchNavigateToBookingConfirm() {
    this.setState((prevState) => ({
      navigateBookingConfirm: !prevState.navigateBookingConfirm,
    }));
  }

  reflectProps(props) {
    if (
      !props.isFetchingResult
      && !props.isValidResult
      && !props.isFailedFetchingResult
    ) {
      props.dispatch(getResultThunk(props.params.centreId));
    }
    if (props.isValidResult) {
      this.setState({ result: this.props.result }, () => this.setState({ result: this.props.result }));
    }
    if (props.isValidOffers && props.offers.length > 0) {
      const filteredOffers = _.filter(props.offers, (offer) => offer.available);
      if (filteredOffers.length !== 0) {
        this.setState(
          {
            selectedDate: moment(filteredOffers[0].date, 'YYYY-MM-DD').locale(
              'de',
            ),
            offers: filteredOffers,
          },
          this.setState({
            selectedDate: moment(filteredOffers[0].date, 'YYYY-MM-DD').locale(
              'de',
            ),
            offers: filteredOffers,
          }),
        );
      } else {
        this.setState(
          {
            selectedDate: moment(new Date()).locale('de'),
            offers: filteredOffers,
          },
          this.setState({
            selectedDate: moment(new Date()).locale('de'),
            offers: filteredOffers,
          }),
        );
      }
    }
  }

  render() {
    const {
      result,
      providerId,
      serviceId,
      offers,
      selectedDate,
      selectedOffer,
      navigateBookingFinished,
      navigateBookingConfirm,
      email,
      phone,
      firstName,
      lastName,
      street,
      building,
      zipcode,
      city,
      step,
    } = this.state;

    if (!result.centre) {
      return <>...</>;
    }

    const { centre } = result;

    const providerList = [];
    _.map(centre.CentreProvider, (centreProvider) => {
      if (centreProvider.bookable) {
        providerList.push({
          label: `${centreProvider.User.firstName} ${centreProvider.User.lastName}`,
          value: centreProvider.centreProvider_id,
        });
      }
    });

    const selectedProvider = _.find(
      centre.CentreProvider,
      (centreProvider) => centreProvider.centreProvider_id === providerId,
    );
    const selectedService = _.find(
      selectedProvider.Service,
      (service) => service.service_id === serviceId,
    );

    const serviceList = [];
    if (selectedProvider) {
      _.map(
        _.get(
          _.find(
            centre.CentreProvider,
            (centreProvider) => centreProvider.centreProvider_id === providerId,
          ),
          'Service',
        ),
        (service) => {
          if (service.onlineBooking) {
            serviceList.push({ label: service.name, value: service.service_id });
          }
        },
      );
    }

    const dayOne = moment(selectedDate).add();
    dayOne.add(100, 'M');
    return (
      <div className="bookingWidget">
        {navigateBookingFinished && (
          <Navigate to={`/profile/${centre.centre_id}`} replace />
        )}
        {!navigateBookingConfirm && !this.props.bookingSuccessful && (
          <>
            <Row justify="start">
              <Col span={24} lg={16}>
                <Row style={{ paddingBottom: '5px' }}>
                  <div className="centreName">{centre.name}</div>
                </Row>
                <Row style={{ paddingBottom: '20px' }}>
                  <div className="profileSectionContent">
                    {`${centre.Address.street} ${centre.Address.building}, ${centre.Address.zipcode} ${centre.Address.city}`}
                  </div>
                </Row>
              </Col>
              {/* <Col span={24} lg={8}>
                <div className="contentBox">
                  <p className="profileSectionContent">Wählen Sie die/den gewünschte/n TherapeutIn und eine Dienstleistung aus.</p>
                </div>
              </Col> */}
            </Row>
            <Row gutter={20}>
              {/* Select for provider */}
              <Col span={24} style={{ paddingBottom: '20px' }}>
                <Select
                  placeholder="TherapeutIn"
                  options={providerList}
                  onChange={(value) => this.onProviderChange(value)}
                  bordered={false}
                  value={providerId}
                  style={{
                    width: '100%',
                    border: '1px solid #EDE8E9',
                    borderRadius: '10px',
                    fontFamily: 'Inter',
                    textAlign: 'left',
                    color: '#3A3335',
                    fontSize: '14px',
                    letterSpacing: '-0.1px',
                    lineHeight: '21px',
                  }}
                  dropdownStyle={{
                    fontFamily: 'Inter',
                    textAlign: 'left',
                    color: '#3A3335',
                    fontSize: '14px',
                    letterSpacing: '-0.1px',
                    lineHeight: '21px',
                  }}
                />
              </Col>
              {/* Select for service */}
              <Col span={24} style={{ paddingBottom: '20px' }}>
                <Select
                  placeholder="Dienstleistung"
                  options={serviceList}
                  onChange={(value) => this.onServiceChange(value)}
                  bordered={false}
                  value={serviceId}
                  style={{
                    width: '100%',
                    border: '1px solid #EDE8E9',
                    borderRadius: '10px',
                    fontFamily: 'Inter',
                    textAlign: 'left',
                    color: '#3A3335',
                    fontSize: '14px',
                    letterSpacing: '-0.1px',
                    lineHeight: '21px',
                  }}
                  dropdownStyle={{
                    fontFamily: 'Inter',
                    textAlign: 'left',
                    color: '#3A3335',
                    fontSize: '14px',
                    letterSpacing: '-0.1px',
                    lineHeight: '21px',
                  }}
                />
              </Col>
            </Row>
            {/* Service description if necessery */}
            {selectedService && (
              <Row style={{ paddingBottom: '20px' }}>
                {selectedService.description && (
                  <Col span={24}>
                    <div className="contentBox">
                      <p className="profileSectionContent">
                        {selectedService.description}
                      </p>
                    </div>
                  </Col>
                )}
              </Row>
            )}
            {offers.length > 0 && (
              <Row>
                <Col span={24}>
                  <div className="contentBox">
                    <Row style={{ paddingBottom: '20px' }}>
                      {/* <Col span={2} md={0}>
                        <Button
                          className="authResetPasswordButton"
                          onClick={() => this.calendarBack(3)}
                        >
                          <p className="calendarTitle">{'<'}</p>
                        </Button>
                      </Col>
                      <Col span={0} md={2}>
                        <Button
                          className="authResetPasswordButton"
                          onClick={() => this.calendarBack(5)}
                        >
                          <p className="calendarTitle">{'<'}</p>
                        </Button>
                      </Col> */}
                      <Col span={24}>
                        <p className="calendarTitle">
                          {` ${moment(selectedDate).locale('de').format('MMMM')} ${moment(selectedDate).year()}`}
                        </p>
                      </Col>
                      {/* <Col span={2} md={0}>
                        <Button
                          className="authResetPasswordButton"
                          onClick={() => this.calendarForward(3)}
                        >
                          <p className="calendarTitle">{'>'}</p>
                        </Button>
                      </Col>
                      <Col span={0} md={2}>
                        <Button
                          className="authResetPasswordButton"
                          onClick={() => this.calendarForward(5)}
                        >
                          <p className="calendarTitle">{'>'}</p>
                        </Button>
                      </Col> */}
                    </Row>
                    <Row align="middle" justify="space-between">
                      {/* Button backwards */}
                      <Col span={2}>
                        {/* TODO: use  justify={md: center, sm: start, xs: start} */}
                        <Row>
                          <Col
                            span={24}
                            md={0}
                            className="arrowOutlinedContainer"
                          >
                            <Button
                              className="arrowOutlinedButton"
                              onClick={() => this.calendarBack(3)}
                            >
                              <LeftOutlined />
                            </Button>
                          </Col>
                          <Col
                            span={0}
                            md={24}
                            className="arrowOutlinedContainer"
                          >
                            <Button
                              className="arrowOutlinedButton"
                              onClick={() => this.calendarBack(5)}
                            >
                              <LeftOutlined />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                      {/* Visible Days */}
                      <Col span={20}>
                        <Row
                          justify="space-between"
                          className="visibleDaysContainer"
                        >
                          <Col span={7} md={4}>
                            <Row style={{ paddingBottom: '10px' }}>
                              <Col span={0} md={24}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .locale('de')
                                    .format('dddd')}
                                </p>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .locale('de')
                                    .format('DD')}
                                </p>
                              </Col>
                              <Col span={24} md={0}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .locale('de')
                                    .format('dd DD')}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                {_.map(
                                  _.filter(
                                    offers,
                                    (offer) => offer.date
                                      === moment(selectedDate).format('YYYY-MM-DD'),
                                  ),
                                  (dayOffer) => (
                                    <Button
                                      className="authResetPasswordButton"
                                      onClick={() => this.selectOffer(dayOffer)}
                                    >
                                      <p
                                        id={`${dayOffer.date}-${dayOffer.startTime}`}
                                        className="calendarText"
                                      >
                                        {dayOffer.startTime}
                                      </p>
                                    </Button>
                                  ),
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={7} md={4}>
                            <Row style={{ paddingBottom: '10px' }}>
                              <Col span={0} md={24}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(1, 'd')
                                    .locale('de')
                                    .format('dddd')}
                                </p>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(1, 'd')
                                    .locale('de')
                                    .format('DD')}
                                </p>
                              </Col>
                              <Col span={24} md={0}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(1, 'd')
                                    .locale('de')
                                    .format('dd DD')}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                {_.map(
                                  _.filter(
                                    offers,
                                    (offer) => offer.date
                                      === moment(selectedDate)
                                        .add(1, 'd')
                                        .format('YYYY-MM-DD'),
                                  ),
                                  (dayOffer) => (
                                    <Button
                                      className="authResetPasswordButton"
                                      onClick={() => this.selectOffer(dayOffer)}
                                    >
                                      <p
                                        id={`${dayOffer.date}-${dayOffer.startTime}`}
                                        className="calendarText"
                                      >
                                        {dayOffer.startTime}
                                      </p>
                                    </Button>
                                  ),
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={7} md={4}>
                            <Row style={{ paddingBottom: '10px' }}>
                              <Col span={0} md={24}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(2, 'd')
                                    .locale('de')
                                    .format('dddd')}
                                </p>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(2, 'd')
                                    .locale('de')
                                    .format('DD')}
                                </p>
                              </Col>
                              <Col span={24} md={0}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(2, 'd')
                                    .locale('de')
                                    .format('dd DD')}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                {_.map(
                                  _.filter(
                                    offers,
                                    (offer) => offer.date
                                      === moment(selectedDate)
                                        .add(2, 'd')
                                        .format('YYYY-MM-DD'),
                                  ),
                                  (dayOffer) => (
                                    <Button
                                      className="authResetPasswordButton"
                                      onClick={() => this.selectOffer(dayOffer)}
                                    >
                                      <p
                                        id={`${dayOffer.date}-${dayOffer.startTime}`}
                                        className="calendarText"
                                      >
                                        {dayOffer.startTime}
                                      </p>
                                    </Button>
                                  ),
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={0} md={4}>
                            <Row style={{ paddingBottom: '10px' }}>
                              <Col span={0} md={24}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(3, 'd')
                                    .locale('de')
                                    .format('dddd')}
                                </p>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(3, 'd')
                                    .locale('de')
                                    .format('DD')}
                                </p>
                              </Col>
                              <Col span={24} md={0}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(3, 'd')
                                    .locale('de')
                                    .format('dd DD')}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                {_.map(
                                  _.filter(
                                    offers,
                                    (offer) => offer.date
                                      === moment(selectedDate)
                                        .add(3, 'd')
                                        .format('YYYY-MM-DD'),
                                  ),
                                  (dayOffer) => (
                                    <Button
                                      className="authResetPasswordButton"
                                      onClick={() => this.selectOffer(dayOffer)}
                                    >
                                      <p
                                        id={`${dayOffer.date}-${dayOffer.startTime}`}
                                        className="calendarText"
                                      >
                                        {dayOffer.startTime}
                                      </p>
                                    </Button>
                                  ),
                                )}
                              </Col>
                            </Row>
                          </Col>
                          <Col span={0} md={4}>
                            <Row style={{ paddingBottom: '10px' }}>
                              <Col span={0} md={24}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(4, 'd')
                                    .locale('de')
                                    .format('dddd')}
                                </p>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(4, 'd')
                                    .locale('de')
                                    .format('DD')}
                                </p>
                              </Col>
                              <Col span={24} md={0}>
                                <p className="calendarSubtitle">
                                  {moment(selectedDate)
                                    .add(4, 'd')
                                    .locale('de')
                                    .format('dd DD')}
                                </p>
                              </Col>
                            </Row>
                            <Row>
                              <Col span={24}>
                                {_.map(
                                  _.filter(
                                    offers,
                                    (offer) => offer.date
                                      === moment(selectedDate)
                                        .add(4, 'd')
                                        .format('YYYY-MM-DD'),
                                  ),
                                  (dayOffer) => (
                                    <Button
                                      className="authResetPasswordButton"
                                      onClick={() => this.selectOffer(dayOffer)}
                                    >
                                      <p
                                        id={`${dayOffer.date}-${dayOffer.startTime}`}
                                        className="calendarText"
                                      >
                                        {dayOffer.startTime}
                                      </p>
                                    </Button>
                                  ),
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                      {/* Button forward */}
                      <Col span={2}>
                        {/* TODO: use  justify={md: center, sm: end, xs: end} */}
                        <Row>
                          <Col
                            span={24}
                            md={0}
                            className="arrowOutlinedContainer"
                          >
                            <Button
                              className="arrowOutlinedButton"
                              onClick={() => this.calendarForward(3)}
                            >
                              <RightOutlined />
                            </Button>
                          </Col>
                          <Col
                            span={0}
                            md={24}
                            className="arrowOutlinedContainer"
                          >
                            <Button
                              className="arrowOutlinedButton"
                              onClick={() => this.calendarForward(5)}
                            >
                              <RightOutlined />
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            )}
            {this.props.isValidOffers && offers.length === 0 && (
              <Col span={24} className="noAppointmentsAvailable">
                <Row className="noAppointmentsAvailable" justify="center">
                  Zur Zeit sind keine freien Termine buchbar, schaue später
                  wieder vorbei!
                </Row>
                <Image
                  style={{ width: '100%' }}
                  src="/no-bookingslots.png"
                  preview={false}
                />
              </Col>
            )}
            <Col span={24}>
              <Row justify="space-between" style={{ paddingBottom: '10px' }}>
                <Button
                  className="cancelButton"
                  onClick={() => this.finishBooking()}
                >
                  Abbrechen
                </Button>
                {selectedOffer && selectedService && (
                  <Button
                    onClick={() => this.switchNavigateToBookingConfirm()}
                    className="nextButton"
                  >
                    Weiter
                  </Button>
                )}
              </Row>
            </Col>
          </>
        )}
        {navigateBookingConfirm
          && !this.props.signedIn
          && !this.props.bookingSuccessful && (
            <>
              <div className="contentBox">
                <Row>
                  <Col>Ihre Buchung</Col>
                </Row>
                <Row>
                  <Col span={24}>{selectedService.name}</Col>
                  <Col span={24}>
                    {`${selectedProvider.User.firstName} ${selectedProvider.User.lastName}`}
                  </Col>
                  <Col span={24}>
                    {`${moment(selectedOffer.date).locale('de').format('MMMM D, YYYY')} ${selectedOffer.startTime}`}
                  </Col>
                </Row>
              </div>
              {step === 'login/signin' && (
                <div className="contentBox">
                  <Row>Login oder Registrieren</Row>
                  <Login
                    openPasswordReset={() => console.log('openPasswordreset')}
                    signIn={(e, p) => this.props.dispatch(signInThunk(e, p))}
                    openSignup1={() => console.log('openSignup1')}
                    bookingWidget
                  />
                  <div
                    style={{
                      borderTop: '1px solid #EDE8E9',
                      paddingTop: '20px',
                    }}
                  >
                    <Signup1
                      signupFields={{
                        email,
                        phone,
                        firstName,
                        lastName,
                        street,
                        building,
                        zipcode,
                        city,
                      }}
                      setSignupFields={(fields) => this.setSignupFields(fields)}
                      openSignup2={() => this.setState({ step: 'password' })}
                      openLogin={() => console.log('openLogin')}
                      fromInvoice={false}
                      bookingWidget
                    />
                  </div>
                </div>
              )}
              {(step === 'password' || this.state.step === 'verification') && (
                <div className="contentBox">
                  <Row>
                    <Col>Ihre Daten</Col>
                  </Row>
                  <Row>
                    <Col span={24}>{`${firstName} ${lastName}`}</Col>
                    <Col span={24}>{`${email} ${phone}`}</Col>
                  </Row>
                </div>
              )}
              {step === 'password' && (
                <div className="contentBox">
                  <Signup2
                    validators={this.state.validators}
                    updateValidators={(value, password, confirmation) => this.updateValidators(value, password, confirmation)}
                    signupFields={{
                      email,
                      phone,
                      firstName,
                      lastName,
                      street,
                      building,
                      zipcode,
                      city,
                    }}
                    signUp={(fields) => {
                      this.setState({ step: 'verification' });
                      this.props.dispatch(signUpThunk(fields));
                    }}
                  />
                </div>
              )}
              {step === 'verification' && (
                <div className="contentBox">
                  <Signup3
                    activateWithCode={(verificationCode) => this.props.dispatch(
                      activateWithCodeThunk({
                        userId: this.props.userId,
                        code: verificationCode,
                      }),
                    )}
                  />
                </div>
              )}
              <Row>
                <Col span={24}>
                  <Row
                    justify="space-between"
                    style={{ paddingBottom: '10px' }}
                  >
                    <Button
                      className="cancelButton"
                      onClick={() => this.switchNavigateToBookingConfirm()}
                    >
                      Zurück
                    </Button>
                  </Row>
                </Col>
              </Row>
            </>
        )}
        {navigateBookingConfirm
          && this.props.signedIn
          && !this.props.bookingSuccessful && (
            <div className="contentBox">
              <Row justify="center" style={{ paddingBottom: '30px' }}>
                <Col span={24}>
                  <p className="checkoutTitle">Ihre Terminangaben</p>
                </Col>
              </Row>
              <Row justify="center" style={{ paddingBottom: '20px' }}>
                <Col span={24}>
                  <p className="checkoutText">{`${centre.name} - ${selectedProvider.User.firstName} ${selectedProvider.User.lastName}`}</p>
                </Col>
              </Row>
              <Row justify="center" style={{ paddingBottom: '20px' }}>
                <Col span={24}>
                  <p className="checkoutText">{`${selectedService.name}`}</p>
                </Col>
              </Row>
              <Row justify="center" style={{ paddingBottom: '30px' }}>
                <Col span={24}>
                  <p className="checkoutText">{`Termin am ${moment(selectedOffer.date, 'YYYY-MM-DD').format('DD.MM.YYYY')} um ${selectedOffer.startTime}`}</p>
                </Col>
              </Row>
              <Row justify="center" style={{ paddingBottom: '0px' }}>
                <Col span={24}>
                  <Button
                    primary
                    className="checkoutButton"
                    onClick={() => this.props.dispatch(
                      bookAppointmentThunk(
                        centre.centre_id,
                        selectedProvider.centreProvider_id,
                        selectedService.service_id,
                        this.props.userId,
                        selectedOffer.date,
                        selectedOffer.startTime,
                        moment(selectedOffer.startTime, 'HH:mm')
                          .add(selectedService.duration, 'minutes')
                          .add(selectedService.bufferTime, 'minutes')
                          .format('HH:mm'),
                      ),
                    )}
                  >
                    Bestätigen
                  </Button>
                </Col>
              </Row>
            </div>
        )}
        {this.props.bookingSuccessful && (
          <div className="contentBox">
            <Row justify="center" style={{ paddingBottom: '50px' }}>
              <Col span={24}>
                <p className="checkoutTitle">
                  Ihre Termin wurde erfolgreich gebucht!
                </p>
              </Col>
            </Row>
            <Row justify="center" style={{ paddingBottom: '0px' }}>
              <Col span={24}>
                <Button
                  primary
                  className="checkoutButton"
                  onClick={() => this.finishBooking()}
                >
                  Weiter
                </Button>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  }
}

export default StoreConnector(
  withParams(BookingWidget),
  stateUtils.bookingWidgetStateMap,
  {},
);
