import mapper from './mapper';

const ACTION_TYPES = {
  SHOW_LOADER: '',
  DISMISS_LOADER: '',
  SHOW_ALERT: '',
  CLOSE_ALERT: '',
  UPDATE_INPUT_VALUE: '',
  SHOW_DATE_PICKER: '',
  CLOSE_DATE_PICKER: '',
  SHOW_VALUE_SELECTOR: '',
  CLOSE_VALUE_SELECTOR: '',
};

export default {
  ACTION_TYPES: mapper(ACTION_TYPES),
};
