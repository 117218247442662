const stateUtils = {
  fullStateMap: (state) => {
    const authState = state.auth;
    const searchState = state.search;
    const modalsState = state.modals;
    const bookingState = state.booking;
    const dashboardState = state.dashboard;

    return {
      ...authState,
      ...searchState,
      ...modalsState,
      ...bookingState,
      ...dashboardState,
    };
  },
  authStateMap: (state) => {
    const authState = state.auth;

    return {
      ...authState,
    };
  },
  searchStateMap: (state) => {
    const searchState = state.search;

    return {
      ...searchState,
    };
  },
  modalsStateMap: (state) => {
    const modalsState = state.modals;

    return {
      ...modalsState,
    };
  },
  bookingStateMap: (state) => {
    const bookingState = state.booking;

    return {
      ...bookingState,
    };
  },
  dashboardStateMap: (state) => {
    const dashboardState = state.dashboard;

    return {
      ...dashboardState,
    };
  },
  bookingWidgetStateMap: (state) => {
    const authState = state.auth;
    const searchState = state.search;
    const bookingState = state.booking;

    return {
      ...authState,
      ...searchState,
      ...bookingState,
    };
  },
};

export default stateUtils;
