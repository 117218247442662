/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const modalsSlice = createSlice({
  name: 'modals',
  initialState: {
    step: 'login',
    open: false,
    error: false,
    errorMessage: '',
    errorMessageDev: '',
    savedContact: '',
  },
  reducers: {
    close: (state) => {
      state.open = false;
    },
    openLogin: (state) => {
      state.open = true;
      state.step = 'login';
    },
    openSignup1: (state) => {
      state.open = true;
      state.step = 'signup1';
    },
    openSignup2: (state) => {
      state.open = true;
      state.step = 'signup2';
    },
    openSignup3: (state) => {
      state.open = true;
      state.step = 'signup3';
    },
    openSignup4: (state) => {
      state.open = true;
      state.step = 'signup4';
    },
    openPasswordReset: (state) => {
      state.open = true;
      state.step = 'passwordReset';
    },
    openPasswordRenew: (state) => {
      state.open = true;
      state.step = 'passwordRenew';
    },
    openSignupUnfinished: (state) => {
      state.open = true;
      state.step = 'signUpUnfinished';
    },
    openPasswordRenewSuccess: (state) => {
      state.open = true;
      state.step = 'passwordRenewSuccess';
    },
    saveContactInState: (state, action) => {
      if (action.payload.contact) {
        state.open = true;
        state.step = action.payload.contact.active ? 'login' : 'signupInvoice';
        state.savedContact = action.payload.contact;
      } else {
        state.savedContact = action.payload.contact;
      }
    },
    cleanError: (state) => {
      state.error = false;
      state.errorMessage = '';
      state.errorMessageDev = '';
    },
    setError: (state, action) => {
      state.error = true;
      state.errorMessage = action.payload.errorMessage;
      if (action.payload.errorMessageDev.response) {
        state.errorMessageDev = action.payload.errorMessageDev.response.data.error.message;
      }
    },
  },
});

export const {
  close,
  openLogin,
  openSignup1,
  openSignup2,
  openSignup3,
  openSignup4,
  openPasswordReset,
  openPasswordRenew,
  openPasswordRenewSuccess,
  saveContactInState,
  cleanError,
  setError,
} = modalsSlice.actions;

export default modalsSlice.reducer;
