/* eslint-disable func-names */
import React from 'react';
import { useParams } from 'react-router-dom';

const withParams = (Component) => function (props) {
  const params = useParams();
  return <Component params={params} {...props} />;
};

export default withParams;
