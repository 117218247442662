/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: {
    isFetchingInvoiceList: false,
    isValidInvoiceList: false,
    isFailedFetchingInvoiceList: false,
    invoiceList: [],
    isFetchingAppointmentList: false,
    isValidAppointmentList: false,
    isFailedFetchingAppointmentList: false,
    appointmentList: [],
    isFetchingInvoice: false,
    isValidInvoice: false,
    isFailedFetchingInvoice: false,
    invoice: {},
    isFetchingUserData: false,
    isValidUserData: false,
    isFailedFetchingUserData: false,
    userData: {},
  },
  reducers: {
    getInvoiceList: (state) => {
      state.isFetchingInvoiceList = true;
      state.isValidInvoiceList = false;
      state.isFailedFetchingInvoiceList = false;
      state.invoiceList = [];
    },
    getInvoiceListS: (state, action) => {
      state.isFetchingInvoiceList = false;
      state.isValidInvoiceList = true;
      state.isFailedFetchingInvoiceList = false;
      state.invoiceList = action.payload;
    },
    getInvoiceListF: (state) => {
      state.isFetchingInvoiceList = false;
      state.isValidInvoiceList = false;
      state.isFailedFetchingInvoiceList = true;
      state.invoiceList = [];
    },
    resetInvoiceList: (state) => {
      state.isFetchingInvoiceList = false;
      state.isValidInvoiceList = false;
      state.isFailedFetchingInvoiceList = false;
      state.invoiceList = [];
    },
    getAppointmentList: (state) => {
      state.isFetchingAppointmentList = true;
      state.isValidAppointmentList = false;
      state.isFailedFetchingAppointmentList = false;
      state.appointmentList = [];
    },
    getAppointmentListS: (state, action) => {
      state.isFetchingAppointmentList = false;
      state.isValidAppointmentList = true;
      state.isFailedFetchingAppointmentList = false;
      state.appointmentList = action.payload;
    },
    getAppointmentListF: (state) => {
      state.isFetchingAppointmentList = false;
      state.isValidAppointmentList = false;
      state.isFailedFetchingAppointmentList = true;
      state.appointmentList = [];
    },
    resetAppointmentList: (state) => {
      state.isFetchingAppointmentList = false;
      state.isValidAppointmentList = false;
      state.isFailedFetchingAppointmentList = false;
      state.appointmentList = [];
    },
    getInvoice: (state) => {
      state.isFetchingInvoice = true;
      state.isValidInvoice = false;
      state.isFailedFetchingInvoice = false;
      state.invoice = {};
    },
    getInvoiceS: (state, action) => {
      state.isFetchingInvoice = false;
      state.isValidInvoice = true;
      state.isFailedFetchingInvoice = false;
      state.invoice = action.payload;
    },
    getInvoiceF: (state) => {
      state.isFetchingInvoice = false;
      state.isValidInvoice = false;
      state.isFailedFetchingInvoice = true;
      state.invoice = {};
    },
    resetInvoice: (state) => {
      state.isFetchingInvoice = false;
      state.isValidInvoice = false;
      state.isFailedFetchingInvoice = false;
      state.invoice = {};
    },
    getUserData: (state) => {
      state.isFetchingUserData = true;
      state.isValidUserData = false;
      state.isFailedFetchingUserData = false;
      state.userData = {};
    },
    getUserDataS: (state, action) => {
      state.isFetchingUserData = false;
      state.isValidUserData = true;
      state.isFailedFetchingUserData = false;
      state.userData = action.payload;
    },
    getUserDataF: (state) => {
      state.isFetchingUserData = false;
      state.isValidUserData = false;
      state.isFailedFetchingUserData = true;
      state.userData = {};
    },
    resetUserData: (state) => {
      state.isFetchingUserData = false;
      state.isValidUserData = false;
      state.isFailedFetchingUserData = false;
      state.userData = {};
    },
  },
});

export const {
  getAppointmentList,
  getAppointmentListS,
  getAppointmentListF,
  resetAppointmentList,
  getInvoiceList,
  getInvoiceListS,
  getInvoiceListF,
  resetInvoiceList,
  getInvoice,
  getInvoiceS,
  getInvoiceF,
  resetInvoice,
  getUserData,
  getUserDataS,
  getUserDataF,
  resetUserData,
} = dashboardSlice.actions;

export default dashboardSlice.reducer;
