export default `
  .ith_embed-auth-stepper {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    min-height: 500px;
    width: 100%;
    padding: 15px 25px;
    border: 1px solid #2a99cc;
    border-radius: 10px;
    background: #fff;
  }

  .ith_embed-auth-stepper .ant-input-affix-wrapper,
  .ith_auth-wrapper-field .ant-select-selector {
    border-radius: 25px !important;
  }
`;
