import React from 'react';
import Loader from 'react-loader';

import colors from '../../constants/colors';
import styles from './styles';

function CustomLoader({ isLoading }) {
  return (
  // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {!isLoading ? (
        ''
      ) : (
        <div className="loader-wrapper">
          <div>
            <Loader color={colors.indicatorColor} width={4} length={10} />
          </div>
          <style>{styles}</style>
        </div>
      )}
    </>
  );
}

export default CustomLoader;
