export default `
  .ith_embed-booking-summary {
    display: flex;
    flex-direction: column;
    width: calc(100% - 2px);
    border: 1px solid #2a99cc;
    border-radius: 10px;
    margin-bottom: 15px;
    background: #2a99cc;
  }

  .ith_embed-booking-summary-main .ant-row .ant-col {
    display: flex;
    align-items: center;
  }

  .ith_embed-booking-summary-header {
    background: #2a99cc;
    color: #fff;
    font-weight: 500;
    font-size: 18px;
    border-radius: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .ith_embed-booking-summary-main {
    background: #fff;
    padding: 15px 0;
    border-radius: 10px;
  }

  .ith_embed-booking-summary-main img {
    margin-right: 5px;
  }

  .ith_embed-booking-summary-header-button {
    position: absolute;
    right: 15px;
    cursor: pointer;
  }
`;
