export default `
  .ith_timeSlot {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .ith_timeBox {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    font-weight: 500;
    font-size: 18px;
    margin: 5px;
    transition:
      box-shadow 0.3s,
      color 0.3s;
    cursor: default;
    background-color: #c6c4c4;
    opacity: 0.6;
    z-index: 1;
  }

  .ith_timeBox.ith_available {
    opacity: 1;
    background-color: #ffe3da;
    color: #4a4a4a;
    cursor: pointer;
  }

  .ith_timeBox.ith_selected {
    background: #e74c3c !important;
    color: #fff !important;
    opacity: 1;
  }

  .ith_timeBox.ith_highlighted {
    background: rgba(223, 71, 98, 100);
  }

  .ith_timeBox.ith_smallPicker {
    width: 35%;
    padding: 20px;
  }

  .ith_timeBox.ith_available:hover {
    box-shadow: 0 0 11px rgba(223, 71, 98, 100);
  }

  .ith_timeBox:first-child {
    margin-top: 24px;
  }

  .ith_timeBox:last-child {
    margin-bottom: 24px;
  }

  .ith_emptySlot {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-weight: bold;
    word-break: break-word;
  }
  @media (max-width: 1350px) {
    .ith_timeBox {
      font-size: 14px;
    }
  }
`;
