import { MenuOutlined, UserOutlined } from '@ant-design/icons';
import {
  Button, Col, Dropdown, Image, Menu, Row, Space,
} from 'antd';
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';

import StoreConnector from '../../hoc/StoreConnector';
import JwtService from '../../interfaces/jwtService';
import { getUserFromTokenThunk, signOutThunk } from '../../redux/thunks/auth';
import { openLoginThunk, openSignup1Thunk } from '../../redux/thunks/modals';
import stateUtils from '../../utils/state';
import './styles.css';

class PageHeader extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  async componentDidMount() {
    const { userId } = this.props;

    if (
      !userId
      && JwtService.hasAccessToken()
      && JwtService.hasRefreshToken()
    ) {
      this.props.dispatch(getUserFromTokenThunk());
    }
  }

  render() {
    const itemsSignUp = [
      {
        key: '1',
        label: (
          <Button
            className="headerSignUpDashboard"
            onClick={() => this.props.dispatch(openSignup1Thunk())}
          >
            Registrieren
          </Button>
        ),
      },
      {
        key: '2',
        label: (
          <Button
            className="headerSignInLogout"
            icon={<UserOutlined />}
            onClick={() => this.props.dispatch(openLoginThunk())}
          >
            Login
          </Button>
        ),
      },
    ];
    const itemsDashboard = [
      {
        key: '1',
        label: (
          <Link to="/dashboard/">
            <Button className="headerSignUpDashboard">Dashboard</Button>
          </Link>
        ),
      },
      {
        key: '2',
        label: (
          <Button
            className="headerSignInLogout"
            icon={<UserOutlined />}
            onClick={() => this.props.dispatch(signOutThunk())}
          >
            Logout
          </Button>
        ),
      },
    ];
    return (
      <Row className="pageHeader" align="middle" justify="space-between">
        <Col span={12} className="headerLogo">
          <Link to="/">
            <Image width="100%" preview={false} src="/sanamia-logo.svg" />
          </Link>
        </Col>
        <Col span={12}>
          <Row align="middle" justify="end">
            <Col xs={24} sm={24} md={0}>
              <div className="phoneSignInSignUp">
                <Dropdown
                  overlay={(
                    <Menu
                      items={this.props.signedIn ? itemsDashboard : itemsSignUp}
                    />
                  )}
                  placement="bottomRight"
                >
                  <Button icon={<MenuOutlined />} />
                </Dropdown>
              </div>
            </Col>
            <Col xs={0} md={24} lg={24} xl={24}>
              <Space className="normalSignInSignUp">
                {!this.props.signedIn && (
                  <>
                    <a href="/" className="headerLink">
                      {' '}
                      Für Therapeuten
                    </a>
                    <Button
                      className="headerSignUpDashboard"
                      onClick={() => this.props.dispatch(openSignup1Thunk())}
                    >
                      Registrieren
                    </Button>
                    <Button
                      className="headerSignInLogout"
                      icon={<UserOutlined />}
                      onClick={() => this.props.dispatch(openLoginThunk())}
                    >
                      Login
                    </Button>
                  </>
                )}
                {this.props.signedIn && (
                  <>
                    <Link to="/dashboard/">
                      <Button className="headerSignUpDashboard">
                        mein Konto
                      </Button>
                    </Link>
                    <Button
                      className="headerSignInLogout"
                      icon={<UserOutlined />}
                      onClick={() => this.props.dispatch(signOutThunk())}
                    >
                      Logout
                    </Button>
                  </>
                )}
              </Space>
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }
}

export default StoreConnector(PageHeader, stateUtils.fullStateMap, {});
