import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api';
import * as React from 'react';

import './index.css';

export default function Home({ lat = 47.36667, lng = 8.55, size = 'full' }) {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyCSo8O28KFJXb9OJq3NhHQsi3Za0u7w9I4',
  });

  if (!isLoaded) return <div>Loading...</div>;
  return <Map lat={lat} lng={lng} size={size} />;
}

const onLoad = (marker) => {
  console.log('marker: ', marker.position);
};

function Map({ lat = 47.36667, lng = 8.55, size = 'full' }) {
  const center = { lat, lng };

  return (
    <GoogleMap
      zoom={12}
      center={center}
      mapContainerClassName={
        size === 'full' ? 'map-container-full' : 'map-container-small'
      }
    >
      <Marker onLoad={onLoad} position={center} />
    </GoogleMap>
  );
}
