import React, { PureComponent } from 'react';

import Profile from '../../containers/Profile/Profile';
import WidgetFrame from '../../hoc/WidgetFrame';

class ProfileWidget extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="pageContainer">
        <Profile />
      </div>
    );
  }
}

export default WidgetFrame(ProfileWidget);
