import React, { PureComponent } from 'react';

import BookingInfos from '../../components/BookingInfos';
import ProfileHeader from '../ProfileHeader';
import styles from './styles';

class CheckoutContainer extends PureComponent {
  render() {
    const {
      t,
      centre,
      provider,
      services,
      startTime,
      endTime,
      bufferTime,
      date,
      userData,
      showBookingCalendar,
      isEmbed,
      rescheduleBookClick,
      isModal,
      closeNewBookingModalAndGetAppts,
    } = this.props;

    if (!centre || !provider || !services || !startTime || !endTime || !date) {
      return null;
    }

    return (
      <ith-div class="ith_content">
        {!isEmbed ? <ProfileHeader t={t} centre={centre} /> : null}
        {!isEmbed ? (
          <ith-div class="ith_bookingSteps">
            <ith-div class="ith_step1">
              <ith-div class="ith_stepIcon">
                <ith-p>1</ith-p>
              </ith-div>
              <ith-p class="ith_stepText">{t('bookingStep1')}</ith-p>
            </ith-div>
            <ith-div class="ith_step2">
              <ith-div class="ith_stepIcon">
                <ith-p>2</ith-p>
              </ith-div>
              <ith-p class="ith_stepText">{t('bookingStep2')}</ith-p>
            </ith-div>
          </ith-div>
        ) : null}
        <ith-div class="ith_checkout">
          <ith-div class="ith_infosUserBooking">
            <BookingInfos
              t={t}
              centre={centre}
              provider={provider}
              services={services}
              startTime={startTime}
              endTime={endTime}
              bufferTime={bufferTime}
              date={date}
              userData={userData}
              showBookingCalendar={showBookingCalendar}
              isEmbed={isEmbed}
              isModal={isModal}
              rescheduleBookClick={rescheduleBookClick}
              closeNewBookingModalAndGetAppts={closeNewBookingModalAndGetAppts}
            />
          </ith-div>
        </ith-div>
        <style>{styles}</style>
      </ith-div>
    );
  }
}

export default CheckoutContainer;
