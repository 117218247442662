import { useEffect } from 'react';

const useLoadFAQWidget = (url, text) => {
  useEffect(() => {
    const script1 = document.createElement('script');
    const script2 = document.createElement('script');

    script1.text = text;

    script2.src = url;

    script2.async = true;
    script2.defer = true;
    script2.type = 'text/javascript';

    document.body.appendChild(script1);
    document.body.appendChild(script2);
    return () => {
      document.body.removeChild(script1);
      document.body.removeChild(script2);
    };
  }, [url]);
};

export default useLoadFAQWidget;
