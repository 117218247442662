/* eslint-disable no-console */

/* eslint-disable class-methods-use-this */

/* eslint-disable no-unused-vars */

/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  CalendarOutlined,
  DashboardOutlined,
  FilePdfOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import {
  Col, Image, List, Row, Space,
} from 'antd';
import _ from 'lodash';
import React from 'react';
import { Link } from 'react-router-dom';

import GMapContainer from '../../components/GMapContainer/GMapContainer';
import Gallery from '../../components/Gallery/Gallery';
import ListCard from '../../components/listingCard/ListCard';
import StoreConnector from '../../hoc/StoreConnector';
import withParams from '../../hoc/withParams';
import {
  getResultThunk,
  invalidateResultThunk,
} from '../../redux/thunks/search';
import stateUtils from '../../utils/state';
import './styles.css';

class DashboardSideMenu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // result: {},
    };
  }

  componentDidMount() {
    this.reflectProps(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.reflectProps(nextProps);
  }

  componentWillUnmount() {
    this.props.dispatch(invalidateResultThunk());
  }

  reflectProps(props) {
    // if (!props.isFetchingResult && !props.isValidResult && !props.isFailedFetchingResult) {
    //   props.dispatch(getResultThunk(props.params.centreId));
    // }
    // if (props.isValidResult) {
    //   this.setState({ result: this.props.result }, () => this.setState({ result: this.props.result }));
    // }
  }

  render() {
    return (
      <div className="dashboardSideMenu">
        <Row justify="space-between" style={{ paddingBottom: '0px' }}>
          <Col span={24}>
            <Link to="/dashboard/" style={{ width: '100%' }}>
              <Row align="middle" justify="center" style={{ height: '65px' }}>
                <Col sm={8} xs={24}>
                  <Row align="middle" justify="center">
                    <DashboardOutlined style={{ fontSize: '25px' }} />
                  </Row>
                </Col>
                <Col sm={16} xs={0}>
                  <div className="sideMenuLabel">Dashboard</div>
                </Col>
              </Row>
            </Link>
          </Col>
          <Col span={24}>
            <Link to="/dashboard/appointments" style={{ width: '100%' }}>
              <Row align="middle" justify="center" style={{ height: '65px' }}>
                <Col sm={8} xs={24}>
                  <Row align="middle" justify="center">
                    <CalendarOutlined style={{ fontSize: '25px' }} />
                  </Row>
                </Col>
                <Col sm={16} xs={0}>
                  <div className="sideMenuLabel">Termine</div>
                </Col>
              </Row>
            </Link>
          </Col>
          <Col span={24}>
            <Link to="/dashboard/invoices" style={{ width: '100%' }}>
              <Row align="middle" justify="center" style={{ height: '65px' }}>
                <Col sm={8} xs={24}>
                  <Row align="middle" justify="center">
                    <FilePdfOutlined style={{ fontSize: '25px' }} />
                  </Row>
                </Col>
                <Col sm={16} xs={0}>
                  <div className="sideMenuLabel">Rechnungen</div>
                </Col>
              </Row>
            </Link>
          </Col>
          <Col span={24}>
            <Link to="/dashboard/settings" style={{ width: '100%' }}>
              <Row align="middle" justify="center" style={{ height: '65px' }}>
                <Col sm={8} xs={24}>
                  <Row align="middle" justify="center">
                    <SettingOutlined style={{ fontSize: '25px' }} />
                  </Row>
                </Col>
                <Col sm={16} xs={0}>
                  <div className="sideMenuLabel">Einstellungen</div>
                </Col>
              </Row>
            </Link>
          </Col>
        </Row>
      </div>
    );
  }
}

export default StoreConnector(
  withParams(DashboardSideMenu),
  stateUtils.fullStateMap,
  {},
);
