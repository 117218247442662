import { Button, Space, Typography } from 'antd';
import React from 'react';

import styles from './styles';

function EmbedError({ message, onClose }) {
  return (
    <>
      <ith-div class="ith_embedErrorWrapper">
        <Space size="small" direction="vertical" align="center">
          <Typography>{message}</Typography>
        </Space>
        <Space size="small" direction="vertical" align="center">
          <Button onClick={onClose}>OK</Button>
        </Space>
      </ith-div>

      <style>{styles}</style>
    </>
  );
}

export default EmbedError;
