/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

export const bookingSlice = createSlice({
  name: 'booking',
  initialState: {
    isFetchingOffers: false,
    isValidOffers: false,
    isFailedFetchingOffers: false,
    offers: {},
    bookingSuccessful: false,
  },
  reducers: {
    getOffers: (state) => {
      state.isFetchingOffers = true;
      state.isValidOffers = false;
      state.isFailedFetchingOffers = false;
      state.offers = [];
    },
    getOffersS: (state, action) => {
      state.isFetchingOffers = false;
      state.isValidOffers = true;
      state.isFailedFetchingOffers = false;
      state.offers = action.payload;
    },
    getOffersF: (state) => {
      state.isFetchingOffers = false;
      state.isValidOffers = false;
      state.isFailedFetchingOffers = true;
      state.offers = [];
    },
    resetOffers: (state) => {
      state.isFetchingOffers = false;
      state.isValidOffers = false;
      state.isFailedFetchingOffers = false;
      state.offers = {};
    },
    bookAppointment: (state) => {
      state.bookingSuccessful = true;
    },
  },
});

export const {
  getOffers,
  getOffersS,
  getOffersF,
  resetOffers,
  bookAppointment,
} = bookingSlice.actions;

export default bookingSlice.reducer;
