import React, { PureComponent } from 'react';

import BookingWidget from '../../containers/BookingWidget/BookingWidget';
import WidgetFrame from '../../hoc/WidgetFrame';

class BookingCustom extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="pageContainer">
        <BookingWidget />
      </div>
    );
  }
}

export default WidgetFrame(BookingCustom);
