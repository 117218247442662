import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import _ from 'lodash';

import de from '../locales/de.json';
import en from '../locales/en.json';

const LANG_STORAGE_KEY = 'LANG_STORAGE_KEY';
const DEFAULT_LANG = 'de';

export const SUPPORTED_LANGS = ['de', 'en'];

i18n.use(detector).init({
  detection: { order: ['navigator', 'localStorage'] },
  resources: {
    de: {
      translation: { ...de },
    },
    en: {
      translation: { ...en },
    },
  },
  load: 'languageOnly',
  fallbackLng: DEFAULT_LANG,
});

export const getLocalLang = (isLoggedIn) => {
  if (isLoggedIn) {
    return localStorage.getItem(LANG_STORAGE_KEY) || DEFAULT_LANG;
  }
  const detectedLanguage = i18n.language.split('-')[0];
  return _.includes(SUPPORTED_LANGS, detectedLanguage)
    ? detectedLanguage
    : DEFAULT_LANG;
};

export const setLang = (langKey, supportedLangList = SUPPORTED_LANGS) => {
  console.log('de', de);
  const NS = 'translation';
  let bundle;

  if (supportedLangList.indexOf(langKey) === -1 || i18n.language === langKey) {
    if (i18n.language === langKey) {
      localStorage.setItem(LANG_STORAGE_KEY, langKey);
    }
    return;
  }

  if (!i18n.hasResourceBundle(langKey, NS)) {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    bundle = require(`../locales/${langKey}.json`);
    i18n.addResourceBundle(langKey, NS, bundle, true, true);
  }

  i18n.changeLanguage(langKey);
  localStorage.setItem(LANG_STORAGE_KEY, langKey);
};

export const initLang = (isLoggedIn) => setLang(getLocalLang(isLoggedIn));

export default i18n;
