export default `
  .loader-wrapper {
    display: flex;
    position: fixed;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.35);
    z-index: 100;
    padding: 10px;
    box-sizing: border-box;
  }
`;
