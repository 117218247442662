import colors from '../../constants/colors';

export default `
  .ith_bookingInfos {
    display: flex;
    flex-direction: column;
    max-height: fit-content;
    background-color: white;
    border-radius: 3px;
    border: 1px solid ${colors.lightBorder};
    box-sizing: border-box;
    padding: 25px;
    width: 100%;
    margin-bottom: 25px;
    overflow: hidden;
  }

  .ith_bookNowWrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 5%;
  }

  .ith_title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  .ith_titleText {
    font-size: 30px;
    color: #42a4d1;
    font-weight: 600;
    word-break: break-all;
  }

  .ith_wfull {
    width: 100%;
  }

  .ith_dateTime {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
  }

  .ith_date {
    display: flex;
    flex-direction: column;
    height: 95px;
    width: 80px;
    background-color: ${colors.darkGreyBackground};
    box-shadow: 0 10px 10px -5px #333;
    border-radius: 0px 0px 5px 5px;
  }

  .ith_month {
    background-color: #42a4d1;
    padding: 5px 0;
    border-radius: 5px 5px 0 0;
    text-align: center;
  }

  .ith_monthText {
    font-size: 14px;
    color: white;
  }

  .ith_day {
    color: ${colors.darkTextGrey};
    text-align: center;
  }

  .ith_dayText {
    font-size: 24px;
    line-height: 24px;
    color: ${colors.darkTextGrey};
  }

  .ith_bookedTime {
    margin-left: 5px;
    font-size: 24px;
    color: ${colors.darkTextGrey};
  }

  .ith_highlighted {
    color: #42a4d1;
    font-weight: 600;
    font-size: 25px;
  }

  .ith_service {
    font-size: 14px;
    color: ${colors.darkTextGrey};
    margin-top: 5%;
    margin-bottom: 5%;
  }

  .ith_hrLine {
    color: ${colors.darkBorder};
    width: 100%;
    margin-bottom: 12px;
    margin-top: 12px;
  }

  .ith_serviceInfos {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .ith_serviceDesc {
    font-size: 14px;
    color: ${colors.darkTextGrey};
    margin-bottom: 5px;
  }

  .ith_serviceDescValue {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: ${colors.darkTextGrey};
  }

  .ith_paymentInfos {
    width: 100%;
    margin-bottom: 5%;
  }

  .ith_terms {
    font-size: 12px;
    color: ${colors.darkTextGrey};
    text-align: center;
    margin-bottom: 10px;
  }

  .ith_checkBoxWrapper {
    display: flex;
    flex-direction: row;
    padding: 10px;
  }

  .ith_checkboxText {
    font-family: Roboto;
    font-size: 14px;
    color: #6f6f6f;
    font-weight: 400;
  }

  .ith_checkboxContainer {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 10px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .ith_checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  .ith_checkboxCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  .ith_checkboxContainer:hover input ~ .ith_checkboxCheckmark {
    background-color: #ccc;
  }

  .ith_checkboxContainer input:checked ~ .ith_checkboxCheckmark {
    background-color: #636463;
  }

  .ith_checkboxCheckmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  .ith_checkboxContainer input:checked ~ .ith_checkboxCheckmark:after {
    display: block;
  }

  .ith_checkboxContainer .ith_checkboxCheckmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .ith_coupon_input {
    width: 40%;
    padding: 16px 8px;
    border-radius: 3px;
    border: 1px solid #dfdfdf;
    box-sizing: border-box;
    font-size: 16px;
    font-family: Roboto;
  }

  .ith_edit_icon {
    margin-left: 2%;
    color: #e74c3c;
    font-size: 25px;
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  }

  .ith_bookButton {
    height: 50px;
    width: 100%;
    box-sizing: border-box;
    font-size: 16px;
    font-family: Poppins;
    font-weight: 500;
    color: #fff;
    background: #e74c3c;
    padding: 3px;
    outline: none;
    border: none;
    border-radius: 5px;
    z-index: 2;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ith_disabled {
    background: rgba(231, 76, 60, 0.6);
    transition: background 0.3s;
  }
  .ith_disabled:hover {
    background: rgba(231, 76, 60, 1);
  }

  @media (min-width: 481px) {
    .ith_date {
      height: 80px;
      width: 80px;
    }

    .ith_monthText {
      font-size: 16px;
    }

    .ith_dayText {
      font-size: 32px;
      line-height: 32px;
    }

    .ith_bookedTime {
      font-size: 32px;
    }

    .ith_service {
      font-size: 16px;
    }

    .ith_serviceDesc {
      font-size: 14px;
    }

    .ith_serviceDescValue {
      font-size: 14px;
    }

    .ith_terms {
      font-size: 14px;
    }

    .ith_serviceInfos {
      justify-content: space-between;
    }
  }

  @media (min-width: 801px) {
    .ith_date {
      height: 100px;
      width: 100px;
    }

    .ith_monthText {
      font-size: 22px;
    }

    .ith_dayText {
      font-size: 44px;
      line-height: 44px;
    }

    .ith_bookedTime {
      font-size: 44px;
    }

    .ith_service {
      font-size: 22px;
    }

    .ith_serviceDesc {
      font-size: 18px;
    }

    .ith_serviceDescValue {
      font-size: 18px;
    }

    .ith_terms {
      font-size: 14px;
    }
  }

  @media (max-width: 1170px) {
  }

  @media (max-width: 800px) {
    .ith_titleText {
      font-size: 23px;
    }

    .ith_highlighted {
      font-size: 14px;
    }

    .ith_bookingInfos {
      padding: 15px;
    }
  }
`;
