/* eslint-disable no-console */

/* eslint-disable class-methods-use-this */

/* eslint-disable no-unused-vars */

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { PureComponent } from 'react';

import CustomCard from '../../components/CustomCard/CustomCard';
import './styles.css';

class LandingMain extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <div className="subTitle">Und so funktioniert&apos;s</div>
        <div className="items-stretch">
          <div className="card-Container flex flex-wrap flex-row justify-around xl:justify-between gap-10">
            <CustomCard
              title="1. Suchen"
              mainDescription="Suche nach Methode, Name und/oder Ort. Unser Verzeichnis zeigt dir schnell die nächst gelegenen Therapeuten an. Wähle aus der Liste aus um die Details zu sehen"
              secondaryDescription=""
              alt="So funktionert es"
              image="/landing1.jpg"
            />
            <CustomCard
              title="2. Auswählen"
              mainDescription="Sobald du den passenden Therapeuten oder die passende Therapeutin gefunden hast, klicke auf BUCHEN, um den Termin direkt zu buchen. Oder rufe den Therapeuten an, falls er unsere Buchungsfunktion noch nicht verwendet."
              secondaryDescription=""
              alt="Auswählen"
              image="/landing2.jpg"
            />
            <CustomCard
              title="3. Buchen"
              mainDescription="Du kannst den passenden Tag und die ideale Zeit auswählen und direkt buchen. Dazu musst du dich einmalig bei uns anmelden, damit der Therapeut deine Kontaktdaten erhält. Du erhälst von uns die Buchungsbestätigung per SMS und/oder Email."
              secondaryDescription=""
              alt="Buchen"
              image="/landing3.jpg"
            />
          </div>
          <div className="background-container">
            <div className="subTitle">Deine Vorteile</div>
            <div className="twocardContainer flex flex-wrap flex-row justify-around xl:justify-between gap-10">
              <CustomCard
                size="big"
                title="Du buchst bei uns ohne Kosten und bezahlst den Anbieter direkt."
                mainDescription="Deinen Termin zu Buchen kostet dich nichts. Unser Service ist für dich kostenlos. Und du siehst immer den Preis, den du für die von dir gewählten Service direkt an den Anbieter zahlst."
                secondaryDescription="Keine versteckten Kosten."
                alt="Buchen"
                image="/landing4.jpg"
              />
              <CustomCard
                size="big"
                title="Du erhälst ein eigenes Benutzerprofil mit deinen Buchungen..."
                mainDescription="So hast du jederzeit den Überblick über deine Buchungen und musst dich nicht immer neu deine Angaben eintippen."
                secondaryDescription="Deine Buchungen im Überblick."
                alt="Buchen"
                image="/landing5.jpg"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LandingMain;
