export default `
  .ith_verification_container {
    margin: 0 auto;
    text-align: left;
    padding: 25px 15px;
    box-sizing: border-box;
  }

  .ith_verification_container_header {
    font-size: 30px;
    font-weight: 600;
    color: #8d8d8f;
  }

  .ith_verification_form {
    padding-top: 20px;
  }

  .ith_verification_form_submit {
    width: 100%;
    padding: 12px 8px;
    box-sizing: border-box;
    cursor: pointer;
    color: #ffffff;
    border: none;
    margin-top: 10%;
    background-color: #e74c3c;
  }

  #form_input_title {
    color: #212b36;
  }

  .ith_options_verification {
    margin-top: 5%;
  }

  #option_verification {
    color: #2892cc;
    text-decoration: none;
    cursor: pointer;
  }

  @media (min-width: 320px) {
  }
  @media (min-width: 480px) {
    .ith_verification_container {
      width: 100%;
      height: 100%;
      text-align: center;
    }
  }
  @media (min-width: 600px) {
    .ith_verification_container_header {
      font-size: 40px;
    }

    .ith_verification_form_submit {
      padding: 16px 10px;
      margin-bottom: 5%;
      font-size: 17px;
      font-weight: 600;
      min-height: 50px;
      width: 50%;
      border: none;
    }

    #form_input_title {
      font-size: 16px;
      align: left;
      margin-bottom: 2%;
    }

    #option_verification {
      font-size: 17px;
    }
  }
  @media (min-width: 801px) {
  }
  @media (min-width: 1025px) {
  }
  @media (min-width: 1281px) {
  }
`;
