import React from 'react';
import { Provider } from 'react-redux';

import EmbedLoader from '../src/components/EmbedLoader';
import env from '../../config/env';
import EmbedBookingContainer from '../src/containers/EmbedBookingContainer';
import withI18next from '../src/hoc/withI18next';
import initStore from '../src/store';
import reducers from '../src/store/reducers';
import { fetchProviders } from '../src/utils/booking';

const store = initStore(reducers, {}, false);

// eslint-disable-next-line import/prefer-default-export
export const EmbedBookingPage = withI18next()(
  class EmbedBookingPage extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true,
        error: false,
        centre: null,
        checkedVariants: [],
        serviceVariants: [],
        providers: [],
        bookingClinic: null,
      };
    }

    componentDidMount() {
      const element = document.getElementById('embed-booking-page');
      const centreId = element.getAttribute('data-centre-id');
      // eslint-disable-next-line no-unused-expressions
      this.state.loading
      && fetchProviders(centreId).then((result) => this.setState(result));
    }

    render() {
      if (this.state.error) {
        return <div>{this.state.error}</div>;
      }

      if (this.state.loading) {
        return <EmbedLoader isLoading={this.state.loading} />;
      }

      const {
        centre,
        checkedVariants,
        serviceVariants,
        providers,
        bookingClinic,
      } = this.state;
      const { authModalChange } = this.props;

      const { t } = this.props;

      const element = document.getElementById('embed-booking-page');

      const isSmallTemplate = element && element.hasAttribute('data-calendar-small');

      const {
        preselectedProviderId,
        preselectedProviderShowSelected,
        preselectedProviderSelectButHide,
        preselectedServiceId,
        preselectedServiceShowSelected,
        preselectedServiceSelectButHide,
      } = centre.CentreSetting;

      return (
        <Provider store={store}>
          <div>
            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href="/static/apple-touch-icon.png"
            />
            <link
              rel="icon"
              type="image/png"
              sizes="16x16"
              href={`${env.api.consumerApps}/favicon-16x16.png`}
            />
            <link
              rel="manifest"
              href={`${env.api.consumerApps}/manifest.json`}
            />
            <link
              rel="mask-icon"
              href={`${env.api.consumerApps}/safari-pinned-tab.svg`}
              color="#0096ff"
            />
            <link
              rel="stylesheet"
              href={`${env.api.consumerApps}/styles/booking-embed.css`}
            />
            <link rel="stylesheet" href={`${env.api.providerApps}/css/booking_external_${element.getAttribute('data-centre-id')}.css`} />
            <meta name="apple-mobile-web-app-title" content={env.config.brandName} />
            <meta name="application-name" content={env.config.brandName} />
            <meta name="theme-color" content="#0096FF" />
            <link
              href="https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,400,300,600,700%7CRaleway:700"
              rel="stylesheet"
              type="text/css"
            />
            <link
              rel="stylesheet"
              href="https://cdn.jsdelivr.net/npm/react-intl-tel-input@7.0.1/dist/main.css"
              type="text/css"
            />
            <link
              href="https://fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,400,300,600,700%7CRaleway:700"
              rel="stylesheet"
              type="text/css"
            />

            <div id="booking-embed">
              <EmbedBookingContainer
                t={t}
                centre={centre}
                checkedVariants={checkedVariants}
                serviceVariants={serviceVariants}
                providers={providers}
                bookingClinic={bookingClinic}
                hideHeader
                isEmbed
                authModalChange={authModalChange}
                isSmallTemplate={isSmallTemplate}
                preselectedProviderId={preselectedProviderId}
                preselectedProviderShowSelected={
                  preselectedProviderShowSelected
                }
                preselectedProviderSelectButHide={
                  preselectedProviderSelectButHide
                }
                preselectedServiceId={preselectedServiceId}
                preselectedServiceShowSelected={preselectedServiceShowSelected}
                preselectedServiceSelectButHide={
                  preselectedServiceSelectButHide
                }
              />
            </div>
          </div>
        </Provider>
      );
    }
  },
);
