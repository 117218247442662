import searchInterface from '../../interfaces/api/search';
import {
  getResult,
  getResultF,
  getResultS,
  getResults,
  getResultsF,
  getResultsS,
  getSuggestions,
  invalidateResult,
  invalidateResults,
} from '../slices/search';
import { setErrorThunk } from './modals';

export const getSuggestionsThunk = (query) => async (dispatch) => {
  await searchInterface
    .getSuggestions(query)
    .then((response) => {
      dispatch(getSuggestions(response));
    })
    .catch((err) => {
      console.log(err);
      if (err && err.code !== 'ERR_CANCELED') {
        dispatch(
          setErrorThunk(
            'Es konnten keine Vorschläge gefunden werden. Versuchen Sie es später erneut.',
            err,
          ),
        );
      }
    });
};

export const getResultsThunk = (query) => async (dispatch) => {
  dispatch(getResults());

  await searchInterface
    .getResults(query)
    .then((response) => {
      dispatch(getResultsS(response));
    })
    .catch((err) => {
      dispatch(getResultsF());

      console.log(err);
      dispatch(
        setErrorThunk(
          'Die Resultate konnten nicht abgerufen werden. Versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};

export const getResultThunk = (query) => async (dispatch) => {
  dispatch(getResult());

  await searchInterface
    .getResult(query)
    .then((response) => {
      dispatch(getResultS(response));
    })
    .catch((err) => {
      dispatch(getResultF());

      console.log(err);
      dispatch(
        setErrorThunk(
          'Die ausgewählte Praxis konnte nicht abgerufen werden. Versuchen Sie es später erneut.',
          err,
        ),
      );
    });
};

export const invalidateResultsThunk = () => async (dispatch) => {
  dispatch(invalidateResults());
};

export const invalidateResultThunk = () => async (dispatch) => {
  dispatch(invalidateResult());
};
