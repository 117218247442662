import { bookingClient, publicBookingClient } from '../clients';

const bookingInterface = {
  getOffers: async (centreId, providerId, serviceId, dateStart, dateEnd) => {
    const { data: offers } = await publicBookingClient.get(
      `/providers/${providerId}/centres/${centreId}/services-day-offer?serviceId=${serviceId}&start=${dateStart}&end=${dateEnd}`,
    );

    return offers;
  },
  bookAppointment: async (
    centreId,
    providerId,
    serviceId,
    userId,
    date,
    startTime,
    endTime,
  ) => {
    const { data: offers } = await bookingClient.post('/appointments', {
      centreId,
      dirPartnerId: centreId,
      providerId,
      serviceId,
      consumerId: userId,
      createdByUserId: userId,
      date,
      startTime,
      endTime,
      description: '',
      status: 'CONFIRMED',
    });

    return offers;
  },
};

export default bookingInterface;
