import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import app from './app';
import portals from './portals';

const rootReducer = combineReducers({
  app,
  portals,
  form: formReducer,
});

const appReducer = (state, action) => rootReducer(state, action);

export default appReducer;
