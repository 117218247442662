import {
  Button, Col, Form, Input, Row, Typography,
} from 'antd';
import { cloneDeep, map, some } from 'lodash';
import React, { useRef, useState } from 'react';
import { Icon } from 'react-icons-kit';
import { checkCircle, key, timesCircle } from 'react-icons-kit/fa';
import env from '../../../../config/env';

const getDefaultValidators = (t) => [
  { text: t('passwordRequirementsLength'), isValid: false, regex: /^.{8,}$/ },
  { text: t('passwordRequirementsNumber'), isValid: false, regex: /.*\d/ },
  {
    text: t('passwordRequirementsLowercase'),
    isValid: false,
    regex: /.*[a-z]/,
  },
  {
    text: t('passwordRequirementsCapital'),
    isValid: false,
    regex: /.*[A-Z]/,
  },
  {
    text: t('passwordRequirementsSpecial'),
    isValid: false,
    regex: /(?=.*?[_#?!@$%^&*])/,
  },
];

function EmbedPassword({ t, handleBack, onSignUpSubmitStep2 }) {
  const passwordFormRef = useRef('');

  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [validators, setValidators] = useState(getDefaultValidators(t));

  const checkPassword = (value) => {
    const validatorsToUpdate = cloneDeep(validators);
    const updatedValidators = map(validatorsToUpdate, (validator) => {
      // eslint-disable-next-line no-param-reassign
      validator.isValid = value.match(validator.regex);
      return validator;
    });

    setPassword(value);
    setValidators(updatedValidators);
  };

  const isPasswordValid = () => {
    const isValidatorsFalse = some(
      validators,
      (validator) => !validator.isValid,
    );
    const isConfirmTheSame = password === confirmedPassword;

    return !isValidatorsFalse && isConfirmTheSame;
  };

  const handleSignUpSubmitStep2 = () => {
    const formData = passwordFormRef.current.getFieldsValue();
    if (isPasswordValid() && formData) {
      onSignUpSubmitStep2(formData);
    }
  };

  return (
    <ith-div class="ith_auth-wrapper-field">
      <Form ref={passwordFormRef} name="signup-password-for" layout="vertical">
        <Form.Item label={t('label_password')} name="password">
          <Input.Password
            size="large"
            placeholder={t('placeholder_password')}
            type="password"
            onChange={(e) => checkPassword(e.target.value)}
            prefix={<Icon icon={key} />}
          />
        </Form.Item>
        <Form.Item label={t('label_reenterPassword')} name="confirmedPassword">
          <Input.Password
            size="large"
            placeholder={t('placeholder_confirmPassword')}
            type="password"
            onChange={(e) => setConfirmedPassword(e.target.value)}
            prefix={<Icon icon={key} />}
          />
        </Form.Item>
        <Row justify="space-between" gutter={[0, 12]}>
          <Col xs={24} sm={10}>
            {validators.map((item) => (
              <Row>
                <Col
                  span={3}
                  className={
                    item.isValid
                      ? 'icon--password-valid'
                      : 'icon--password-invalid'
                  }
                >
                  <Icon
                    icon={item.isValid ? checkCircle : timesCircle}
                    size={20}
                  />
                </Col>
                <Col span={21} style={{}}>
                  {item.text}
                </Col>
              </Row>
            ))}
          </Col>
          <Col xs={24} sm={12}>
            <Typography.Text>
              {t('label_policy1')}
              <a
                href={env.links.bookingTermsURL}
                target="_blank"
                className="ith_links"
                rel="noreferrer"
              >
                {t('label_termsOfServices')}
              </a>
              {t('label_policy2')}
              <a href={env.links.privacyURL} target="_blank" className="ith_links" rel="noreferrer">
                {t('landing_privacy_policy')}
              </a>
              {t('label_policy3')}
            </Typography.Text>
          </Col>
        </Row>
        <Row
          justify="space-between"
          align="bottom"
          style={{ marginTop: '25px' }}
          gutter={[0, 12]}
        >
          <Col xs={24} sm={11} md={6}>
            <Button
              className="btn--auth btn--auth-primary"
              htmlType="submit"
              onClick={handleBack}
            >
              {t('back')}
            </Button>
          </Col>
          <Col xs={24} sm={11} md={6}>
            <Button
              className="btn--auth btn--auth-primary"
              htmlType="submit"
              onClick={handleSignUpSubmitStep2}
            >
              {t('landing_signup')}
            </Button>
          </Col>
        </Row>
      </Form>
    </ith-div>
  );
}

export default EmbedPassword;
