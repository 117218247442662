import { Map } from 'immutable';

import appActionTypes from '../../constants/app';

const initialState = Map({
  isAppReady: false,

  user: null,
  isUserFetching: false,
  isUserValid: false,
  userError: null,

  authModalName: null,

  specialSignUpData: null,
});

// eslint-disable-next-line default-param-last
export default function (state = initialState, action) {
  switch (action.type) {
    case appActionTypes.USER_FETCH:
      return {
        ...state,
        isUserFetching: true,
        isUserValid: false,
        userError: null,
      };

    case appActionTypes.USER_RECEIVE:
      return {
        ...state,
        isAppReady: true,
        isUserFetching: false,
        isUserValid: true,
        userError: null,
        user: action.payload.user,
      };

    case appActionTypes.USER_ERROR:
      return {
        ...state,
        isAppReady: false,
        isUserFetching: false,
        isUserValid: false,
        userError: action.payload.errorMessage,
      };

    case appActionTypes.USER_RESET:
      return {
        ...state,
        isAppReady: true,
        isUserFetching: false,
        isUserValid: false,
        userError: null,
        user: null,
      };

    case appActionTypes.USER_SET:
      return {
        ...state,
        user: action.payload.user,
      };

    case appActionTypes.AUTH_MODAL_CHANGE:
      return { ...state, authModalName: action.payload.name };

    case appActionTypes.APP_READY:
      return {
        ...state,
        isAppReady: action.payload.isAppReady,
      };

    case appActionTypes.SET_SIGN_UP_DATA:
      return {
        ...state,
        signUpData: action.payload.signUpData,
      };

    case appActionTypes.SET_SPECIAL_SIGN_UP_DATA:
      return {
        ...state,
        specialSignUpData: action.payload.specialSignUpData,
      };

    default:
      return state;
  }
}
