export default class JwtService {
  static ACCESS_TOKEN_KEY = 'JWT_TOKEN';

  static REFRESH_TOKEN_KEY = 'JWT_REFRESH_TOKEN';

  static getAccessToken() {
    return window.localStorage.getItem(JwtService.ACCESS_TOKEN_KEY);
  }

  static setAccessToken(accessToken) {
    window.localStorage.setItem(JwtService.ACCESS_TOKEN_KEY, accessToken);
  }

  static hasAccessToken() {
    const accessToken = JwtService.getAccessToken();

    return accessToken !== null && accessToken !== '';
  }

  static removeAccessToken() {
    window.localStorage.removeItem(JwtService.ACCESS_TOKEN_KEY);
  }

  static getRefreshToken() {
    return window.localStorage.getItem(JwtService.REFRESH_TOKEN_KEY);
  }

  static setRefreshToken(refreshToken) {
    window.localStorage.setItem(JwtService.REFRESH_TOKEN_KEY, refreshToken);
  }

  static hasRefreshToken() {
    const refreshToken = JwtService.getRefreshToken();

    return refreshToken !== null && refreshToken !== '';
  }

  static removeRefreshToken() {
    window.localStorage.removeItem(JwtService.REFRESH_TOKEN_KEY);
  }
}
