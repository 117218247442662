import { Col, Row } from 'antd';
import React, { PureComponent } from 'react';
import { Navigate } from 'react-router-dom';

import AppointmentList from '../containers/AppointmentList/AppointmentList';
import DashboardSideMenu from '../containers/DashboardSideMenu/DashboardSideMenu';
import Page from '../hoc/Page';
import StoreConnector from '../hoc/StoreConnector';
import JwtService from '../interfaces/jwtService';
import stateUtils from '../utils/state';

class AppointmentsPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    if (
      !this.props.signedIn
      && !JwtService.hasAccessToken()
      && !JwtService.hasRefreshToken()
    ) {
      return <Navigate to="/" replace />;
    }

    if (!this.props.signedIn) {
      return <div>...</div>;
    }

    return (
      <Row style={{ height: 'calc(100vh - 75px)' }}>
        <Col xs={4} sm={8} md={4}>
          <DashboardSideMenu />
        </Col>
        <Col xs={20} sm={16} md={20}>
          <AppointmentList />
        </Col>
      </Row>
    );
  }
}

export default Page(
  StoreConnector(AppointmentsPage, stateUtils.fullStateMap, {}),
  false,
  true,
);
