import * as _ from 'lodash';
import moment from 'moment';
import Router from 'next/router';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import appointmentService from '../../api/api/appointmentService';
import appActionTypes from '../../constants/app';
import APPOINT_STATUSES from '../../constants/appointmentStatuses';
import { authModalTypes } from '../../constants/authModalTypes';
import { DATE_FORMAT, TIME_FORMAT } from '../../constants/dateConstants';
import { EditIcon } from '../../constants/icons';
import DateTime from '../../utils/date-time';
import ModalError from '../ModalError';
import SVGIcon from '../SVGIcon';
import styles from './styles';

class BookingInfos extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      startTime: null,
      endTime: null,
      totalPrice: null,
      disableButton: false,
      error: false,
      errorMessage: null,
      timeFormat: '',
    };

    this.onBookClick = this.onBookClick.bind(this);
    this.toggleState = this.toggleState.bind(this);
  }

  componentDidMount() {
    const {
      startTime, endTime, services, date,
    } = this.props;
    const totalPrice = _.sumBy(services, 'total');

    this.setState({
      startTime: moment(
        `${date} ${startTime}`,
        `${DATE_FORMAT} ${TIME_FORMAT}`,
      ),
      endTime: moment(`${date} ${endTime}`, `${DATE_FORMAT} ${TIME_FORMAT}`),
      totalPrice,
    });

    const localStorageTimeFormat = localStorage.getItem('time_format');

    if (localStorageTimeFormat) {
      this.setState({ timeFormat: localStorageTimeFormat });
    } else {
      const timeFormat = DateTime.getTimeFormatByLocale();
      this.setState({ timeFormat });
    }
  }

  // todo
  onBookClick() {
    if (this.props.rescheduleBookClick) {
      this.props.rescheduleBookClick();
    } else {
      this.setState({ disableButton: true }, async () => {
        try {
          const user = this.props.userData
            ? this.props.userData
            : this.props.user;

          if (!user) {
            this.props.authModalChange(authModalTypes.signIn);
            return;
          }

          const { props } = this;

          const data = {
            providerId: props.provider.centreProvider_id,
            serviceId: props.services[0].service_id,
            date: props.date,
            startTime: props.startTime,
            endTime: props.endTime,
            status: APPOINT_STATUSES.CONFIRMED,
            centreId: props.centre.centre_id,
            description: '',
            consumerId: user.user_id,
            createdByUserId: user.user_id,
            dirPartnerId: props.centre.centre_id,
          };

          await appointmentService.createAppointment(data);

          this.setState(
            {
              disableButton: false,
            },
            () => {
              if (
                this.props.isModal
                && this.props.closeNewBookingModalAndGetAppts
              ) {
                this.props.closeNewBookingModalAndGetAppts();
              }

              if (!_.isEmpty(Router.router)) {
                Router.push('/dashboard/dashboard');
              } else if (this.props.showBookingCalendar) {
                this.props.showBookingCalendar(true);
              }
            },
          );
        } catch (e) {
          console.log(e);
          this.setState({
            disableButton: false,
            error: true,
            errorMessage:
              _.get(e, 'response.data.error.message', null)
              || _.get(e, 'message', null)
              || 'An error occured',
          });
        }
      });
    }
  }

  handleEditClick = () => {
    if (Router.router && !this.props.isEmbed) {
      Router.back();
    } else if (this.props.showBookingCalendar) {
      this.props.showBookingCalendar(false);
    }
  };

  toggleState(stateName) {
    // eslint-disable-next-line react/no-access-state-in-setstate
    this.setState({ [stateName]: !this.state[stateName] });
  }

  render() {
    const { t } = this.props;
    const { state, props } = this;
    const { timeFormat } = state;
    const endTime = moment(state.endTime).subtract(props.bufferTime, 'm');

    return (
      <ith-div class="ith_bookingInfos">
        <ith-div class="ith_title">
          <ith-p class="ith_titleText">{t('label_bookingDetails')}</ith-p>
          <ith-div class="ith_edit_icon" onClick={this.handleEditClick}>
            <SVGIcon icon={EditIcon} />
          </ith-div>
        </ith-div>
        <hr className="ith_hrLine" />
        <ith-div class="ith_dateTime">
          <ith-div class="ith_date">
            <ith-div class="ith_month">
              <ith-p class="ith_monthText ith_bodyText-bold">
                {state.startTime && state.startTime.format('MMM')}
              </ith-p>
            </ith-div>
            <ith-div class="ith_day">
              <ith-p class="ith_dayText ith_bodyText-bold">
                {state.startTime && state.startTime.format('D')}
              </ith-p>
            </ith-div>
          </ith-div>
          <ith-p class="ith_bookedTime ith_bodyText-medium">
            {state.startTime
              && `${state.startTime.format(timeFormat)} - ${endTime.format(timeFormat)}`}
          </ith-p>
        </ith-div>
        <hr className="ith_hrLine" />
        <ith-div class="ith_serviceInfos">
          <ith-p class="ith_highlighted">{props.centre.name}</ith-p>
        </ith-div>
        <hr className="ith_hrLine" />
        <ith-div class="ith_serviceInfos">
          <ith-p class="ith_highlighted">
            {props.provider.User.firstName}
            {' '}
            {props.provider.User.lastName}
          </ith-p>
        </ith-div>
        {props.services.map((service, idx) => (
          <ith-div key={`${idx + 1}`}>
            <hr className="ith_hrLine" />
            <ith-div class="ith_serviceInfos">
              <ith-p class="ith_highlighted">{service.name}</ith-p>
              <ith-p class="ith_serviceDescValue ith_bodyText-bold">
                CHF
                {' '}
                {service.total}
              </ith-p>
            </ith-div>
          </ith-div>
        ))}

        {/* TODO coupon discount */}

        {/* <hr className="hrLine" /> */}
        {/* <div className="serviceInfos"> */}
        {/*  <input */}
        {/*    className="coupon_input" */}
        {/*    type="text" */}
        {/*    placeholder={this.props.t('label_yourCouponCode')} */}
        {/*    value='' */}
        {/*    onChange={() => {}} */}
        {/*  /> */}
        {/*  <p className="serviceDescValue bodyText-bold">CHF 120</p> */}
        {/* </div> */}

        <hr className="ith_hrLine" />
        <ith-div class="ith_serviceInfos">
          <ith-p class="ith_serviceDesc bodyText-bold">
            {t('label_totalAmount')}
          </ith-p>
          <ith-p class="ith_serviceDescValue bodyText-bold">
            CHF
            {' '}
            {state.totalPrice}
          </ith-p>
        </ith-div>
        <hr className="ith_hrLine" />
        <ith-div class="ith_serviceInfos">
          <ith-p class="ith_serviceDesc ith_bodyText-bold">
            {t('label_paymentMethod')}
          </ith-p>
          <ith-p class="ith_serviceDescValue ith_bodyText-bold">
            {t('label_paymentOnSpot')}
          </ith-p>
        </ith-div>
        {/* todo not in use yet */}
        {/* <hr className="ith_hrLine" />
         <ith-div class="ith_checkBoxWrapper">
          <label className="ith_checkboxContainer">
            <ith-p class="ith_checkboxText">{t('label_noNewsletter', { brandName: config.brandName })}</ith-p>
            <input type="checkbox" />
            <ith-span class="ith_checkboxCheckmark" />
          </label>
        </ith-div>
        <ith-div class="ith_checkBoxWrapper">
          <label className="ith_checkboxContainer">
            <ith-p class="ith_checkboxText">{t('label_yesMarketing')}</ith-p>
            <input type="checkbox" />
            <ith-span class="ith_checkboxCheckmark" />
          </label>
        </ith-div>
        <hr className="ith_hrLine" /> */}

        <ith-div class="ith_wfull ith_bookNowWrapper">
          <ith-p class="ith_terms ith_bodyText-medium">
            {t('label_bookingTerms')}
          </ith-p>

          {props.user || props.userData ? (
            <ith-button
              class="ith_bookButton"
              disabled={this.state.disableButton}
              onClick={this.onBookClick}
            >
              {t('label_bookNow')}
            </ith-button>
          ) : (
            <ith-button
              class="ith_bookButton ith_disabled"
              onClick={() => this.props.authModalChange(authModalTypes.signIn)}
            >
              {t('landing_login')}
            </ith-button>
          )}
        </ith-div>

        <ModalError
          t={this.props.t}
          status={this.state.error}
          toggleState={this.toggleState}
          errorMessage={this.state.errorMessage}
        />

        <style>{styles}</style>
      </ith-div>
    );
  }
}

BookingInfos.defaultProps = {
  // eslint-disable-next-line react/default-props-match-prop-types
  t: () => '',
};

BookingInfos.propTypes = {
  startTime: PropTypes.string.isRequired,
  endTime: PropTypes.string.isRequired,
  bufferTime: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.app.user,
});

const mapDispatchToProps = (dispatch) => ({
  authModalChange: (name) => dispatch({ type: appActionTypes.AUTH_MODAL_CHANGE, payload: { name } }),
});

export default connect(mapStateToProps, mapDispatchToProps)(BookingInfos);
