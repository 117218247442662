import React, { Component } from 'react';

// import GifLoader from '../components/GifLoader';
import AuthModals from '../containers/AuthModals/AuthModals';
import ErrorModals from '../containers/ErrorModals/ErrorModals';
import stateUtils from '../utils/state';
import StoreConnector from './StoreConnector';
import StoreProvider from './StoreProvider';

const decorator = (ComposedComponent) => StoreProvider(
  (() => {
    class WidgetFrameComponent extends Component {
      constructor(props) {
        super(props);
        this.state = {
          isPageReady: true,
        };
      }

      // todo: Set up sentry/error handling
      // todo: Set up Rerouting/Auth Routing
      // todo: Set up Socket

      render() {
        const { isPageReady } = this.state;

        return isPageReady ? (
          <>
            <ComposedComponent />
            <AuthModals />
            <ErrorModals />
          </>
        ) : (
          <div>Loading...</div>
        ); // <GifLoader />
      }
    }

    return StoreConnector(WidgetFrameComponent, stateUtils.fullStateMap, {});
  })(),
);

export default decorator;
