const i18next = require('i18next');
const env = require('../../../config/env');
const enTranslation = require('../../../locales/en.json');
const frTranslation = require('../../../locales/fr.json');
const deTranslation = require('../../../locales/de.json');
const itTranslation = require('../../../locales/it.json');

const i18n = i18next.default ? i18next.default : i18next;

const options = {
  resources: {
    en: { translation: enTranslation },
    fr: { translation: frTranslation },
    de: { translation: deTranslation },
    it: { translation: itTranslation },
  },
  fallbackLng: env.default.config.defaultLanguage || 'en',
  load: 'languageOnly', // we only provide en, de -> no region specific locals like en-US, de-DE

  debug: false, // process.env.NODE_ENV !== 'production',
  saveMissing: true,

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ',',
    format: (value, format) => {
      if (format === 'uppercase') return value.toUpperCase();
      return value;
    },
  },

  detection: {
    caches: ['localStorage', 'cookie'],
  },
};

// initialize if not already initialized
if (!i18n.isInitialized) i18n.init(options);

// a simple helper to getInitialProps passed on loaded i18n data
i18n.getInitialProps = (req, namespaces) => {
  // eslint-disable-next-line no-param-reassign
  if (!namespaces) namespaces = i18n.options.defaultNS;
  // eslint-disable-next-line no-param-reassign
  if (typeof namespaces === 'string') namespaces = [namespaces];

  // do not serialize i18next instance avoid sending it to client
  if (req && req.i18n) req.i18n.toJSON = () => null;

  const ret = {
    i18n: req ? req.i18n : i18n,
    // use the instance on req - fixed language on
    // request (avoid issues in race conditions with lngs of different users)
  };

  // for serverside pass down initial translations
  if (req && req.i18n) {
    const initialI18nStore = {};
    // req.i18n.languages.forEach((l) => {
    //   initialI18nStore[l] = {};
    //   namespaces.forEach((ns) => {
    //     initialI18nStore[l][ns] = (req.i18n.services.resourceStore.data[l] || {})[ns] || {};
    //   });
    // });

    ret.initialI18nStore = initialI18nStore;
    ret.initialLanguage = env.default.server.stage === 'development'
      ? req.i18n.language
      : env.defaultLanguageFull;
  }

  return ret;
};

module.exports = i18n;
