import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import weekday from 'dayjs/plugin/weekday';
import * as React from 'react';

import './App.css';
import Router from './Router';
import useLoadFAQWidget from './utils/faq';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

function App() {
  useLoadFAQWidget(
    'https://euc-widget.freshworks.com/widgets/201000000495.js',
    `window.fwSettings={
    'widget_id':201000000495
    };
    !function(){if("function"!=typeof window.FreshworksWidget){var n=function(){n.q.push(arguments)};n.q=[],window.FreshworksWidget=n}}()`,
  );
  return (
    <div className="App">
      <Router />
    </div>
  );
}

export default App;
