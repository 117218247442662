export default `
  .loader-wrapper {
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    left: 0;
    top: 50px;
    background-color: transparent;
    z-index: 100;
    padding: 10px;
    box-sizing: border-box;
  }
`;
