import React, { PureComponent } from 'react';

import LandingMain from '../containers/LandingMain/LandingMain';
import SearchHeader from '../containers/SearchHeader/SearchHeader';
import Page from '../hoc/Page';

class LandingPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <SearchHeader />
        <LandingMain />
      </>
    );
  }
}

export default Page(LandingPage);
